import ApiService from '@/services/api.service';
import { resetRouter } from '@/services/utils.service';
import { version } from '../../../package.json';

const state = {
  isLoading: false,
  isLoadingUserOfficeCategory: false,
  accessToken: null,
  refreshToken: null,
  error: null,
  errorUserOfficeCategory: null,
  authenticationErrorCode: 0,
  authenticationError: '',
  user: null,
  userOfficeCategory: null,
  menuId: null,
  menu: null,
  versionUpdated: true
};

const getters = {
  getAccessToken: (state) => {
    return state.accessToken;
  },
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingUserOfficeCategory: (state) => {
    return state.isLoadingUserOfficeCategory;
  },
  getError: (state) => {
    return state.error;
  },
  getErrorUserOfficeCategory: (state) => {
    return state.isErrorUserOfficeCategory;
  },
  getUser: (state) => {
    return state.user;
  },
  getUserOfficeCategory: (state) => {
    return state.userOfficeCategory;
  },
  getMenuId: (state) => {
    return state.menuId;
  },
  getMenu: (state) => {
    return state.menu;
  },
  getVersionUpdated: state => state.versionUpdated
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderUserOfficeCategory: (state, status) => (state.isLoading = status),
  setLoginSuccess: (state, accessToken) => {
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
    }

    state.accessToken = accessToken;
  },
  setAccessTokenFromLocalStorage: (state) => {
    const token = localStorage.getItem('access_token');

    if (token) {
      state.accessToken = token;
    }
  },
  setRefreshToken: (state, refreshToken) => {
    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }
  },
  setError: (state, data) => (state.error = data),
  setErrorUserOfficeCategory: (state, data) => (state.error = data),
  setUser: (state, data) => (state.user = data),
  setUserOfficeCategory: (state, data) => (state.userOfficeCategory = data),
  setMenuId: (state, data) => (state.menuId = data),
  setMenu: (state, data) => {
    if (data) {
      localStorage.setItem('menu', JSON.stringify(data));
    }

    state.menu = data;
  },
  setMenuFromLocalStorage: (state) => {
    const menu = JSON.parse(localStorage.getItem('menu'));

    if (menu) {
      state.menu = menu;
    }
  },
  setVersionUpdated: (state, data) => state.versionUpdated = data
};

const actions = {
  async login({ commit, dispatch }, payload) {
    commit('setLoader', true);

    try {
      const response = await ApiService.post('api/v1/login', payload);

      const { client_min_version } = response.data.data.user

      if (client_min_version) {
        if (version !== client_min_version) {
          commit('setVersionUpdated', false)
        }
      }

      commit('setRefreshToken', response.data.data.refresh_token);
      commit('setLoginSuccess', response.data.data.token);
      commit('setUser', response.data.data.user);
      commit('setError', null);

      return response;
    } catch (error) {
      if (error.response.data.errors) {
        dispatch(
          'alert/createAlert',
          {
            data: error.response.data.errors[0].detail,
            status: 'error',
          },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.message, status: 'error' },
          { root: true }
        );
      }

      commit('setError', error.response.data.message);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchMe({ commit, dispatch }) {
    try {
      const response = await ApiService.get(`api/v1/users/-actions/me`);

      const { client_min_version } = response.data.me

      if (client_min_version) {
        if (version !== client_min_version) {
          commit('setVersionUpdated', false)
        }
      }

      commit('setUser', response.data.me);

      return response;
    } catch (error) {
      if (error.response.data.errors) {
        dispatch(
          'alert/createAlert',
          {
            data: error.response.data.errors[0].detail,
            status: 'error',
          },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.message, status: 'error' },
          { root: true }
        );
      }
    }
  },
  async fetchUserOfficeCategory({ commit, dispatch, state }) {
    commit('setLoaderUserOfficeCategory', true);

    try {
      const response = await ApiService.get(
        `api/v1/office-categories/${state.user.office_category_id}`
      );

      commit('setUserOfficeCategory', response.data.data);

      return response;
    } catch (error) {
      if (error.response.data.errors) {
        dispatch(
          'alert/createAlert',
          {
            data: error.response.data.errors[0].detail,
            status: 'error',
          },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.message, status: 'error' },
          { root: true }
        );
      }

      commit('setErrorUserOfficeCategory', error.response.data.message);
    } finally {
      commit('setLoaderUserOfficeCategory', true);
    }
  },
  async register({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/register', payload);

      commit('setLoginSuccess', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.message);
    }
    commit('setLoader', false);
  },

  /**
   * Ngereset LocalStorage & bikin router baru
   */

  logout({ commit }) {
    resetRouter();

    ApiService.removeHeader();
    localStorage.clear();

    commit('setLoginSuccess', null);
    commit('setUser', null);
    commit('setUserOfficeCategory', null);
    commit('setLoader', false);
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
