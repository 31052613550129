<template>
  <div>
    <base-card title="Ganti Email">
      <form @submit.prevent="onSubmit" class="space-y-4">
        <base-input
          inset
          label="Email Baru"
          type="email"
          required
          placeholder="Masukkan Email Baru"
          v-model="email"
        />
        <div class="flex justify-end">
          <base-button>Update</base-button>
        </div>
      </form>
    </base-card>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/update-email`,
        {
          method: 'patch',
          data: {
            email: this.email,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.email = null;

        this.createAlert({
          status: 'success',
          data: 'Email berhasil diubah',
        });

        this.$emit('success')
      }

      this.loading = false;
    },
  },
};
</script>
