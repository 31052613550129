<template>
  <div
    :class="{ html2pdf__page: asContent }"
    :slot="asContent ? 'pdf-content' : null"
  >
    <section class="html2pdf__page-break px-4 pt-1 space-y-2 text-sm">
      <div class="flex items-center justify-between gap-8">
        <div class="flex-shrink-0 whitespace-nowrap">
          <p>PT. NATURAL NUSANTARA</p>
          <p class="text-xs">Jl. Ring Road Barat No. 72 Salakan</p>
          <p class="text-xs">Trihanggo Gamping Sleman Jogjakarta</p>
          <p class="text-xs">Telp : 0274-6499191</p>
          <p class="text-xs">Fax : 0274-6499192, 0274-6499193</p>
        </div>
        <p class="font-bold text-lg whitespace-nowrap">TANDA TERIMA BARANG</p>
        <div>
          <div class="grid grid-cols-9">
            <p class="col-span-4">Nomor</p>
            <p>:</p>
            <p class="col-span-4">{{ order.code }}</p>
          </div>
          <div class="grid grid-cols-9">
            <p class="col-span-4">Tanggal</p>
            <p>:</p>
            <p class="col-span-4 font-bold">{{ order.date | formatDate('DD/MM/YYYY') }}</p>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 pb-4">
        <p class="col-span-3">Kode Stokcist</p>
        <p>:</p>
        <p class="col-span-8">{{ order.destinationOfficeCode }}</p>

        <p class="col-span-3">Nama Stokcist</p>
        <p>:</p>
        <p class="col-span-8">{{ order.destinationOfficeName }}</p>

        <p class="col-span-3">Alamat</p>
        <p>:</p>
        <p class="col-span-8">{{ order.destinationOfficeAddress }}</p>
      </div>

      <table class="border w-full border-black text-sm">
        <tr class="border-b border-black">
          <th class="pb-4 px-4 text-left">Kode</th>
          <th class="pb-4 px-4 text-left">Nama Barang</th>
          <th class="pb-4 px-4 text-right">Jumlah</th>
          <th class="pb-4 px-4 text-left">Cek</th>
          <th class="pb-4 px-4 text-left">Keterangan</th>
        </tr>
        <tbody class="border-b border-black">
          <tr v-for="detail in order.details" :key="detail.id">
            <td class="pb-4 px-4">{{ detail.product_code }}</td>
            <td class="pb-4 px-4">{{ detail.product_name }}</td>
            <td class="pb-4 px-4 text-right">{{ detail.product_qty }}</td>
            <td class="px-4">
              <div class="w-6 h-5 border border-black"></div>
            </td>
          </tr>
        </tbody>
        <tr class="border-b border-black">
          <td colspan="5" class="pb-4 px-4">
            <strong>Keterangan:</strong>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="pb-4 px-4">
            <div class="flex justify-between items-end mb-2">
              <div>
                <p class="text-xs">Barang-barang di atas telah diterima dalam keadaan baik dan lengkap</p>
                <div class="text-sm flex">
                  <span class="mr-4">Tanggal</span>
                  <div class="pt-2 border-b border-dashed border-gray-400 pb-2 flex">
                    <div class="text-right" style="width: 32px;">/</div>
                    <div class="text-right" style="width: 46px;">/</div>
                    <div class="text-right" style="width: 56px;"></div>
                  </div>
                </div>
                <div style="width: 210px;">
                  <div style="height: 50px;" class="border-b border-dashed border-gray-400"></div>
                  <p class="text-xs text-center">Penerima</p>
                </div>
              </div>
              <div>
                <div style="width: 180px;" class="text-center">
                  <strong>PT. NATURAL NUSANTARA</strong>
                  <div style="height: 80px;" class="border-b border-dashed border-gray-400"></div>
                  <p class="text-xs">Pengirim</p>
                </div>
              </div>
            </div>
            <p>
              <strong>Catatan</strong> : <span class="text-xs">Pengaduan / komplain barang tidak dilayani setelah tanda terima ini ditandatangani</span>
            </p>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>
<script>
export default {
  name: 'PrintKemasan',
  props: {
    asContent: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({
        code: null,
        date: null,
        destinationOfficeCode: null,
        destinationOfficeName: null,
        destinationOfficeAddress: null,
        details: []
      })
    },
  },
  methods: {},
};
</script>
