<template>
  <base-wrapper :loading="loading">
    <datatable
      :columns="columns"
      :total="productCategories.meta.page.total"
      :perPage="productCategories.meta.page.perPage"
      :currentPage="productCategories.meta.page.currentPage"
      @pagechanged="onPagechanged"
    >
      <template v-slot:tbody="{ classes }">
        <tr
          v-for="productCategory in productCategories.data"
          :key="productCategory.id"
          :class="[classes.tr, 'bg-white']"
        >
          <td :class="[classes.td]">
            <p class="font-bold text-gray-900">
              {{ productCategory.attributes.code || '-' }}
            </p>
          </td>
          <td :class="[classes.td]">
            {{ productCategory.attributes.name }}
          </td>
        </tr>
      </template>
    </datatable>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      productCategories: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode',
        },
        {
          id: 'name',
          name: 'Name',
        },
      ];
    },
  },
  methods: {
    onPagechanged(page) {
      this.loadProductCategories({
        'page[number]': page,
      });
    },
    async loadProductCategories(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/product-categories', {
        params: {
          'page[size]': 5,
          sort: '-createdAt',
          ...params,
        },
      });

      if (!error) {
        this.productCategories = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadProductCategories();
  },
};
</script>
