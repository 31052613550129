<template>
  <div>
    <base-qr-scan-modal
      :visible="visible"
      play-sound-on-scanned
      dynamic-qr-box
      :with-camera-scan="withCameraScan"
      @close="$emit('close')"
      @scanned="onQrScanned"
    >
      <template #bottom>
        <div class="flex gap-x-4">
          <base-input
            type="text"
            placeholder="Kode Qr"
            fullwidth
            :shadow="false"
            v-model="code"
          />
          <base-button v-on:click="onSave">Simpan</base-button>
        </div>
      </template>
    </base-qr-scan-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseQrScanModal from '@/components/base/BaseQrScanModal.vue';
import BaseInput from '@/components/base/BaseInput';
import BaseButton from '@/components/base/BaseButton';
import { requestMixin } from '@/mixins/request/request';

export default {
  components: {
    BaseQrScanModal,
    BaseInput,
    BaseButton,
  },
  mixins: [requestMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    withCameraScan: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['updated', 'close'],
  data() {
    return {
      code: null,
      loading: false,
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.resetCode();
      }
    },
  },
  methods: {
    ...mapActions({
      updateProductWithParams: 'products/updateProductWithParams',
    }),
    onBarcodeScan(e) {
      if (this.visible) {
        this.code = e.detail.text;
      }
    },
    onQrScanned(code) {
      this.code = code;
    },
    async onSave() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/products/${this.product.data.id}`,
        {
          method: 'patch',
          data: {
            data: {
              id: this.product.data.id,
              type: 'products',
              attributes: {
                qrcode: this.code,
              },
            },
          },
        }
      );

      if (!err) {
        this.$emit('updated');
      }

      this.loading = false;
    },
    resetCode() {
      this.code = this.product.data.attributes.qrcode;
    },
  },
  mounted() {
    document.addEventListener('barcode-scan', this.onBarcodeScan);
  },
  destroyed() {
    document.removeEventListener('barcode-scan', this.onBarcodeScan);
  },
};
</script>
