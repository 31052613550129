<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-6xl"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        :with-style="false"
        title="Daftar Barang"
        description="Berdasarkan data barang yang telah dibuat"
      >
        <div class="space-y-4 border-t pt-5">
          <div class="flex justify-end">
            <base-input
              type="search"
              placeholder="Cari kode atau nama"
              :shadow="false"
              v-model="filter.search"
              debounce
              @native-input="loadProducts"
            />
          </div>
          <datatable
            :columns="[
              { id: 'code', name: 'Kode Barang' },
              { id: 'name', name: 'Name Barang' },
              { id: 'category', name: 'Kelompok Produk' },
              { id: 'unit', name: 'Unit' },
            ]"
            :total="products.meta.page.total"
            :perPage="products.meta.page.perPage"
            :currentPage="products.meta.page.currentPage"
            @pagechanged="onChangePage"
          >
            <template #tbody="{ classes }">
              <tr
                v-for="product in products.data"
                :key="product.id"
                :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                @click="onClick(product)"
              >
                <td :class="[classes.td, 'font-bold text-gray-900']">
                  {{ product.attributes.code }}
                </td>
                <td :class="classes.td">{{ product.attributes.name }}</td>
                <td :class="classes.td">
                  {{ product.attributes.product_category_name }}
                </td>
                <td :class="classes.td">{{ product.attributes.unit_name }}</td>
              </tr>
            </template>
          </datatable>

          <div class="flex justify-end">
            <base-button @click="$emit('close')">Tutup</base-button>
          </div>
        </div>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close', 'click-product'],
  data() {
    return {
      filter: {
        search: null,
      },
      loading: false,
      products: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  methods: {
    async loadProducts(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/products', {
        params: {
          'page[size]': 5,
          'fields[products]': 'code,name,product_category_name,unit_name',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.products = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadProducts({
        'page[number]': page,
      });
    },
    onClick(product) {
      this.$emit('click-product', product);
    },
    onOpened() {
      this.loadProducts();
    },
  },
};
</script>
