<template>
  <base-wrapper>
    <section>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-6">
          <CashbillStat />
          <div class="flex flex-col flex-grow gap-4 sm:flex-row sm:justify-end">
            <MitraPeriodMonthSelect
              v-model="filter.periodId"
              v-on:change="handleChangeFilter"
            />
            <base-input label="Status Faktur">
              <base-select
                :shadow="false"
                v-model="filter.order_status"
                @change="handleChangeFilter"
                id="destination"
                :options="[
                  {
                    key: 'null',
                    value: null,
                    label: 'Semua',
                  },
                  {
                    key: 'true',
                    value: true,
                    label: 'Valid',
                  },
                  {
                    key: 'reported',
                    value: 'reported',
                    label: 'Valid'
                  },
                  {
                    key: 'false',
                    value: false,
                    label: 'Draft',
                  },
                  {
                    key: 'cancel',
                    value: 'cancel',
                    label: 'Cancel'
                  },
                  {
                    key: 'deleted',
                    value: 'deleted',
                    label: 'Hapus'
                  },
                ]"
              />
            </base-input>
            <base-input
              :shadow="false"
              fullwidth
              :lg-fullwidth="false"
              label="Pencarian"
              type="search"
              v-model="search"
              debounce
              @native-input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
        </div>
        <Datatable
          :total="getOrders.meta.page.total"
          :perPage="getOrders.meta.page.perPage"
          :currentPage="getOrders.meta.page.currentPage"
          @pagechanged="onPageChangeOrder"
          :is-empty="!getOrders.data.length"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  No. Cashbill
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Mitra Usaha
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  PV
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  BV
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Upline
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Total Harga Mitra Usaha
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status Laporan
                </th>
                <th
                  scope="col"
                  class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status Faktur
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody class="relative">
            <div v-if="isLoading" class="flex items-center text-center">
              <loading></loading>
            </div>
            <tbody v-if="getOrders.data.length">
              <tr
                class="cursor-pointer bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in getOrders.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                @click.prevent="onClickRow(data)"
              >
                <td
                  class="whitespace-nowrap px-4 py-4 text-sm text-gray-900"
                >
                  <div class="text-sm font-bold text-gray-900">
                    {{ data.attributes.origin_code }} /
                    <span class="text-xs text-gray-400">{{
                      data.attributes.destination_code
                    }}</span>
                  </div>
                  <div class="text-xs text-gray-500">
                    {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                  </div>
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                >
                  <p class="font-bold text-gray-900">
                    {{
                      getSingleIncluded(
                        getOrders,
                        data.relationships['destination-office'].data.id
                      ).attributes.code
                    }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{
                      getSingleIncluded(
                        getOrders,
                        data.relationships['destination-office'].data.id
                      ).attributes.name
                    }}
                  </p>
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
                >
                  {{ data.attributes.grand_total_pv | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
                >
                  {{ data.attributes.total_bonus_value | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-center text-sm text-gray-500"
                >
                  <p class="text-gray-700">
                    {{
                      getSingleIncluded(
                        getOrders,
                        data.relationships['destination-office'].data.id
                      ).attributes.referred_by ?? '-'
                    }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{
                      getSingleIncluded(
                        getOrders,
                        data.relationships['destination-office'].data.id
                      ).attributes.referred_by_name ?? '-'
                    }}
                  </p>
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
                >
                  {{ data.attributes.final_price | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-left text-sm text-gray-500"
                >
                  <span
                    v-if="data.attributes.is_bonus_reported"
                    class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
                  >
                    Dilaporkan
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
                  >
                    Belum Dilaporkan
                  </span>
                </td>
                <td
                  class="whitespace-nowrap px-4 py-4 text-left text-sm text-gray-500"
                >
                  <span
                    v-if="data.attributes.order_status"
                    class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
                  >
                    Valid
                  </span>
                  <span
                    v-else-if="
                      !data.attributes.order_status &&
                      !data.attributes.canceled_by_user_id &&
                      !data.attributes.canceled_from
                    "
                    class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700"
                  >
                    Draft
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
                  >
                    Revisi
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>
    </section>

    <CashbillDetailModal
      :order="dataPenjualan"
      :office="getOffice"
      :visible="openDetail"
      @close="openDetail = false"
      @refresh="handleRefresh"
      @edit="openEditOrder"
      @delete="confirmationDelete = true"
    />

    <!--DELETE MODAL CONFIRMATION-->
    <transition name="fade">
      <Modal2
        v-if="confirmationDelete"
        @close-modal="confirmationDelete = false"
        colorheading="bg-red-50"
        colorbody="bg-red-50"
      >
        <template v-slot:heading>
          <h3
            class="text-lg font-semibold leading-6 text-gray-900"
            id="modal-title "
          >
            Hapus faktur penjualan
          </h3>
        </template>
        <template v-slot:body>
          <div
            class="flex relative content-center items-center justify-center py-2"
          >
            <svg
              class="text-red-100"
              width="194"
              height="191"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                fill="currentColor"
              />
            </svg>
            <div class="flex absolute w-full justify-center">
              <DocumentDelete class="h-32" />
            </div>
          </div>
          <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
            Faktur penjualan dengan Nomor
            <span class="font-bold">{{
              dataPenjualan.attributes.origin_code
            }}</span>
            akan dihapus. Apakah anda yakin ingin melanjutkan?
          </h3>
        </template>
        <template v-slot:footer>
          <div>
            <button
              @click="deleteOrder"
              class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Ya, saya yakin
            </button>
            <button
              @click="confirmationDelete = !confirmationDelete"
              class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
            >
              Kembali
            </button>
          </div>
        </template>
      </Modal2>
    </transition>

    <transition name="fade">
      <Modal2
        v-if="deleteStatus"
        @close-modal="deleteStatus = false"
        :footer="false"
        colorheading="bg-red-50"
        colorbody="bg-red-50"
      >
        <template v-slot:heading> </template>
        <template v-slot:body>
          <div
            class="flex relative content-center items-center justify-center py-2"
          >
            <svg
              class="text-red-100"
              width="194"
              height="191"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                fill="currentColor"
              />
            </svg>
            <div class="flex absolute w-full justify-center">
              <DocumentSuccess class="h-32" />
            </div>
          </div>
          <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
            Faktur penjualan dengan Nomor
            <span class="font-bold">{{
              dataPenjualan.attributes.origin_code
            }}</span>
            berhasil dihapus.
          </h3>
        </template>
      </Modal2>
    </transition>

    <!-- MODAL EDIT CONFIRMATION-->
    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="confirmationEdit"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Edit Faktur Penjualan
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Faktur Penjualan yang lama akan dihapus dan diganti faktur
                    yang baru. Apakah anda yakin?
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <base-button
                type="button"
                fullwidth
                @click="editOrder(dataPenjualan)"
              >
                Ya
              </base-button>
              <base-button
                fullwidth
                color="white"
                @click="confirmationEdit = !confirmationEdit"
                type="button"
              >
                Tutup
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </base-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import Datatable from '@/components/base/Datatable';
import Loading from '@/components/Loading.vue';
import Modal2 from '@/components/Modal2.vue';
import { StorageService } from '@/services/storage.service';
import { DocumentSuccess, DocumentDelete } from '@/assets/icons';
import {
  getListMonths,
  getListPastNYear,
  getCurrentDate,
} from '@/services/date.service.js';
import CashbillDetailModal from '@/components/cash-bill/CashbillDetailModal.vue';
import CashbillStat from '@/components/cash-bill/CashbillStat.vue';
import MitraPeriodMonthSelect from '@/components/period/mitra-period/mitra-period-month-select.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    Loading,
    Modal2,
    DocumentSuccess,
    DocumentDelete,
    CashbillDetailModal,
    CashbillStat,
    MitraPeriodMonthSelect,
  },

  data() {
    return {
      filter: {
        month: getCurrentDate().month,
        year: getCurrentDate().year,
        order_status: false,
        bonus_month: null,
        is_prev_bonus: true,
        periodId: null,
      },
      search: null,
      openDetail: false,
      confirmationDelete: false,
      deleteStatus: false,
      confirmationEdit: false,
      dataPenjualan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getOffice: 'offices/getOffice',
      isLoading: 'orders/getLoading',
    }),
    ordersParams() {
      const params = {
        'filter[origin_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'page[limit]': 5,
        'filter[order_type]': 'sale',
        'filter[is_prev_bonus]': this.filter.is_prev_bonus,
        search: this.search,
        'filter[destination_office_category_id]': 5,
        'filter[mitra_period_uuid]': this.filter.periodId,
        include: 'destination-office,origin-office',
        fields: {
          offices: 'name,code,address,address2,referred_by,referred_by_name',
          orders:
            'origin_office_code,destination_office_code,is_valid_for_packing,is_valid_for_shipment,is_shipped,is_completed,has_follow_up_delivery,is_pending_follow_up_delivery,updatedAt,final_price,destination-office,origin-office,origin_code,destination_code,is_bonus_reported,grand_total_weight,order_status,createdAt,is_prev_bonus,grand_total_pv,total_bonus_value',
        },
      };
      return params;
    },
    availableMonthOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListMonths().map((month, index) => ({
          key: month,
          value: index + 1,
          label: month,
        })),
      ];
    },
    availablYearOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListPastNYear().map((year) => ({
          key: year,
          value: year,
          label: year,
        })),
      ];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
      duplicateOrder: 'orders/duplicateOrder',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchCurrentPeriod: 'mitra_periods/fetchCurrentMitraPeriod',
    }),
    handleChangeFilter() {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.ordersParams,
        pageNumber: page,
      });
    },
    onClickRow(data) {
      this.openDetail = !this.openDetail;
      Promise.all([
        this.fetchOrder({
          id: data.attributes.order_id,
          fields: {
            'order-details':
              'total_price,product_qty,product_weight,total_weight,product_name,product_code',
          },
        }),
        this.fetchOfficeById({
          office_id: data.relationships['destination-office'].data.id,
          params: {
            include: 'uplines,upline-relations',
          },
        }),
      ]).then(() => {
        this.dataPenjualan = this.getOrder.data;
      });
    },
    openEditOrder() {
      if (
        this.dataPenjualan.attributes.canceled_from &&
        this.dataPenjualan.attributes.canceled_by_user_id
      ) {
        this.confirmationEdit = !this.confirmationEdit;
      } else {
        this.$router.push(
          `/cashbill/pencatatan-tambah-poin/${this.dataPenjualan.id}`
        );
      }
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            order_status: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            origin_warehouse_id: StorageService.getUser().current_warehouse,
            pageNumber: 1,
            pageSize: 5,
            destination_office_category: 'Member',
            fields: this.fields,
          });
        }
      });
    },
    deleteOrder() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updateOrder(payload).then((response) => {
        this.confirmationDelete = !this.confirmationDelete;
        this.deleteStatus = true;
        if (response) {
          this.fetchOrders({
            ...this.ordersParams,
          });
          this.openDetail = !this.openDetail;
        } else {
          this.failed = true;
        }
      });
    },
    editOrder(data) {
      this.duplicateOrder({
        id: data.id,
      }).then((response) => {
        if (response) {
          this.$router.push(
            `/penjualan/transaksi-penjualan/${response.data.data.id}`
          );
        }
      });
    },
    cetakInvoice() {
      this.$refs.html2Pdf.generatePdf();
    },
    handleRefresh: function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    async loadCurrentPeriod() {
      const res = await this.fetchCurrentPeriod({
        'fields[mitra-periods]': 'month,year,name',
      });

      this.filter.periodId = res.data.data.id;
    },
  },
  async created() {
    await this.loadCurrentPeriod();

    this.fetchOrders({
      ...this.ordersParams,
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
