var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loadingKantorPelayanan}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('base-input',{attrs:{"type":"search","placeholder":"Cari Kantor Pelayanan","debounce":"","shadow":false},on:{"native-input":_vm.loadKantorPelayanan},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-button',{attrs:{"type":"button"},on:{"click":_vm.onCreate}},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:plus-20-solid"}}),_vm._v(" Tambah Kantor Pelayanan ")],1)],1),_c('datatable',{attrs:{"total":_vm.kantorPelayanans.meta.page.total,"perPage":_vm.kantorPelayanans.meta.page.perPage,"currentPage":_vm.kantorPelayanans.meta.page.currentPage,"meta":_vm.kantorPelayanans.meta,"columns":[
        { id: 'code', name: 'Kode Kantor Pelayanan' },
        { id: 'name', name: 'Nama Kantor Pelayanan' },
        { id: 'area', name: 'Area' },
        { id: 'bank_account_number', name: 'No. Rekening' },
        { id: 'phone', name: 'Alamat / No. Telp' },
      ],"cursor":""},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.kantorPelayanans.data),function(kantorPelayanan){return _c('tr',{key:kantorPelayanan.id,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){return _vm.onClickDetail(kantorPelayanan)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(kantorPelayanan.attributes.code)+" ")])]),_c('td',{class:classes.td},[_c('p',{staticClass:"text-gray-900 mb-1"},[_vm._v(_vm._s(kantorPelayanan.attributes.name))]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.getOfficeIncluded(kantorPelayanan).attributes.email ?? ''))])]),_c('td',{class:classes.td},[_vm._v(_vm._s(_vm.getOfficeIncluded(kantorPelayanan).attributes.area_name ?? '-'))]),_c('td',{class:classes.td},[_vm._v(_vm._s(_vm.getOfficeIncluded(kantorPelayanan).attributes.bank_account_number ?? '-'))]),_c('td',{class:classes.td},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(kantorPelayanan).attributes.address ?? '-')+" ")]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(kantorPelayanan).attributes.phone ?? '')+" ")])])])])})}}])})],1),_c('office-form-modal',{attrs:{"office-type":"kantor_pelayanan","visible":_vm.createModalVisible},on:{"close":function($event){_vm.createModalVisible = false},"success":_vm.onSuccessCreate}}),_c('view-office-modal',{attrs:{"office-type":"kantor_pelayanan","loading":_vm.detailModal.loading,"visible":_vm.detailModal.visible,"office-id":_vm.detailModal.officeId},on:{"close":function($event){_vm.detailModal.visible = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }