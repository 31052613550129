var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex flex-wrap justify-end gap-2"},[_c('base-button',{staticClass:"md:hidden",attrs:{"color":"white"},on:{"click":function($event){_vm.visibleFilter = !_vm.visibleFilter}}},[_vm._v("Filter")]),_c('base-button',{attrs:{"to":{ name: 'penjualan-pbs.tambah' }}},[_vm._v("Tambah Penjualan P3S")]),_c('div',{class:[
        'w-full flex-col gap-2 md:flex md:w-auto md:flex-row md:order-first',
        _vm.visibleFilter ? 'flex' : 'hidden'
      ]},[_c('base-input',{attrs:{"type":"search","shadow":false,"fullwidth":"","placeholder":"Cari Kode","debounce":""},on:{"native-input":_vm.loadStockLoanMovements},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-select',{attrs:{"expand":true,"md-expand":false,"shadow":false,"options":[
            { key: 'all', label: 'Status Faktur', value: null },
            { key: 'draft', label: 'Draft', value: false },
            { key: 'valid', label: 'Valid', value: true },
          ]},on:{"change":_vm.loadStockLoanMovements},model:{value:(_vm.filter.is_valid),callback:function ($$v) {_vm.$set(_vm.filter, "is_valid", $$v)},expression:"filter.is_valid"}}),_c('base-select',{attrs:{"expand":true,"md-expand":false,"shadow":false,"options":[
            { key: 'all', label: 'Status Laporan', value: null },
            { key: 'draft', label: 'Belum Dilaporkan', value: false },
            { key: 'valid', label: 'Dilaporkan', value: true },
          ]},on:{"change":_vm.loadStockLoanMovements},model:{value:(_vm.filter.is_reported),callback:function ($$v) {_vm.$set(_vm.filter, "is_reported", $$v)},expression:"filter.is_reported"}})],1)],1),_c('datatable',{attrs:{"columns":_vm.tableColumns,"total":_vm.stockLoanMovements.meta.page.total,"perPage":_vm.stockLoanMovements.meta.page.perPage,"currentPage":_vm.stockLoanMovements.meta.page.currentPage},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.stockLoanMovements.data),function(stockLoanMovement,index){return _c('tr',{key:index,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){return _vm.onView(stockLoanMovement)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(stockLoanMovement.attributes.code)+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(stockLoanMovement.attributes.createdAt))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stockLoanMovement.attributes.items_qty))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stockLoanMovement.attributes.total_price))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":stockLoanMovement.attributes.valid ? 'green' : 'yellow'}},[_vm._v(_vm._s(stockLoanMovement.attributes.valid ? 'Valid' : 'Draft'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":stockLoanMovement.attributes.reported ? 'indigo' : 'yellow'}},[_vm._v(_vm._s(stockLoanMovement.attributes.reported ? 'Dilaporkan' : 'Belum Dilaporkan'))])],1)])})}}])})],1),_c('view-modal',{attrs:{"visible":_vm.viewModal.visible,"stock-loan-movement-id":_vm.viewModal.id},on:{"close":function($event){_vm.viewModal.visible = false},"edit":function($event){return _vm.$router.push({
        name: 'penjualan-pbs.edit',
        params: { id: _vm.viewModal.id },
      })},"validated":_vm.onValidated}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }