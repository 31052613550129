<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-6xl"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card :with-style="false" :title="`Tambah ${label}`">
        <form class="grid grid-cols-6 gap-4 border-t pt-5" @submit.prevent="onSubmit">
          <search-office-input
            :office-type="officeType"
            mode="input"
            class="col-span-3"
            v-model="form.officeSearch"
            @search="onChangeOffice"
          />
          <base-input
            type="text"
            inset
            :label="`Nama ${label}`"
            placeholder="Herbal Murni Indonesia"
            class="col-span-3"
            v-model="form.officeSearch.name"
          />
          <base-input
            type="text"
            inset
            label="No. Telp"
            placeholder="08xxxxxxxx"
            class="col-span-3"
            v-model="form.phone"
          />
          <base-input
            type="text"
            inset
            label="Email"
            placeholder="hmi@naturalnusantara.co.id"
            class="col-span-3"
            v-model="form.email"
          />

          <h2 class="col-span-full font-bold font-bold text-gray-900">
            Informasi Alamat
          </h2>
          <base-input inset label="Provinsi" class="col-span-3">
            <base-select
              inset
              expand
              expand-md
              :options="provinceOptions"
              v-model="form.address.province"
              @change="onChangeProvince"
            />
          </base-input>
          <base-input inset label="Kabupaten" class="col-span-3">
            <base-select
              inset
              expand
              expand-md
              :options="cityOptions"
              v-model="form.address.city"
              @change="onChangeCity"
            />
          </base-input>
          <base-input inset label="Kecamatan" class="col-span-3">
            <base-select
              inset
              expand
              expand-md
              :options="districtOptions"
              v-model="form.address.district"
              @change="onChangeDistrict"
            />
          </base-input>
          <base-input inset label="Desa" class="col-span-3">
            <base-select
              inset
              expand
              expand-md
              :options="villageOptions"
              v-model="form.address.village"
            />
          </base-input>
          <base-input
            inset
            label="Alamat"
            placeholder="Jln. xxxx"
            tag="textarea"
            class="col-span-full"
            v-model="form.address.address"
          />
          <base-input
            type="text"
            inset
            label="No Akun GL"
            placeholder="0132019"
            class="col-span-full"
            v-model="form.noAccountGl"
          />
          <base-input
            type="text"
            inset
            label="Cost Center"
            placeholder="0132019"
            class="col-span-full"
            v-model="form.costCenter"
          />

          <div class="col-span-full flex justify-end gap-2">
            <base-button type="submit">Simpan</base-button>
            <base-button type="button" color="white" @click="$emit('close')">Tutup</base-button>
          </div>
        </form>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import SearchOfficeInput from './search-office-input.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    SearchOfficeInput,
  },
  props: {
    officeType: {
      type: String,
      required: true,
    },
    visible: Boolean,
  },
  emits: ['close', 'success'],
  data() {
    return {
      form: {
        officeSearch: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
        },
        phone: null,
        email: null,
        noAccountGl: null,
        costCenter: null,
        address: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          area: {
            id: null,
            code: null,
          },
          address: null,
        },
      },
      loading: false,
      provinces: {
        data: [],
      },
      cities: {
        data: [],
      },
      districts: {
        data: [],
      },
      villages: {
        data: [],
      },
      officeCategories: {
        data: [],
      },
    };
  },
  computed: {
    label() {
      return {
        supplier: 'Supplier',
        kantor_pelayanan: 'Kantor Pelayanan',
      }[this.officeType];
    },
    provinceOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Provinsi',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.provinces.data.map((province) => ({
          key: province.id,
          label: province.attributes.name,
          value: {
            id: province.id,
            name: province.attributes.name,
          },
        })),
      ];
    },
    cityOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kabupaten',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.cities.data.map((city) => ({
          key: city.id,
          label: city.attributes.name,
          value: {
            id: city.id,
            name: city.attributes.name,
          },
        })),
      ];
    },
    districtOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kecamatan',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.districts.data.map((district) => ({
          key: district.id,
          label: district.attributes.name,
          value: {
            id: district.id,
            name: district.attributes.name,
          },
        })),
      ];
    },
    villageOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Desa',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.villages.data.map((village) => ({
          key: village.id,
          label: village.attributes.name,
          value: {
            id: village.id,
            name: village.attributes.name,
          },
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async createOffice(payload) {
      this.loading = true;

      const [, err] = await this.request('/api/v1/offices', {
        method: 'post',
        data: payload,
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.createAlert({
          status: 'success',
          data: `${this.label} Berhasil Ditambahkan`,
        });

        this.$emit('success');
      }

      this.loading = false;
    },
    async loadOfficeCategories() {
      const [res, err] = await this.request('/api/v1/office-categories', {
        params: {
          'fields[office-categories]': 'name',
        },
      });

      if (!err) {
        this.officeCategories = res;
      }
    },
    async loadProvinces() {
      const [res, err] = await this.request('/api/v1/provinces');

      if (!err) {
        this.provinces = res;
      }
    },
    async loadCities() {
      const [res, err] = await this.request(
        `/api/v1/provinces/${this.form.address.province.id}/cities`,
        {
          params: {
            include: 'area',
          },
        }
      );

      if (!err) {
        this.cities = res;
      }
    },
    async loadDistricts() {
      const [res, err] = await this.request(
        `/api/v1/cities/${this.form.address.city.id}/districts`
      );

      if (!err) {
        this.districts = res;
      }
    },
    async loadVillages() {
      const [res, err] = await this.request(
        `/api/v1/districts/${this.form.address.district.id}/villages`
      );

      if (!err) {
        this.villages = res;
      }
    },
    async onChangeOffice() {
      if (this.form.officeSearch.id) {
        this.loading = true;

        const [res, err] = await this.request(
          `/api/v1/offices/${this.form.officeSearch.id}`,
          {
            params: {
              include: 'addresses,area',
              'fields[offices]':
                'no_account_gl,cost_center,phone,email,address,addresses,area',
            },
          }
        );

        if (!err) {
          this.form.phone = res.data.attributes.phone;
          this.form.email = res.data.attributes.email;
          this.form.noAccountGl = res.data.attributes.no_account_gl;
          this.form.costCenter = res.data.attributes.cost_center;
          this.form.address.address = res.data.attributes.address;

          if (res.data.relationships.addresses.data.length) {
            const [address] = this.getIncludedByType(res, 'addresses');
            const area = this.getSingleIncluded(
              res,
              res.data.relationships.area.data.id
            );

            this.form.address.province.id = address.attributes.province_id;
            this.form.address.province.name = address.attributes.province_name;

            await this.loadCities();

            this.form.address.city.id = address.attributes.city_id;
            this.form.address.city.name = address.attributes.city_name;

            this.form.address.area.id = area.id;
            this.form.address.area.name = area.attributes.name;

            if (address.attributes.district_id) {
              await this.loadDistricts();

              this.form.address.district.id = address.attributes.district_id;
              this.form.address.district.name =
                address.attributes.district_name;

              if (address.attributes.village_id) {
                await this.loadVillages();

                this.form.address.village.id = address.attributes.village_id;
                this.form.address.village.name =
                  address.attributes.village_name;
              }
            }
          }
        }

        this.loading = false;
      } else {
        this.form.phone = null;
        this.form.email = null;

        this.form.noAccountGl = null;
        this.form.costCenter = null;

        this.form.address.province.id = null;
        this.form.address.province.name = null;

        this.form.address.city.id = null;
        this.form.address.city.name = null;

        this.form.address.district.id = null;
        this.form.address.district.name = null;

        this.form.address.village.id = null;
        this.form.address.village.name = null;

        this.form.address.area.id = null;
        this.form.address.area.name = null;

        this.form.address.address = null;
      }
    },
    onChangeProvince() {
      this.form.address.city.id = null;
      this.form.address.city.name = null;

      this.form.address.district.id = null;
      this.form.address.district.name = null;

      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.form.address.area.id = null;
      this.form.address.area.name = null;

      this.loadCities();
    },
    onChangeCity() {
      const city = this.cities.data.find(
        (city) => city.id === this.form.address.city.id
      );
      const area = this.getSingleIncluded(
        this.cities,
        city.relationships.area.data.id
      );

      this.form.address.area.code = area.attributes.code;
      this.form.address.area.id = area.id;

      this.form.address.district.id = null;
      this.form.address.district.name = null;

      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.loadDistricts();
    },
    onChangeDistrict() {
      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.loadVillages();
    },
    onOpened() {
      this.form.officeSearch.id = null;
      this.form.officeSearch.code = null;
      this.form.officeSearch.searchCode = null;
      this.form.officeSearch.originalCode = null;
      this.form.officeSearch.name = null;

      this.form.phone = null;
      this.form.email = null;
      this.form.noAccountGl = null;
      this.form.costCenter = null;

      this.form.address.province.id = null;
      this.form.address.province.name = null;

      this.form.address.city.id = null;
      this.form.address.city.name = null;

      this.form.address.district.id = null;
      this.form.address.district.name = null;

      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.form.address.area.id = null;
      (this.form.address.area.code = null), (this.form.address.address = null);
    },
    onSubmit() {
      const id = this.form.officeSearch.id;

      const payload = {
        data: {
          type: 'offices',
          ...(id ? { id } : {}),
          attributes: {
            office_type: this.officeType,
            code: this.form.officeSearch.code,
            name: this.form.officeSearch.name,
            phone: this.form.phone,
            email: this.form.email,
            no_account_gl: parseInt(this.form.noAccountGl),
            cost_center: parseInt(this.form.costCenter),
            address_details: [
              {
                province_id: parseInt(this.form.address.province.id),
                city_id: parseInt(this.form.address.city.id),
                district_id: parseInt(this.form.address.district.id),
                village_id: parseInt(this.form.address.village.id),
              },
            ],
          },
          relationships: {
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.officeCategories.data.find(
                  (officeCategory) =>
                    officeCategory.attributes.name === this.label
                ).id,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.form.address.area.id,
              },
            },
          },
        },
      };

      if (id) {
        this.updateOffice(payload);
      } else {
        this.createOffice(payload);
      }
    },
    async updateOffice(payload) {
      this.loading = true;

      const id = this.form.officeSearch.id;

      const [, err] = await this.request(`/api/v1/offices/${id}`, {
        method: 'patch',
        data: payload,
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.createAlert({
          status: 'success',
          data: `${this.label} Berhasil Diperbarui`,
        });

        this.$emit('success');
      }

      this.loading = false;
    },
  },
  created() {
    this.loadOfficeCategories();
    this.loadProvinces();
  },
};
</script>
