<template>
  <div>
    <base-card title="Ganti Password">
      <form @submit.prevent="onSubmit" class="space-y-4">
        <base-input inset label="Kata sandi lama" placeholder="Kata sandi lama" type="password" required v-model="currentPassword" />
        <base-input inset label="Kata sandi baru" placeholder="Kata sandi baru" type="password" required v-model="newPassword" />
        <base-input inset label="Konfrimasi sandi baru" placeholder="Konfrimasi sandi baru" type="password" required v-model="newPasswordConfirmation" />

        <div class="flex justify-end">
          <base-button>Update</base-button>
        </div>
      </form>
    </base-card>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      currentPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/update-password`,
        {
          method: 'patch',
          data: {
            current_password: this.currentPassword,
            new_password: this.newPassword,
            new_password_confirmation: this.newPasswordConfirmation,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.currentPassword = null;
        this.newPassword = null;
        this.newPasswordConfirmation = null;

        this.createAlert({
          status: 'success',
          data: 'Kata sandi berhasil diubah',
        });
      }

      this.loading = false;
    },
  },
};
</script>
