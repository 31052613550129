<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end">
        <base-input :shadow="false" type="text" placeholder="Cari Nama Wilayah" debounce v-model="filter.search" @native-input="loadCities" />
      </div>
      <datatable
        :columns="columns"
        :total="cities.meta.page.total"
        :perPage="cities.meta.page.perPage"
        :currentPage="cities.meta.page.currentPage"
        @pagechanged="onPagechanged"
      >
        <template v-slot:tbody="{ classes }">
          <tr
            v-for="city in cities.data"
            :key="city.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td :class="[classes.td]">
              <p class="font-bold text-gray-900">
                {{ city.attributes.code || '-' }}
              </p>
            </td>
            <td :class="[classes.td]">
              {{ city.attributes.name }}
            </td>
            <td :class="[classes.td]">
              {{ getSingleIncluded(cities, city.relationships.province.data.id).attributes.name }}
            </td>
            <td :class="[classes.td]">
              {{ city.attributes.area_code }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        search: null
      },
      loading: false,
      cities: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode Kota',
        },
        {
          id: 'name',
          name: 'Nama Kota',
        },
        {
          id: 'province',
          name: 'Provinsi',
        },
        {
          id: 'area',
          name: 'Area',
        },
      ];
    },
  },
  methods: {
    onPagechanged(page) {
      this.loadCities({
        'page[number]': page,
      });
    },
    async loadCities(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/cities', {
        params: {
          'page[size]': 5,
          'sort': '-createdAt',
          'include': 'province',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!error) {
        this.cities = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadCities();
  },
};
</script>
