<template>
  <base-wrapper>
    <edit-office-form office-type="stockist" :office-id="$route.params.id" @success="$router.push({ name: 'master.stockist' })" />
  </base-wrapper>
</template>
<script>
import EditOfficeForm from '@/components/office/edit-office-form.vue';

export default {
  name: 'EditStockist',
  components: {
    EditOfficeForm,
  },
};
</script>
