<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search v-model="search" @input="debounceSearch" />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getProducts.meta.page.total"
              :perPage="getProducts.meta.page.perPage"
              :currentPage="getProducts.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sortData('code')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      @click="sortData('name')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kelompok Produk
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getProducts.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailBarang(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.attributes['product_category_name']
                          ? data.attributes['product_category_name']
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <BaseModal
          :showing="openDetail"
          @close="openDetail = !openDetail"
          :showClose="openDetail"
          :backgroundClose="true"
          size="max-w-6xl"
        >
          <template v-if="dataProduct.attributes">
            <div class="flex justify-between">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Detail Barang
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Berdasarkan data barang yang telah dibuat
                </p>
              </div>
              <div>
                <img src="@/assets/images/logo.png" alt="" class="hidden w-52 sm:block" />
              </div>
            </div>
            <div class="border-t border-gray-200 py-5 mt-5">
              <dl
                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6"
              >
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataProduct.attributes.code }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataProduct.attributes.name }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Isi</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataProduct.attributes.volume ?? '-' }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Satuan</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      getProduct.included.find(
                        (it) => it.id == dataProduct.relationships.unit.data.id
                      )?.attributes?.name
                    }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">PV</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataProduct.attributes.point }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">BV</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataProduct.attributes.bonus }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">P/N</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      !!getProduct.data.attributes.is_point
                        ? 'Point'
                        : 'Non Point'
                    }}
                  </dd>
                </div>
              </dl>

              <ProductPriceTable class="mt-8" />
            </div>
            <div class="flex justify-end gap-x-2">
              <base-button @click="openDetail = !openDetail" type="button">
                Tutup
              </base-button>
            </div>
          </template>
        </BaseModal>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <base-button @click="closeModal" color="white">
                      Selesai
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading">
                      {{ getError }}
                    </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="failedModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Ulangi
                    </button>
                    <base-button color="danger" @click="closeModal">
                      Selesai
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseModal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ProductPriceTable from '@/components/product-price/product-price-table.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    BaseModal,
    BaseSearch,
    BaseButton,
    ProductPriceTable,
  },
  data() {
    return {
      search: null,
      openDetail: false,
      success: false,
      failed: false,
      dataProduct: {},
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
      params: {
        fields: {
          products: 'product_category_name,code,supplier_code,name,volume',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getProduct: 'products/getProduct',
      getProductPrices: 'product_prices/getProductPrices',
    }),

    filteredData() {
      return this.getResults.filter((products) =>
        products.attributes.name
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      exportProducts: 'products/exportProducts',
      deleteProduct: 'products/deleteProduct',
      fetchProductById: 'products/fetchProductById',
      fetchProductPrices: 'product_prices/fetchProductPrices',
    }),
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    closeModal() {
      this.success = this.failed = false;
      this.openDetail = !this.openDetail;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
      });
    },
    failedModal() {
      this.failed = !this.failed;
    },
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    getProductPriceValue(areaName, buyerType) {
      let productPrice = this.getProductPrices.data.find(function (item) {
        return (
          item.id === item.id &&
          item.attributes.area === areaName &&
          item.attributes['buyer-type'] === buyerType
        );
      });
      return productPrice;
    },
    onPageChangeProducts(page) {
      this.fetchProducts({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        ...this.params,
      });
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        ...this.params,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        ...this.params,
      });
    }, 300),
    detailBarang(product) {
      this.fetchProductPrices({
        productId: product.id,
        params: {
          'page[size]': 99,
        },
      });
      this.fetchProductById({
        id: product.id,
        include: 'unit',
      }).then(() => {
        this.dataProduct = this.getProduct.data;
        this.openDetail = !this.openDetail;
      });
    },
    downloadProduct() {
      this.exportProducts({
        pageSize: this.getProducts.meta.page.total,
      });
    },
    deleteData(data) {
      this.deleteProduct({
        id: data.id,
      }).then((response) => {
        if (!response) {
          this.failed = false;
        }
      });
    },
  },
  created() {
    this.fetchProducts({
      pageNumber: 1,
      pageSize: 5,
      sortName: this.sort.name,
      sortDirection: this.sort.direction,
      ...this.params,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
