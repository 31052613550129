<template>
  <div>
    <loading v-if="loading" />

    <div class="space-y-4">
      <base-card
        :title="`Edit Data ${label}`"
        :description="`Informasi ${label}`"
      >
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input
            inset
            type="text"
            :label="`Kode ${label}`"
            :placeholder="`Masukkan Kode ${label}`"
            required
            v-model="form.code"
          />
          <base-input
            inset
            type="text"
            :label="`Nama ${label}`"
            :placeholder="`Masukkan Nama ${label}`"
            required
            v-model="form.name"
          />
          <base-input
            inset
            type="text"
            label="No. KTP"
            placeholder="Masukkan No. KTP"
            required
            v-model="form.ktp"
          />
          <base-input
            inset
            type="email"
            label="Email"
            placeholder="Masukkan Email"
            required
            v-model="form.email"
          />
          <base-input
            inset
            type="text"
            label="No. HP"
            placeholder="Masukkan No. HP"
            required
            v-model="form.cellphone"
          />
          <base-input
            inset
            type="date"
            label="Tanggal Lahir"
            placeholder="Masukkan Tanggal Lahir"
            required
            v-model="form.birthdate"
          />
          <base-input inset label="Pendidikan Terakhir">
            <base-select
              inset
              expand
              :options="educationOptions"
              v-model="form.educationId"
            />
          </base-input>
          <base-input inset label="Pekerjaan Terakhir">
            <base-select
              inset
              expand
              :options="professionOptions"
              v-model="form.professionId"
            />
          </base-input>
          <base-input class="col-span-full" label="Jenis Kelamin">
            <div class="flex gap-8">
              <base-radio val="male" label="Laki Laki" v-model="form.sex" />
              <base-radio val="female" label="Perempuan" v-model="form.sex" />
            </div>
          </base-input>
          <base-input class="col-span-full" label="Status Pernikahan">
            <div class="flex gap-8">
              <base-radio
                val="married"
                label="Menikah"
                v-model="form.married"
              />
              <base-radio
                val="single"
                label="Belum Menikah"
                v-model="form.married"
              />
            </div>
          </base-input>
          <template v-if="form.married === 'married'">
            <base-input
              inset
              type="text"
              label="Nama Pasangan"
              placeholder="Masukkan Nama Pasangan"
              v-model="form.spouseName"
            />
            <base-input
              inset
              type="date"
              label="Tanggal Lahir Pasangan"
              v-model="form.spouseBirthdate"
            />
            <base-input
              inset
              type="text"
              label="Nama Pewaris"
              placeholder="Masukkan Nama Pasangan"
              v-model="form.devisorName"
            />
            <base-input
              inset
              type="date"
              label="Tanggal Lahir Pewaris"
              v-model="form.devisorBirthdate"
            />
          </template>
        </div>
      </base-card>

      <base-card title="Alamat" :description="`Informasi Alamat ${label}`">
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input
            inset
            label="Alamat"
            placeholder="Jln. xxxx"
            tag="textarea"
            class="col-span-full"
            v-model="form.address.address"
          />
          <base-input inset label="Provinsi">
            <base-select
              inset
              expand
              :options="provinceOptions"
              v-model="form.address.province"
              @change="onChangeProvince"
            />
          </base-input>
          <base-input inset label="Kabupaten">
            <base-select
              inset
              expand
              :options="cityOptions"
              v-model="form.address.city"
              @change="onChangeCity"
            />
          </base-input>
          <base-input inset label="Kecamatan">
            <base-select
              inset
              expand
              :options="districtOptions"
              v-model="form.address.district"
              @change="onChangeDistrict"
            />
          </base-input>
          <base-input inset label="Desa">
            <base-select
              inset
              expand
              :options="villageOptions"
              v-model="form.address.village"
            />
          </base-input>
          <base-input
            inset
            type="text"
            label="Kode POS"
            placeholder="54831"
            class="col-span-full"
            v-model="form.address.post"
          />
          <base-input
            inset
            disabled
            label="Area"
            placeholder="Area"
            class="col-span-full"
            v-model="form.address.area.code"
          />
        </div>
      </base-card>

      <base-card
        title="Data Bank"
        description="Data ini digunakan untuk pencarian bonus"
      >
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input inset label="Nama Bank">
            <base-select
              inset
              expand
              :options="bankOptions"
              v-model="form.bankId"
            />
          </base-input>
          <base-input
            inset
            type="text"
            label="No. Rekening"
            placeholder="Masukkan No. Rekening"
            v-model="form.bankAccountNumber"
          />
          <base-input
            inset
            type="text"
            label="Cabang Bank"
            placeholder="Masukkan Cabang Bank"
            v-model="form.bankBranch"
          />
          <base-input
            inset
            type="text"
            label="Nama Rekening"
            placeholder="Masukkan Nama Rekening"
            v-model="form.bankAccountName"
          />
        </div>
      </base-card>

      <base-card title="Data NPWP" :description="`Data NPWP ${label}`">
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input
            inset
            type="text"
            label="No. NPWP"
            placeholder="Masukkan No. NPWP"
            v-model="form.npwpNumber"
          />
          <base-input
            inset
            type="text"
            label="Nama NPWP"
            placeholder="Masukkan Nama NPWP"
            v-model="form.npwpName"
          />
        </div>
      </base-card>

      <div class="flex justify-end">
        <base-button @click="onSave">Simpan</base-button>
      </div>
    </div>

    <base-confirm
      :visible="successVisible"
      type="success"
      :with-cancel="false"
      confirm-text="Kembali"
      :message="`${label} berhasil diperbarui`"
      @close="$emit('success')"
      @confirmed="$emit('success')"
    />
  </div>
</template>

<script>
import BaseRadio from '@/components/base/BaseRadio.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseRadio,
    BaseConfirm,
  },
  props: {
    officeId: {
      type: String,
      required: true,
    },
    officeType: {
      type: String,
      required: true,
    },
  },
  emits: ['success'],
  data() {
    return {
      successVisible: false,
      form: {
        id: null,
        code: null,
        name: null,
        ktp: null,
        email: null,
        cellphone: null,
        birthdate: null,
        educationId: null,
        professionId: null,
        sex: 'male',
        married: 'single',
        spouseName: null,
        spouseBirthdate: null,
        devisorName: null,
        devisorBirthdate: null,
        address: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          area: {
            id: null,
            code: null,
          },
          address: null,
          post: null,
        },
        bankId: null,
        bankAccountNumber: null,
        bankBranch: null,
        bankAccountName: null,
        npwpNumber: null,
        npwpName: null,
      },
      provinces: {
        data: [],
      },
      cities: {
        data: [],
      },
      districts: {
        data: [],
      },
      villages: {
        data: [],
      },
      educations: {
        data: [],
      },
      professions: {
        data: [],
      },
      banks: {
        data: [],
      },
      loadingOffice: false,
      loadingEducations: false,
      loadingProfessions: false,
      loadingBanks: false,
      loadingSave: false,
    };
  },
  computed: {
    label() {
      return {
        stockist: 'Stockist',
      }[this.officeType];
    },
    loading() {
      return (
        this.loadingOffice ||
        this.loadingEducations ||
        this.loadingProfessions ||
        this.loadingBanks ||
        this.loadingSave
      );
    },
    educationOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Pendidikan Terakhir',
          value: null,
        },
        ...this.educations.data.map((education) => ({
          key: education.id,
          label: education.attributes.name,
          value: education.id,
        })),
      ];
    },
    professionOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Pendidikan Terakhir',
          value: null,
        },
        ...this.professions.data.map((profession) => ({
          key: profession.id,
          label: profession.attributes.name,
          value: profession.id,
        })),
      ];
    },
    bankOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Bank',
          value: null,
        },
        ...this.banks.data.map((bank) => ({
          key: bank.id,
          label: bank.attributes.name,
          value: bank.id,
        })),
      ];
    },
    provinceOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Provinsi',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.provinces.data.map((province) => ({
          key: province.id,
          label: province.attributes.name,
          value: {
            id: province.id,
            name: province.attributes.name,
          },
        })),
      ];
    },
    cityOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kabupaten',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.cities.data.map((city) => ({
          key: city.id,
          label: city.attributes.name,
          value: {
            id: city.id,
            name: city.attributes.name,
          },
        })),
      ];
    },
    districtOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kecamatan',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.districts.data.map((district) => ({
          key: district.id,
          label: district.attributes.name,
          value: {
            id: district.id,
            name: district.attributes.name,
          },
        })),
      ];
    },
    villageOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Desa',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.villages.data.map((village) => ({
          key: village.id,
          label: village.attributes.name,
          value: {
            id: village.id,
            name: village.attributes.name,
          },
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadOffice() {
      this.loadingOffice = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.officeId}`,
        {
          params: {
            include: 'education,profession,addresses,area,bank',
            'fields[offices]':
              'code,name,ktp_number,email,cellphone,birthdate,sex,marital_status,spouse_name,spouse_birthdate,devisor_name,devisor_birthdate,education,profession,addresses,area,address,postal_code,bank,bank_account_number,bank_account_name,bank_branch_name,npwp_number,npwp_name',
            'fields[educations]': 'name',
            'fields[professions]': 'name',
            'fields[areas]': 'code',
          },
        }
      );

      if (!err) {
        this.form.id = res.data.id;
        this.form.code = res.data.attributes.code;
        this.form.name = res.data.attributes.name;
        this.form.ktp = res.data.attributes.ktp_number;
        this.form.email = res.data.attributes.email;
        this.form.cellphone = res.data.attributes.cellphone;
        this.form.birthdate = res.data.attributes.birthdate;
        this.form.sex = res.data.attributes.sex;
        this.form.married = res.data.attributes.marital_status ?? 'single';
        this.form.spouseName = res.data.attributes.spouse_name;
        this.form.spouseBirthdate = res.data.attributes.spouse_birthdate;
        this.form.devisorName = res.data.attributes.devisor_name;
        this.form.devisorBirthdate = res.data.attributes.devisor_birthdate;

        const education = res.data.relationships.education.data;

        this.form.educationId = education ? education.id : null;

        const profession = res.data.relationships.profession.data;

        this.form.professionId = profession ? profession.id : null;

        const addresses = res.data.relationships.addresses.data;

        if (addresses.length) {
          const address = this.getSingleIncluded(res, addresses[0].id);

          this.form.address.province.id = address.attributes.province_id;
          this.form.address.province.name = address.attributes.province_name;

          this.loadCities();

          this.form.address.city.id = address.attributes.city_id;
          this.form.address.city.name = address.attributes.city_name;

          this.loadDistricts();

          this.form.address.district.id = address.attributes.district_id;
          this.form.address.district.name = address.attributes.district_name;

          if (this.form.address.district.id) {
            this.loadVillages();

            this.form.address.village.id = address.attributes.village_id;
            this.form.address.village.name = address.attributes.village_name;
          }

          const area = this.getSingleIncluded(
            res,
            res.data.relationships.area.data.id
          );

          this.form.address.area.id = area.id;
          this.form.address.area.code = area.attributes.code;
        } else {
          this.form.address.province.id = null;
          this.form.address.province.name = null;
          this.form.address.city.id = null;
          this.form.address.city.name = null;
          this.form.address.district.id = null;
          this.form.address.district.name = null;
          this.form.address.village.id = null;
          this.form.address.village.name = null;
          this.form.address.area.id = null;
          this.form.address.area.code = null;
        }

        this.form.address.address = res.data.attributes.address;
        this.form.address.post = res.data.attributes.postal_code;

        const bank = res.data.relationships.bank.data;

        this.form.bankId = bank ? bank.id : null;
        this.form.bankAccountNumber = res.data.attributes.bank_account_number;
        this.form.bankBranch = res.data.attributes.bank_branch_name;
        this.form.bankAccountName = res.data.attributes.bank_account_name;

        this.form.npwpNumber = res.data.attributes.npwp_number;
        this.form.npwpName = res.data.attributes.npwp_name;
      }

      this.loadingOffice = false;
    },
    async loadEducations() {
      this.loadingEducations = true;

      const [res, err] = await this.request('/api/v1/educations', {
        params: {
          'fields[educations]': 'name',
        },
      });

      if (!err) {
        this.educations = res;
      }

      this.loadingEducations = false;
    },
    async loadProfessions() {
      this.loadingProfessions = true;

      const [res, err] = await this.request('/api/v1/professions', {
        params: {
          'fields[professions]': 'name',
        },
      });

      if (!err) {
        this.professions = res;
      }

      this.loadingProfessions = false;
    },
    async loadBanks() {
      this.loadingBanks = true;

      const [res, err] = await this.request('/api/v1/banks', {
        params: {
          'fields[banks]': 'name',
        },
      });

      if (!err) {
        this.banks = res;
      }

      this.loadingBanks = false;
    },
    async loadProvinces() {
      const [res, err] = await this.request('/api/v1/provinces');

      if (!err) {
        this.provinces = res;
      }
    },
    async loadCities() {
      const [res, err] = await this.request(
        `/api/v1/provinces/${this.form.address.province.id}/cities`,
        {
          params: {
            include: 'area',
          },
        }
      );

      if (!err) {
        this.cities = res;
      }
    },
    async loadDistricts() {
      const [res, err] = await this.request(
        `/api/v1/cities/${this.form.address.city.id}/districts`
      );

      if (!err) {
        this.districts = res;
      }
    },
    async loadVillages() {
      const [res, err] = await this.request(
        `/api/v1/districts/${this.form.address.district.id}/villages`
      );

      if (!err) {
        this.villages = res;
      }
    },
    onChangeProvince() {
      this.form.address.city.id = null;
      this.form.address.city.name = null;

      this.form.address.district.id = null;
      this.form.address.district.name = null;

      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.form.address.area.id = null;
      this.form.address.area.code = null;

      this.loadCities();
    },
    onChangeCity() {
      const city = this.cities.data.find(
        (city) => city.id === this.form.address.city.id
      );
      const area = this.getSingleIncluded(
        this.cities,
        city.relationships.area.data.id
      );

      this.form.address.area.code = area.attributes.code;
      this.form.address.area.id = area.id;

      this.form.address.district.id = null;
      this.form.address.district.name = null;

      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.loadDistricts();
    },
    onChangeDistrict() {
      this.form.address.village.id = null;
      this.form.address.village.name = null;

      this.loadVillages();
    },
    async onSave() {
      this.loadingSave = true;

      const [, err] = await this.request(`/api/v1/offices/${this.form.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'offices',
            id: this.form.id,
            attributes: {
              code: this.form.code,
              name: this.form.name,
              ktp_number: this.form.ktp,
              email: this.form.email,
              cellphone: this.form.cellphone,
              birthdate: this.form.birthdate,
              sex: this.form.sex,
              marital_status: this.form.married,
              spouse_name: this.form.spouseName,
              spouse_birthdate: this.form.spouseBirthdate,
              devisor_name: this.form.devisorName,
              devisor_birthdate: this.form.devisorBirthdate,
              address: this.form.address.address,
              postal_code: this.form.address.post,
              bank_account_number: this.form.bankAccountNumber,
              bank_branch_name: this.form.bankBranch,
              bank_account_name: this.form.bankAccountName,
              npwp_number: this.form.npwpNumber,
              npwp_name: this.form.npwpName,
              address_details: [
                {
                  province_id: this.form.address.province_id,
                  city_id: this.form.address.city_id,
                  district_id: this.form.address.district_id,
                  village_id: this.form.address.village_id,
                },
              ],
            },
            relationships: {
              ...(this.form.educationId
                ? {
                    education: {
                      data: {
                        type: 'educations',
                        id: this.form.educationId,
                      },
                    },
                  }
                : {}),
              ...(this.form.professionId
                ? {
                    profession: {
                      data: {
                        type: 'professions',
                        id: this.form.professionId,
                      },
                    },
                  }
                : {}),
              ...(this.form.bankId
                ? {
                    bank: {
                      data: {
                        type: 'banks',
                        id: this.form.bankId,
                      },
                    },
                  }
                : {}),
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.successVisible = true;
      }

      this.loadingSave = false;
    },
  },
  created() {
    this.loadOffice();
    this.loadEducations();
    this.loadProfessions();
    this.loadProvinces();
    this.loadBanks();
  },
};
</script>
