var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loadingMailLogs || _vm.loadingRetry}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-input',{attrs:{"placeholder":"Cari Email","shadow":false,"type":"search","debounce":""},on:{"native-input":_vm.loadMailLogs},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
        { key: 'all', value: null, label: 'Pilih Status' },
        { key: 'pending', value: 'pending', label: 'Menunggu Diproses' },
        { key: 'processing', value: 'processing', label: 'Sedang Diproses' },
        { key: 'failed', value: 'failed', label: 'Gagal' },
        { key: 'success', value: 'success', label: 'Berhasil' }
      ]},on:{"change":_vm.loadMailLogs},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('datatable',{attrs:{"total":_vm.mailLogs.meta.page.total,"perPage":_vm.mailLogs.meta.page.perPage,"currentPage":_vm.mailLogs.meta.page.currentPage,"columns":[
        { id: 'date', name: 'Tanggal' },
        { id: 'email', name: 'Email' },
        { id: 'status', name: 'Status' },
        { id: 'action', name: 'Aksi', theadClass: 'text-center' },
      ],"scroll-x":false},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.mailLogs.data),function(historyLog){return _c('tr',{key:historyLog.id,class:[classes.tr, 'bg-white']},[_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(historyLog.attributes.createdAt))+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(historyLog.attributes.email)+" ")]),_c('td',{class:classes.td},[_c('base-badge',{attrs:{"color":_vm.getStatusBadge(historyLog.attributes.status).color,"title":historyLog.attributes.status === 'failed'
                  ? historyLog.attributes.message
                  : ''}},[_vm._v(_vm._s(_vm.getStatusBadge(historyLog.attributes.status).text))])],1),_c('td',{class:[classes.td, 'text-center']},[(historyLog.attributes.status === 'failed')?_c('base-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.onRetry(historyLog.id)}}},[_vm._v("Kirim Ulang")]):_vm._e()],1)])})}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }