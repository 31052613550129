<template>
  <base-wrapper :loading="loading">
    <base-card :title="editing ? 'Edit User' : 'Tambah User Baru'">
      <form class="space-y-4" @submit.prevent="onSave">
        <base-input
          inset
          type="text"
          label="Nama"
          placeholder="contoh: Ujang"
          v-model="form.name"
        />
        <base-input
          inset
          type="email"
          label="Email"
          placeholder="contoh: admin@nasa.com"
          v-model="form.email"
        />
        <base-input
          inset
          type="text"
          label="No Telepon"
          placeholder="contoh: 081234567891"
          v-model="form.cellphone"
        />

        <template v-if="!editing">
          <base-input
            inset
            type="password"
            label="Password"
            placeholder="min 8 karakter"
            v-model="form.password"
          />
          <base-input
            inset
            type="password"
            label="Konfirmasi Password"
            placeholder="min 8 karakter"
            v-model="form.password_confirmation"
          />
          <base-input inset label="Kategori Kantor">
            <base-select
              inset
              expand
              expand-md
              :options="officeCategoryOptions"
              v-model="form.office_category_id"
            />
          </base-input>
          <search-office-input
            v-if="form.office_category_id"
            :office-type="officeType"
            v-model="form.office"
          />
        </template>

        <search-role-input
          v-if="editing || form.office_category_id"
          :office-type="officeType"
          v-model="form.role"
        />
        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-2">
            <template v-if="editing && withNotify">
              <base-checkbox
                label="Kirim Notifikasi Whatsapp"
                name="notify_whatsapp"
                v-model="form.notify_whatsapp"
              />
              <base-checkbox
                label="Kirim Notifikasi Email"
                name="notify_email"
                v-model="form.notify_email"
              />
            </template>
          </div>
          <div class="flex gap-2">
            <base-button type="submit">Simpan</base-button>
            <base-button
              type="button"
              color="white"
              :to="{ name: 'master.user' }"
              >Kembali</base-button
            >
          </div>
        </div>
      </form>
    </base-card>
    <base-confirm
      type="success"
      message="User Berhasil Disimpan"
      :with-cancel="false"
      confirm-text="Selesai"
      :visible="visibleSuccess"
      @confirmed="$router.push({ name: 'master.user' })"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import SearchOfficeInput from '@/components/office/search-office-input.vue';
import SearchRoleInput from '@/components/role/search-role-input.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    SearchOfficeInput,
    SearchRoleInput,
    BaseConfirm,
    BaseCheckbox,
  },
  data() {
    return {
      id: null,
      withNotify: false,
      form: {
        name: null,
        email: null,
        cellphone: null,
        password: null,
        password_confirmation: null,
        office_category_id: null,
        office: {},
        role: {
          id: null,
          name: null,
          search: null,
          originalSearch: null,
        },
        notify_whatsapp: false,
        notify_email: false,
      },
      loadingOfficeCategories: false,
      loadingSave: false,
      loadingUser: false,
      officeCategories: {
        data: [],
      },
      visibleSuccess: false,
    };
  },
  computed: {
    editing() {
      return !!this.id;
    },
    loading() {
      return (
        this.loadingOfficeCategories || this.loadingSave || this.loadingUser
      );
    },
    officeCategoryOptions() {
      return [
        {
          key: 'none',
          value: null,
          label: 'Pilh Kategori Kantor',
        },
        ...this.officeCategories.data.map((item) => ({
          key: item.id,
          value: item.id,
          label: item.attributes.name,
        })),
      ];
    },
    officeType() {
      if (this.editing) {
        return 'pusat';
      }

      const offieCategory = this.officeCategories.data.find(
        (officeCategory) => officeCategory.id === this.form.office_category_id
      );

      return offieCategory.attributes.name.replace(' ', '_').toLowerCase();
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async createUser() {
      return await this.request('/api/v1/users', {
        method: 'post',
        data: {
          data: {
            type: 'users',
            attributes: {
              name: this.form.name,
              email: this.form.email,
              cellphone: this.form.cellphone,
              password: this.form.password,
              password_confirmation: this.form.password_confirmation,
            },
            relationships: {
              office: {
                data: {
                  type: 'offices',
                  id: this.form.office.id,
                },
              },
              roles: {
                data: [
                  {
                    type: 'roles',
                    id: this.form.role.id,
                  },
                ],
              },
            },
          },
        },
      });
    },
    async updateUser() {
      return await this.request(`/api/v1/users/${this.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'users',
            id: this.id,
            attributes: {
              name: this.form.name,
              email: this.form.email,
              cellphone: this.form.cellphone,
              notify_whatsapp: this.form.notify_whatsapp,
              notify_email: this.form.notify_email,
            },
            relationships: {
              roles: {
                data: [
                  {
                    type: 'roles',
                    id: this.form.role.id,
                  },
                ],
              },
            },
          },
        },
      });
    },
    async loadOfficeCategories() {
      this.loadingOfficeCategories = true;

      const [res, error] = await this.request('/api/v1/office-categories');

      if (!error) {
        this.officeCategories = res;
      }

      this.loadingOfficeCategories = false;
    },
    async loadUser() {
      this.loadingUser = true;

      const [res, error] = await this.request(`/api/v1/users/${this.id}`, {
        params: {
          'fields[users]': 'name,email,cellphone,current-role',
          include: 'current-role',
        },
      });

      if (!error) {
        this.form.name = res.data.attributes.name;
        this.form.email = res.data.attributes.email;
        this.form.cellphone = res.data.attributes.cellphone;

        const role = this.getSingleIncluded(
          res,
          res.data.relationships['current-role'].data.id
        );

        this.form.role.id = role.id;
        this.form.role.name = role.attributes.name;
        this.form.role.search = role.attributes.name;
        this.form.role.originalSearch = role.attributes.name;

        if (this.form.role.name === 'Mitra Usaha') {
          this.withNotify = true
        }
      }

      this.loadingUser = false;
    },
    async onSave() {
      this.loadingSave = true;

      const [, err] = this.editing
        ? await this.updateUser()
        : await this.createUser();

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.visibleSuccess = true;
      }

      this.loadingSave = false;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;

      this.loadUser();
    } else {
      this.loadOfficeCategories();
    }
  },
};
</script>
