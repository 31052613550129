<template>
  <datatable>
    <template #thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Area
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Harga Stockist
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Harga Mitra Usaha
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Harga Konsumen
          </th>
        </tr>
      </thead>
    </template>
    <template #tbody>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-for="area in productPriceAreas" :key="area">
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            {{
              getProductPriceValue(
                areaNames[area],
                'Stockist',
                getProduct.data
              )?.attributes.area
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getProductPriceValue(
                areaNames[area],
                'Stockist',
                getProduct.data
              )?.attributes.price | toCurrency
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getProductPriceValue(
                areaNames[area],
                'Mitra Usaha',
                getProduct.data
              )?.attributes.price | toCurrency
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getProductPriceValue(
                areaNames[area],
                'Konsumen',
                getProduct.data
              )?.attributes.price | toCurrency
            }}
          </td>
        </tr>
      </tbody>
    </template>
  </datatable>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ProductPriceTable',
  components: {},
  computed: {
    ...mapGetters({
      getProduct: 'products/getProduct',
      getProductPrices: 'product_prices/getProductPrices',
      getUser: 'auth/getUser',
    }),
    areaNames() {
      return {
        0: 'Wilayah Jawa',
        1: 'Wilayah I',
        2: 'Wilayah II',
        3: 'Wilayah III',
        4: 'Wilayah IV',
      };
    },
    productPriceAreas() {
      if (this.getUser.current_role.toLowerCase() !== 'stockist') {
        return [0, 1, 2, 3, 4];
      }

      return [this.getUser.office_area_code];
    },
  },
  methods: {
    getProductPriceValue(areaName, buyerType) {
      let productPrice = this.getProductPrices.data.find(function (item) {
        return (
          item.id === item.id &&
          item.attributes.area === areaName &&
          item.attributes['buyer-type'] === buyerType
        );
      });
      return productPrice;
    },
  },
};
</script>
