<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end">
        <base-input
          type="search"
          placeholder="Cari Stockist"
          :shadow="false"
          v-model="filter.search"
          debounce
          @native-input="loadOffices"
        />
      </div>
      <datatable
        :columns="tableColumns"
        :total="offices.meta.page.total"
        :perPage="offices.meta.page.perPage"
        :currentPage="offices.meta.page.currentPage"
        :meta="offices.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="(office, index) in offices.data"
            :key="index"
            :class="[classes.tr, 'bg-white hover:bg-green-100']"
          >
            <td
              :class="[classes.td, 'cursor-pointer space-y-1']"
              @click="onViewOffice(getOfficeIncluded(office))"
            >
              <p class="font-bold text-gray-900">
                {{ office.attributes.code }}
              </p>
              <p class="text-xs text-gray-700">{{ office.attributes.name }}</p>
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onViewOffice(getOfficeIncluded(office))"
            >
              {{ getOfficeIncluded(office).attributes.area_code }}
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onViewOffice(getOfficeIncluded(office))"
            >
              {{ getOfficeIncluded(office).attributes.phone ?? '-' }}
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onViewOffice(getOfficeIncluded(office))"
            >
              {{ getOfficeIncluded(office).attributes.address ?? '-' }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :class="
                  getOfficeIncluded(office).attributes.is_promoted
                    ? ''
                    : 'cursor-pointer'
                "
                :color="
                  getOfficeIncluded(office).attributes.is_promoted
                    ? 'indigo'
                    : 'green'
                "
                @click="onPromote(getOfficeIncluded(office))"
              >
                {{
                  getOfficeIncluded(office).attributes.is_promoted
                    ? getOfficeIncluded(office).attributes.promoted_by
                    : 'Promosi ke SC'
                }}
              </base-badge>
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :class="
                  getOfficeIncluded(office).attributes.is_pbs_activated
                    ? ''
                    : 'cursor-pointer'
                "
                :color="
                  getOfficeIncluded(office).attributes.is_pbs_activated
                    ? 'indigo'
                    : 'green'
                "
                @click="onActivatePbs(getOfficeIncluded(office))"
              >
                {{
                  getOfficeIncluded(office).attributes.is_pbs_activated
                    ? 'Aktif'
                    : 'Aktivasi P3S'
                }}
              </base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-office-modal
      office-type="stockist"
      :visible="viewModal.visible"
      :office-id="viewModal.officeId"
      editable
      edit-route="master.stockist.edit"
      @close="viewModal.visible = false"
    />
    <promote-office-to-sc-modal
      :visible="promoteToScModal.visible"
      :office-id="promoteToScModal.officeId"
      @close="promoteToScModal.visible = false"
      @promoted="onPromoted"
    />
    <activate-office-pbs-modal
      :visible="activatePbsModal.visible"
      :office-id="activatePbsModal.officeId"
      @close="activatePbsModal.visible = false"
      @pbs-activated="onPbsActivated"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewOfficeModal from '@/components/office/view-office-modal.vue';
import PromoteOfficeToScModal from '@/components/office/promote-office-to-sc-modal.vue';
import ActivateOfficePbsModal from '@/components/office/activate-office-pbs-modal.vue';

export default {
  mixins: [requestMixin],
  components: {
    PromoteOfficeToScModal,
    ViewOfficeModal,
    ActivateOfficePbsModal,
  },
  data() {
    return {
      filter: {
        search: null,
      },
      loading: false,
      offices: {
        meta: {
          page: {},
        },
        data: [],
      },
      promoteToScModal: {
        visible: false,
        officeId: null,
      },
      viewModal: {
        visible: false,
        officeId: null,
      },
      activatePbsModal: {
        visible: false,
        officeId: null,
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'code', name: 'Kode Stockist' },
        { id: 'area_code', name: 'Wilayah Harga' },
        { id: 'phone', name: 'No. Telp' },
        { id: 'address', name: 'Alamat' },
        { id: 'promotion_status', name: 'Status', theadClass: 'text-center' },
        { id: 'pbs_status', name: 'Status P3S', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    getOfficeIncluded(office) {
      return this.getSingleIncluded(
        this.offices,
        office.relationships.office.data.id
      );
    },
    async loadOffices(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'filter[office_category_id]': 4,
          'filter[search]': this.filter.search,
          'page[limit]': 5,
          include: 'office',
          'fields[simple-offices]': 'code,name,office',
          'fields[offices]':
            'area_code,phone,address,is_promoted,promoted_by,is_pbs_activated',
          ...params,
        },
      });

      if (!err) {
        this.offices = res;
      }

      this.loading = false;
    },
    onActivatePbs(office) {
      if (!office.attributes.is_pbs_activated) {
        this.activatePbsModal.officeId = office.id;
        this.activatePbsModal.visible = true;
      }
    },
    onPageChanged(page) {
      this.loadOffices({ ...page });
    },
    onPromote(office) {
      if (!office.attributes.is_promoted) {
        this.promoteToScModal.officeId = office.id;
        this.promoteToScModal.visible = true;
      }
    },
    onPromoted() {
      this.promoteToScModal.visible = false;

      this.loadOffices();
    },
    onPbsActivated() {
      this.activatePbsModal.visible = false;

      this.loadOffices();
    },
    onViewOffice(office) {
      this.viewModal.officeId = office.id;
      this.viewModal.visible = true;
    },
  },
  created() {
    this.loadOffices();
  },
};
</script>
