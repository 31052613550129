<template>
  <base-input label="Limit Order barang">
    <div class="space-y-4">
      <div class="space-y-2">
        <base-radio
          :val="true"
          v-model="productLimit.unlimited"
          label="Unlimited"
          @change="onChangeUnlimited"
        />
        <base-radio
          :val="false"
          v-model="productLimit.unlimited"
          @change="onChangeLimited"
        >
          <input
            type="text"
            placeholder="Masukkan Batas"
            class="focus:outline-none border-0 p-0 pl-1 text-sm placeholder-gray-300 focus:ring-0"
            v-model="productLimit.limit"
          />
        </base-radio>
      </div>

      <template v-if="!productLimit.unlimited">
        <base-checkbox
          label="Periode Manual"
          label-wrapper-class="!ml-2"
          v-model="productLimit.limitPeriodDate"
          @change="onChangeLimitPeriodDate"
        />
        <div v-if="productLimit.limitPeriodDate" class="grid grid-cols-2 gap-4">
          <base-input
            inset
            type="date"
            label="Awal"
            v-model="productLimit.limitPeriodStartAt"
          />
          <base-input
            inset
            type="date"
            label="Akhir"
            v-model="productLimit.limitPeriodEndAt"
          />
        </div>
        <base-input
          inset
          label="Limit Periodik"
          :disabled="productLimit.limitPeriodDate"
        >
          <base-select
            inset
            expand
            :disabled="productLimit.limitPeriodDate"
            :options="[
              {
                key: 'null',
                value: null,
                label: 'Pilih Periode',
              },
              {
                key: 'daily',
                value: 'daily',
                label: 'Harian',
              },
              {
                key: 'weekly',
                value: 'weekly',
                label: 'Mingguan',
              },
              {
                key: 'monthly',
                value: 'monthly',
                label: 'Bulanan',
              },
            ]"
            v-model="productLimit.limitPeriod"
          />
        </base-input>
      </template>
    </div>
  </base-input>
</template>

<script>
import BaseRadio from '@/components/base/BaseRadio.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';

export default {
  components: {
    BaseRadio,
    BaseCheckbox,
  },
  props: {
    value: Object,
  },
  emits: ['input'],
  computed: {
    productLimit: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChangeLimitPeriodDate() {
      if (this.productLimit.limitPeriodDate) {
        this.productLimit.limitPeriod = null;
        this.productLimit.limitPeriodStartAt = null;
        this.productLimit.limitPeriodEndAt = null;
      }
    },
    onChangeUnlimited() {
      if (this.productLimit.unlimited) {
        this.reset();
      }
    },
    onChangeLimited() {
      if (!this.productLimit.unlimited) {
        this.reset();
      }
    },
    reset() {
      this.productLimit.limit = null;
      this.productLimit.limitPeriodDate = false;
      this.productLimit.limitPeriod = null;
      this.productLimit.limitPeriodStartAt = null;
      this.productLimit.limitPeriodEndAt = null;
    },
  },
};
</script>
