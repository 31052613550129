<template>
  <div
    :class="style"
  >
    <div
      v-if="title || description"
      class="flex items-center justify-between pb-5"
    >
      <div class="space-y-2">
        <h3 v-if="title" class="text-lg font-bold leading-6 text-gray-900">
          {{ title }}
        </h3>
        <p v-if="description" class="max-w-4xl text-sm text-gray-500">
          {{ description }}
        </p>
      </div>
      <slot name="action"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    withStyle: {
      default: true,
    },
    padless: Boolean,
    smOnly: Boolean,
    smNone: Boolean,
    mdNone: Boolean
  },
  computed: {
    style() {
      if (!this.withStyle) {
        return null
      }

      if (this.smOnly) {
        const padding = this.padless ? '' : 'sm:p-6'

        return ['sm:rounded-lg sm:bg-white sm:shadow', padding]
      }

      if (this.smNone) {
        const padding = this.padless ? '' : 'px-4 py-5 sm:p-0'

        return ['rounded-lg bg-white shadow sm:rounded-none sm:bg-transparent sm:shadow-none', padding]
      }

      if (this.mdNone) {
        const padding = this.padless ? '' : 'px-4 py-5 md:p-0'

        return ['rounded-lg bg-white shadow md:rounded-none md:bg-transparent md:shadow-none', padding]
      }

      const padding = this.padless ? '' : 'px-4 py-5 sm:p-6'

      return ['rounded-lg bg-white shadow', padding]
    }
  }
};
</script>
