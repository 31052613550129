var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-select',{attrs:{"shadow":false,"expand":false,"options":[
          { key: 'none', value: null, label: 'Pilih Kantor Tujuan' },
          { key: 'pusat', value: 1, label: 'Pusat' },
          { key: 'kantor_pelayanan', value: 2, label: 'Kantor Pelayanan' },
          { key: 'stockist_center', value: 3, label: 'Stockist Center' },
        ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.origin_office_category_id),callback:function ($$v) {_vm.$set(_vm.filter, "origin_office_category_id", $$v)},expression:"filter.origin_office_category_id"}}),_c('base-select',{attrs:{"shadow":false,"expand":false,"options":[
          { key: 'none', value: null, label: 'Status' },
          {
            key: 'completed',
            value: { 'filter[order_status]': 'Selesai' },
            label: 'Selesai',
          },
          {
            key: 'not_completed',
            value: { 'filter[not-order_status]': 'Selesai' },
            label: 'Belum Selesai',
          },
        ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('base-button',{attrs:{"to":{ name: 'pembelian.tambah' }}},[_vm._v("Tambah Pembelian")])],1),_c('datatable',{attrs:{"scroll-x":false,"columns":_vm.columns,"is-empty":!_vm.orders.data.length,"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"cursor":""},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [_c('tbody',{class:classes.tbody},_vm._l((_vm.orders.data),function(order){return _c('tr',{key:order.id,class:[classes.tr, 'cursor-pointer hover:bg-green-100'],on:{"click":function($event){return _vm.onClickDetail(order)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(order.attributes.origin_code)+" ")]),_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.attributes.updatedAt))+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.origin_office_code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.final_price))+" ")]),_c('td',{class:[
                classes.td,
                'text-right',
                _vm.getPaymentRemainder(order) < 0
                  ? 'text-red-500'
                  : 'text-green-500',
              ]},[_c('p',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getPaymentRemainder(order)))+" ")])]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.total_point_value))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.total_bonus_value))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
                    .attributes.has_bundle
                    ? 'green'
                    : 'yellow'}},[_vm._v(_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.has_bundle ? 'Sudah Dilaporkan' : 'Belum Dilaporkan'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":order.attributes.order_status === 'Draft'
                    ? 'yellow'
                    : 'green'}},[_vm._v(_vm._s(order.attributes.order_status))])],1)])}),0)]}}])})],1),_c('view-order-modal',{attrs:{"label":"Pembelian","visible":_vm.detailModal.visible,"order-id":_vm.detailModal.id,"edit-route-name":"pembelian.edit"},on:{"close":function($event){_vm.detailModal.visible = false},"deleted":_vm.onDeleted}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }