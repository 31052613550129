var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex flex-wrap justify-end gap-2"},[_c('base-button',{staticClass:"md:hidden",attrs:{"color":"white"},on:{"click":function($event){_vm.visibleFilter = !_vm.visibleFilter}}},[_vm._v("Filter")]),_c('base-button',{attrs:{"to":{ name: 'restock-pbs.tambah' }}},[_vm._v("Tambah Restock P3S")]),_c('div',{class:[
        'w-full flex-col gap-2 md:flex md:w-auto md:flex-row md:order-first',
        _vm.visibleFilter ? 'flex' : 'hidden'
      ]},[_c('base-input',{attrs:{"type":"search","shadow":false,"placeholder":"Cari Kode","debounce":"","fullwidth":""},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
            { key: 'all', label: 'Status', value: null },
            { key: 'draft', label: 'Draft', value: false },
            { key: 'valid', label: 'Valid', value: true },
          ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.is_valid_for_payment),callback:function ($$v) {_vm.$set(_vm.filter, "is_valid_for_payment", $$v)},expression:"filter.is_valid_for_payment"}})],1)],1),_c('datatable',{attrs:{"columns":_vm.tableColumns,"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"cursor":""},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order,index){return _c('tr',{key:index,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){_vm.onView(
              _vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
            )}}},[_c('td',{class:classes.td},[_c('p',[_c('span',{staticClass:"font-bold text-gray-900"},[_vm._v(_vm._s(order.attributes.origin_code))]),_vm._v(" / "+_vm._s(order.attributes.destination_code)+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.attributes.updatedAt))+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.origin_office_code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.grand_total_price))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.payment_amount))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
                  .attributes.is_valid_for_payment
                  ? 'indigo'
                  : 'yellow'}},[_vm._v(_vm._s(order.attributes.order_status))])],1)])})}}])})],1),_c('view-order-modal',{attrs:{"visible":_vm.viewModal.visible,"order-id":_vm.viewModal.id,"columns":[
      'code',
      'warehouse',
      'office',
      'date',
      'user',
      'area',
      'shipment_type',
      'address',
    ]},on:{"close":function($event){_vm.viewModal.visible = false},"validated":_vm.onValidated,"deleted":_vm.onDeleted}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }