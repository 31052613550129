<template>
  <base-wrapper :loading="loadingHistories || loadingBonus">
    <div class="space-y-4">
      <base-card title="Ambil Data Bonus">
        <base-button :loading="loadingSync.visible" @click="onSync">{{
          loadingSync.visible ? loadingSync.text : 'Ambil Data Bonus'
        }}</base-button>
      </base-card>
      <base-card title="Riwayat Ambil Data Bonus">
        <datatable
          :columns="historyColumns"
          :scroll-x="false"
          :total="histories.meta.page.total"
          :perPage="histories.meta.page.perPage"
          :currentPage="histories.meta.page.currentPage"
          :meta="histories.meta"
          @pagechanged="onHistoryPageChange"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="history in histories.data"
              :key="history.id"
              :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
              <td :class="[classes.td]">
                {{ history.attributes.createdAt | formatDate }}
              </td>
              <td :class="[classes.td]">
                {{ history.attributes.user }}
              </td>
              <td :class="[classes.td]">
                {{ history.attributes.finishedAt | formatDate }}
              </td>
              <td :class="[classes.td]">
                <base-badge
                  :color="getStatusColor(history.attributes.status)"
                  >{{ getStatusText(history.attributes.status) }}</base-badge
                >
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
      <base-card title="Data Bonus">
        <template #action>
          <div class="flex items-center gap-2">
            <base-input
              type="search"
              :shadow="false"
              placeholder="Cari Kode Kantor"
              v-model="filterBonus.office"
              debounce
              @native-input="loadBonus"
            />
            <base-input
              type="search"
              :shadow="false"
              placeholder="Cari Kode TMPBNS"
              v-model="filterBonus.bonus_office"
              debounce
              @native-input="loadBonus"
            />
            <base-select
              :shadow="false"
              :expand="false"
              :options="[
                { key: 'all', value: null, label: 'Pilih Keterangan' },
                { key: 'stockist', value: 'stockist', label: 'Stockist' },
                { key: 'transfer', value: 'transfer', label: 'Transfer' },
                { key: 'pending', value: 'pending', label: 'Pending' },
                { key: 'empty', value: '', label: 'Kosong' },
              ]"
              v-model="filterBonus.keterangan"
              @change="loadBonus"
            />
            <base-select
              :shadow="false"
              :expand="false"
              :options="[
                { key: 'all', value: null, label: 'Status' },
                { key: 'belum_diambil', value: 'not-taken', label: 'Belum Diambil' },
                { key: 'diambil', value: 'taken', label: 'Diambil' },
                { key: 'belum_diterima', value: 'not-retrieved', label: 'Belum Diterima' },
                { key: 'diterima', value: 'retrieved', label: 'Diterima' },
              ]"
              v-model="filterBonus.status"
              @change="loadBonus"
            />
            <mitra-period-select
              prev-period
              v-model="filterBonus.mitra_period_id"
              @change="loadBonus"
            />
          </div>
        </template>

        <datatable
          :columns="bonusColumns"
          :scroll-x="false"
          :total="bonus.meta.page.total"
          :perPage="bonus.meta.page.perPage"
          :currentPage="bonus.meta.page.currentPage"
          :meta="bonus.meta"
          :sort="sortBonus"
          @pagechanged="onBonusPageChange"
          @sortchanged="onBonusSortChange"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="bonusItem in bonus.data"
              :key="bonusItem.id"
              :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
              <td :class="[classes.td]">
                <strong class="text-gray-900">{{
                  bonusItem.attributes.code
                }}</strong>
              </td>
              <td :class="[classes.td]">
                {{ bonusItem.attributes.temp_code }}
              </td>
              <td :class="[classes.td]">
                {{ bonusItem.attributes.name }}
              </td>
              <td :class="[classes.td]">
                {{ bonusItem.attributes.bonus_office }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ bonusItem.attributes.rabat | toCurrency }}
              </td>
              <td :class="[classes.td]">
                {{ bonusItem.attributes.keterangan || '-' }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="bonusItem.attributes.taken_at ? 'green' : 'yellow'"
                  >{{
                    bonusItem.attributes.taken_at ? 'Diambil' : 'Belum Diambil'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    bonusItem.attributes.retrieved_at ? 'green' : 'yellow'
                  "
                  >{{
                    bonusItem.attributes.retrieved_at
                      ? 'Diterima'
                      : 'Belum Diterima'
                  }}</base-badge
                >
              </td>
            </tr>
            <tr v-if="bonus.data.length" :class="classes.tr">
              <td :class="[classes.td, 'text-right']" colspan="5">
                {{ bonus.meta.total | toCurrency }}
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions, mapGetters } from 'vuex';
import MitraPeriodSelect from '@/components/period/mitra-period/mitra-period-select.vue';

export default {
  mixins: [requestMixin],
  components: { MitraPeriodSelect },
  data() {
    return {
      channelName: null,
      bonus: {
        data: [],
        meta: {
          page: {},
          total_per_page: 0,
          total: 0,
        },
      },
      filterBonus: {
        mitra_period_id: null,
        office: null,
        bonus_office: null,
        keterangan: null,
        status: null
      },
      sortBonus: [],
      histories: {
        data: [],
        meta: {
          running: false,
          page: {},
        },
      },
      loadingHistories: false,
      loadingBonus: false,
      loadingSync: {
        visible: false,
        text: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    historyColumns() {
      return [
        { id: 'date', name: 'Tanggal' },
        { id: 'user', name: 'User' },
        { id: 'finished_at', name: 'Selesai' },
        { id: 'status', name: 'Status' },
      ];
    },
    bonusColumns() {
      return [
        { id: 'code', name: 'Kode' },
        { id: 'temp_code', name: 'Kode Sementara' },
        { id: 'name', name: 'Nama' },
        { id: 'bonus_office', name: 'TMPBNS' },
        { id: 'rabat', name: 'Rabat', theadClass: 'text-right', sortable: true, sortClass: 'justify-end' },
        { id: 'description', name: 'Keterangan' },
        {
          id: 'taken_status',
          name: 'Status Diambil',
          theadClass: 'text-center',
        },
        {
          id: 'retrieved_status',
          name: 'Status Diterima',
          theadClass: 'text-center',
        },
      ];
    },
    sortBonusParams() {
      return this.sortBonus.map(sortColumn => `${sortColumn.direction === 'desc' ? '-' : ''}${sortColumn.id}`)
        .join(',')
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addSyncListener() {
      this.channelName = `private-App.Models.User.${this.me.id}`

      const userChannel = this.$pusher.subscribe(this.channelName);

      userChannel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (e) => {
          if (
            e.type === 'App\\Notifications\\SyncMemberBonusDataStatusUpdated'
          ) {
            if (e.status === 'success') {
              this.stopLoadingSync();

              this.createAlert({
                data: 'Berhasil memproses data bonus',
                status: 'success',
              });

              this.loadHistories();

              this.filterBonus.mitra_period_id = this.me.prev_period.id;
              this.filterBonus.bonus_office = null;
              this.filterBonus.keterangan = null;
              this.filterBonus.code = null;
              this.filterBonus.status = null

              this.loadBonus();
            }
          }
        }
      );
    },
    getStatusText(status) {
      return {
        created: 'Sedang Diproses',
        failed: 'Gagal',
        success: 'Selesai',
      }[status];
    },
    getStatusColor(status) {
      return {
        created: 'yellow',
        failed: 'red',
        success: 'green',
      }[status];
    },
    async loadHistories(params = {}) {
      this.loadingHistories = true;

      const [res, err] = await this.request('/api/v1/member-bonus-histories', {
        params: {
          'fields[member-bonus-histories]': 'createdAt,user,finishedAt,status',
          'page[size]': 10,
          sort: '-createdAt',
          ...params,
        },
      });

      if (err) {
        this.createAlert({
          data: 'Gagal mengambil riwayat ambil data bonus',
          status: 'error',
        });
      } else {
        this.histories = res;
      }

      this.loadingHistories = false;
    },
    async loadBonus(params = {}) {
      this.loadingBonus = true;

      const [res, err] = await this.request('/api/v1/bonus', {
        params: {
          'fields[bonus]':
            'code,temp_code,name,taken_at,retrieved_at,rabat,bonus_office,keterangan',
          'page[size]': 5,
          'filter[mitra_period_id]': this.filterBonus.mitra_period_id,
          'filter[bonus_office]': this.filterBonus.bonus_office || null,
          'filter[code]': this.filterBonus.office || null,
          'filter[keterangan]': this.filterBonus.keterangan,
          'filter[status]': this.filterBonus.status,
          'sort': this.sortBonusParams,
          ...params,
        },
      });

      if (err) {
        this.createAlert({
          data: 'Gagal mengambil riwayat data bonus',
          status: 'error',
        });
      } else {
        this.bonus = res;
      }

      this.loadingBonus = false;
    },
    onHistoryPageChange(page) {
      this.loadHistories({
        'page[number]': page,
      });
    },
    onBonusPageChange(page) {
      this.loadBonus({
        'page[number]': page,
      });
    },
    onBonusSortChange(val) {
      this.sortBonus = [...val]
      this.loadBonus()
    },
    async onSync() {
      this.startLoadingSync('Menunggu Diproses');

      const [, err] = await this.request(
        '/api/v1/offices/-actions/sync-member-bonus-data',
        {
          method: 'post',
        }
      );

      if (err) {
        this.createAlert({
          data: 'Gagal mengambil data bonus',
          status: 'error',
        });

        this.stopLoadingSync();
      } else {
        this.loadingSync.text = 'Sedang Diproses';
      }
    },
    removeSyncListener() {
      this.$pusher.unsubscribe(this.channelName);
    },
    startLoadingSync(text) {
      this.loadingSync.text = text;
      this.loadingSync.visible = true;
    },
    stopLoadingSync() {
      this.loadingSync.visible = false;
      this.loadingSync.text = null;
    },
  },
  async created() {
    this.filterBonus.mitra_period_id = this.me.prev_period.id;

    this.loadHistories().then(() => {
      if (this.histories.meta.running) {
        this.startLoadingSync('Sedang diproses');
      }
    });
    this.loadBonus();
    this.addSyncListener();
  },
  beforeRouteLeave(to, from, next) {
    this.removeSyncListener();

    next();
  },
};
</script>
