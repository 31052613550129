<template>
  <div>
    <Modal
      v-if="getOffice.data?.attributes"
      :showing="modelValue"
      @close="modelValue = false"
      :showClose="modelValue"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail {{ officeLabel }}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data {{ officeLabel }} yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">
              Nama {{ officeLabel }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.name ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Kode {{ officeLabel }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.code ?? '-' }}

              {{
                getOffice.data.attributes?.promoted_by
                  ? `(${getOffice.data.attributes?.promoted_by})`
                  : null
              }}
            </dd>
          </div>
          <div v-if="!readOnly" class="sm:col-span-1">
            <dd class="mt-1 text-sm text-gray-900">
              <toggle-button
                v-model="getOffice.data.attributes.is_suspended"
                :labels="{
                  checked: 'Aktif',
                  unchecked: 'Non Aktif',
                }"
                :width="getOffice.data.attributes?.is_suspended ? 60 : 80"
                v-on:change="onActiveChange"
              />
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.phone ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Email</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.email ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Provinsi</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.included?.find((it) => it.type == 'addresses')
                  ?.attributes?.province_name ?? '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Kabupaten</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.included?.find((it) => it.type == 'addresses')
                  ?.attributes?.city_name ?? '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Kecamatan</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.included?.find((it) => it.type == 'addresses')
                  ?.attributes?.district_name ?? '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Desa</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.included?.find((it) => it.type == 'addresses')
                  ?.attributes?.village_name ?? '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">
              Alamat {{ officeLabel }} 1
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.address ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">
              Alamat {{ officeLabel }} 2
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.address2 ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.included?.find((it) => it.type == 'areas')?.attributes
                  .name ?? '-'
              }}
              ({{
                getOffice.included?.find((it) => it.type == 'areas')?.attributes
                  .code ?? '-'
              }})
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.createdAt | formatDate }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Terakhir Diperbarui</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data.attributes?.updatedAt | formatDate }}
            </dd>
          </div>
        </dl>
      </div>
      <section v-if="viewAs !== 'supplier'" class="px-6">
        <h3 class="mb-4 text-lg font-bold leading-6 text-gray-900">
          Daftar User di Kantor Ini
        </h3>
        <Datatable
          :total="getUsers.meta.page.total"
          :perPage="getUsers.meta.page.perPage"
          :currentPage="getUsers.meta.page.currentPage"
          :paginated="false"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Nama
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Role
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="getUsers.data.length > 0">
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in getUsers.data"
                :key="data.id"
                @click="onOpenUserDetail(data)"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes?.name }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes?.email }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes?.current_role }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  v-if="!readOnly"
                >
                  <toggle-button
                    :value="!data.attributes?.is_suspended"
                    :labels="{
                      checked: 'Aktif',
                      unchecked: 'Non Aktif',
                    }"
                    :width="data.attributes?.is_suspended ? 60 : 80"
                    v-on:change="onActiveChange(data)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="4"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </section>
      <slot></slot>
      <div class="mx-8 mt-4 flex justify-between">
        <base-button
          v-if="!readOnly"
          color="white"
          @click="() => this.$router.push(`${viewAs}/${getOffice.data.id}`)"
        >
          <Icon class="h-5 w-5" icon="heroicons:pencil-square" />
          Edit
        </base-button>
      </div>
    </Modal>
    <user-detail-modal
      :visible="detailUserModal.visible"
      :reload="detailUserModal.reload"
      :user-id="detailUserModal.userId"
      @close="onCloseUserDetail"
      @edit-password="onEditUserPassword"
    />
    <edit-user-password-modal
      :visible="editPasswordUserModal.visible"
      :user-id="editPasswordUserModal.userId"
      @close="onCloseEditUserPassword"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/base/BaseModal';
import BaseButton from '@/components/base/BaseButton.vue';
import Datatable from '@/components/base/Datatable';
import { ToggleButton } from 'vue-js-toggle-button';
import UserDetailModal from '@/components/user/user-detail-modal.vue';
import EditUserPasswordModal from '@/components/user/edit-user-password-modal.vue';

export default {
  name: 'OfficeDetailModal',
  components: {
    Modal,
    BaseButton,
    Datatable,
    ToggleButton,
    UserDetailModal,
    EditUserPasswordModal
  },
  data() {
    return {
      openDetail: false,
      detailUserModal: {
        visible: false,
        reload: true,
        userId: null,
      },
      editPasswordUserModal: {
        visible: false,
        userId: null,
      },
    };
  },
  props: {
    value: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
    viewAs: {
      type: String,
      default: 'stockist',
    },
  },
  methods: {
    ...mapActions({
      updateOffice: 'offices/updateOffice',
      fetchUser: 'users/fetchUser',
    }),
    onOpenUserDetail(user) {
      this.detailUserModal.userId = user.id;
      this.detailUserModal.reload = true;
      this.detailUserModal.visible = true;
    },
    onCloseUserDetail() {
      this.detailUserModal.visible = false;
    },
    onEditUserPassword() {
      this.detailUserModal.visible = false;

      this.editPasswordUserModal.userId = this.detailUserModal.userId;
      this.editPasswordUserModal.visible = true;
    },
    onCloseEditUserPassword() {
      this.editPasswordUserModal.visible = false;

      this.detailUserModal.reload = false;
      this.detailUserModal.visible = true;
    },
    async onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.getOffice.data.id,
          attributes: {
            is_suspended: !this.getOffice.data.attributes?.is_suspended,
          },
        },
      };
      await this.updateOffice(payload);
      this.$emit('refetch');
    },
  },

  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
      getOffice: 'offices/getOffice',
      getUsers: 'users/getUsers',
    }),
    officeLabel() {
      const map = {
        stockist: 'Stockist',
        'stockist-center': 'Stockist Center',
        member: 'Mitra Usaha',
        'kantor-pelayanan': 'Kantor Pelayanan',
        supplier: 'Supplier',
      };
      return map[this.viewAs] ?? map['stockist'];
    },
    modelValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('update', value);
      },
    },
  },
};
</script>
