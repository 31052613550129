var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-wrapper',{staticClass:"space-y-6"},[_c('create-order-form',{attrs:{"valid":_vm.order.valid,"with-origin-office-type":!_vm.order.id,"with-origin-office":"","use-origin-office-warehouse":"","with-destination-office":false,"origin-office-type-options":[
        { key: 'pusat', value: 'pusat', label: 'Pusat' },
        {
          key: 'kantor_pelayanan',
          value: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
        },
      ]},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.canCreateOrderDetail)?_c('create-order-detail-form',{attrs:{"disabled":_vm.order.valid,"order-id":_vm.order.id,"origin-office":{
        id: _vm.order.originOffice.id,
        code: _vm.order.originOffice.code,
      },"destination-office":{
        id: _vm.order.destinationOffice.id,
        code: _vm.order.destinationOffice.code,
      },"origin-warehouse":{
        id: _vm.order.originOffice.currentWarehouse.id,
        code: _vm.order.originOffice.currentWarehouse.code,
      },"buyer-type":{
        id: _vm.order.buyerType.id,
        code: _vm.order.buyerType.code,
      },"area":{
        id: _vm.order.area.id,
        code: _vm.order.area.code,
      },"create-order-attributes":{
        is_loan: _vm.order.loan,
        is_using_stock: _vm.order.using_stock,
        destination_warehouse_id: _vm.order.destinationOffice.currentWarehouse.id,
        order_type: 'purchase',
        order_shipment: _vm.order.shipment_type,
        ...(_vm.order.custom_shipment_address
          ? {
              destination_address: {
                province_id: _vm.order.shipmentAddress.province.id,
                city_id: _vm.order.shipmentAddress.city.id,
                district_id: _vm.order.shipmentAddress.district.id,
                village_id: _vm.order.shipmentAddress.village.id,
                address: _vm.order.shipmentAddress.address,
              },
            }
          : null),
      },"min-spend":_vm.minOrderSpend},on:{"order-created":_vm.onOrderCreated},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}):_vm._e(),(_vm.canCreatePayment)?_c('create-balance-payment-form',{attrs:{"disabled":_vm.order.valid,"payment-id":_vm.payment.id},model:{value:(_vm.balanceUsed),callback:function ($$v) {_vm.balanceUsed=$$v},expression:"balanceUsed"}}):_vm._e(),_c('div',{staticClass:"flex justify-end gap-x-2"},[(!_vm.order.valid)?_c('base-button',{attrs:{"disabled":!_vm.canValidate},on:{"click":_vm.onValidate}},[_vm._v("Validasi")]):_vm._e(),_c('base-button',{attrs:{"to":{ name: 'restock' },"color":"white"}},[_vm._v("Kembali")])],1)],1),_c('view-order-modal',{attrs:{"visible":_vm.viewOrderModalVisible,"order-id":_vm.order.id},on:{"validated":function($event){return _vm.$router.push({ name: 'restock' })},"close":function($event){return _vm.$router.push({ name: 'restock' })}}}),_c('validate-order-confirmation',{attrs:{"order-id":_vm.order.id,"visible":_vm.confirmValidateOrderVisible},on:{"close":function($event){_vm.confirmValidateOrderVisible = false},"confirmed":_vm.onValidateConfirmed}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }