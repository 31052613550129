<template>
  <base-wrapper :loading="loadingStockistCenter">
    <div class="space-y-4">
      <div class="flex justify-end">
        <base-input
          type="search"
          placeholder="Cari Stockist Center"
          debounce
          :shadow="false"
          v-model="filter.search"
          @native-input="loadStockistCenter"
        />
      </div>
      <datatable
        :total="stockistCenters.meta.page.total"
        :perPage="stockistCenters.meta.page.perPage"
        :currentPage="stockistCenters.meta.page.currentPage"
        :meta="stockistCenters.meta"
        :columns="[
          { id: 'code', name: 'Kode Stockist Center' },
          { id: 'name', name: 'Nama Stockist Center' },
          { id: 'area', name: 'Area' },
          { id: 'phone', name: 'Alamat / No. Telp' },
        ]"
        cursor
        @pagechanged="onPageChange"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="stockistCenter in stockistCenters.data"
            :key="stockistCenter.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(stockistCenter)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ stockistCenter.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">
              <p class="text-gray-900 mb-1">{{ stockistCenter.attributes.name }}</p>
              <p class="text-xs">{{ getOfficeIncluded(stockistCenter).attributes.email ?? '' }}</p>
            </td>
            <td :class="classes.td">{{ getOfficeIncluded(stockistCenter).attributes.area_name ?? '-' }}</td>
            <td :class="classes.td">
              <div>
                <p>
                  {{ getOfficeIncluded(stockistCenter).attributes.address ?? '-' }}
                </p>
                <p class="text-xs">
                  {{ getOfficeIncluded(stockistCenter).attributes.phone ?? '' }}
                </p>
              </div>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-office-modal
      office-type="stockist_center"
      :loading="detailModal.loading"
      :visible="detailModal.visible"
      :office-id="detailModal.officeId"
      @close="detailModal.visible = false"
    />
  </base-wrapper>
</template>

<script>
import ViewOfficeModal from '@/components/office/view-office-modal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'Home',
  mixins: [requestMixin],
  components: {
    ViewOfficeModal,
  },
  data() {
    return {
      detailModal: {
        officeId: null,
        visible: false,
        loading: false
      },
      filter: {
        search: null,
      },
      loadingStockistCenter: false,
      stockistCenters: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  created() {
    this.loadStockistCenter();
  },
  methods: {
    async onClickDetail(simpleOffice) {
      this.detailModal.officeId = simpleOffice.relationships.office.data.id;
      this.detailModal.visible = true;
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.stockistCenters,
        simpleOffice.relationships.office.data.id
      );
    },
    onPageChange(page) {
      this.loadStockistCenter(page);
    },
    async loadStockistCenter(params) {
      this.loadingStockistCenter = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'page[limit]': 5,
          'filter[office_category_id]': 3,
          include: 'office',
          'fields[simple-offices]': 'code,name,office',
          'fields[offices]': 'address,phone,email,area_name',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.stockistCenters = res;
      }

      this.loadingStockistCenter = false;
    },
  },
};
</script>
