<template>
  <div>
    <base-confirm
      v-if="visible"
      @close="$emit('close')"
      @confirmed="onConfirmed"
      size="max-w-xl"
      message="Faktur akan divalidasi ke gudang. Apakah Anda yakin data yang dibuat sudah benar?"
      type="success"
      visible
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  components: { BaseConfirm },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    orderId: String,
    paymentId: String,
    payments: Array
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onConfirmed() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/orders/${this.orderId}/-actions/validateOrder`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {

      if (this.paymentId && this.payments && this.payments.length) {
        await this.storePayment()
      }
        this.$emit('confirmed');
      }

      this.loading = false;
    },
    async storePayment() {
      await Promise.all(this.payments.map(payment => this.request(`/api/v1/payment-methods`, {
        method: 'post',
        data: {
          data: {
            type: 'payment-methods',
            attributes: {
              payment_amount: Number(payment.amount.replace(/\D/g, '')),
              receipt_number: payment.receiptNumber
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.orderId
                }
              },
              payment: {
                data: {
                  type: 'payments',
                  id: this.paymentId
                }
              },
              ...(payment.officeBankId
                ? {
                  'office-bank': {
                    data: {
                      type: 'office-banks',
                      id: payment.officeBankId
                    }
                  }
                }
                : {}
              ),
              'payment-method-type': {
                data: {
                  type: 'payment-method-types',
                  id: payment.paymentMethodTypeId
                }
              }
            }
          }
        }
      })))
    }
  },
};
</script>
