<template>
  <base-wrapper>
    <div class="space-y-6">
      <create-order-form
        :with-restock-type="false"
        with-destination-area
        :with-loan="false"
        :valid="order.valid"
        use-origin-office-warehouse
        v-model="order"
      />

      <template v-if="order.destinationOffice.id">
        <cashback-order-provider
          class="space-y-6"
          :office-type="order.originOfficeType"
          :total-price="totalPrice"
          :area-code="order.area.code"
          v-model="cashback"
        >
          <create-order-detail-form
            :cashback="cashback"
            :origin-office="order.originOffice"
            :origin-warehouse="order.originOffice.currentWarehouse"
            :destination-office="order.destinationOffice"
            :buyer-type="order.buyerType"
            :area="order.area"
            :order-id="order.id"
            with-cashback
            :create-order-attributes="{
              is_using_stock: true,
              order_shipment: order.shipment_type,
              order_type: 'sale',
              destination_warehouse_id:
                order.destinationOffice.currentWarehouse.id,
              ...(order.custom_shipment_address
                ? {
                    destination_address: {
                      province_id: order.shipmentAddress.province.id,
                      city_id: order.shipmentAddress.city.id,
                      district_id: order.shipmentAddress.district.id,
                      village_id: order.shipmentAddress.village.id,
                      address: order.shipmentAddress.address,
                    },
                  }
                : {}),
            }"
            :disabled="order.valid"
            :min-spend="minOrderSpend"
            v-model="orderDetails"
            @order-created="onOrderCreated"
          />
          <create-payment-form
            v-if="canAddPayment"
            :office-id="order.originOffice.id"
            :order-id="order.id"
            :payment-id="paymentId"
            :min-amount="totalPrice"
            :paid="cashback"
            :with-validate-button="false"
            auto-save
            @load-payment-methods-finished="loadingPaymentMethods = false"
          />
        </cashback-order-provider>
      </template>

      <div class="flex justify-end gap-2">
        <base-button
          v-if="!order.valid"
          :disabled="!canValidate"
          @click="onValidate"
          >Siap Validasi</base-button
        >
        <base-button color="white" :to="{ name: 'penjualan' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <validate-order-confirmation
      :visible="validateConfirmationVisible"
      :order-id="order.id"
      @close="validateConfirmationVisible = false"
      @confirmed="onConfirmValidate"
    />
    <view-order-modal
      :visible="viewModalVisible"
      :order-id="order.id"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      @close="$router.push({ name: 'penjualan' })"
      @validated="$router.push({ name: 'penjualan' })"
      @deleted="$router.push({ name: 'penjualan' })"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import CashbackOrderProvider from '@/components/order/cashback-order-provider.vue';
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import { mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';

export default {
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    ValidateOrderConfirmation,
    ViewOrderModal,
    CreatePaymentForm,
    CashbackOrderProvider,
  },
  mixins: [requestMixin],
  data() {
    return {
      loadingBuyerType: false,
      loadingOriginOffice: false,
      loadingOrder: false,
      loadingSetup: false,
      loadingPaymentMethods: false,
      cashback: null,
      minOrderSpend: 0,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        custom_shipment_address: false,
        date: new Date(),
        destinationOffice: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,

          address: null,

          currentWarehouse: {
            id: null,
            code: null,
          },

          area: {
            id: null,
            code: null,
          },
        },
        destinationOfficeType: 'stockist',
        originOfficeType: 'kantor_pelayanan',
        originOffice: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          area: {
            id: null,
            code: null,
          },
        },
        shipment_type: 'pickup',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        },
      ],
      paymentId: null,
      validateConfirmationVisible: false,
      viewModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    canAddPayment() {
      return (
        this.orderDetails.filter(
          (orderDetail) => orderDetail.id && orderDetail.qty > 0
        ).length && this.paymentId
      );
    },
    canValidate() {
      return (
        this.orderDetails.filter((orderDetail) => orderDetail.qty > 0).length &&
        this.totalPrice > this.minOrderSpend
      );
    },
    loading() {
      return (
        this.loadingSetup ||
        this.loadingOriginOffice ||
        this.loadingBuyerType ||
        this.loadingOrder ||
        this.loadingPaymentMethods
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, err] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 10,
          'fields[buyer-types]': 'code',
        },
      });

      if (!err && res.data.length) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadOriginOffice() {
      this.loadingOriginOffice = true;

      const [office, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}`,
        {
          params: {
            include: 'current-warehouse,area',
            'fields[offices]': 'code,name,current-warehouse,area',
            'fields[warehouses]': 'code,name',
            'fields[areas]': 'code,name',
          },
        }
      );

      if (!err) {
        this.order.originOffice.id = office.data.id;
        this.order.originOffice.code = office.data.attributes.code;
        this.order.originOffice.searchCode = office.data.attributes.code;
        this.order.originOffice.originalCode = office.data.attributes.code;
        this.order.originOffice.name = office.data.attributes.name;

        const warehouse = this.getSingleIncluded(
          office,
          office.data.relationships['current-warehouse'].data.id
        );

        this.order.originOffice.currentWarehouse.id = warehouse.id;
        this.order.originOffice.currentWarehouse.code =
          warehouse.attributes.code;

        const area = this.getSingleIncluded(
          office,
          office.data.relationships.area.data.id
        );

        this.order.originOffice.area.id = area.id;
        this.order.originOffice.area.code = area.attributes.code;
      }

      this.loadingOriginOffice = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      const [order, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'area,buyer-type,destination-warehouse,destination-office,origin-office,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,order_shipment,is_valid_for_payment,area,buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details,shipment_address,final_price',
            'fields[areas]': 'code',
            'fields[offices]': 'code,name,address,office_type,area_code',
            'fields[warehouses]': 'code,name',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price',
          },
        }
      );

      if (!err) {
        this.order.id = order.data.id;

        const area = this.getSingleIncluded(
          order,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          order,
          order.data.relationships['buyer-type'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          order,
          order.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['destination-warehouse'].data.id
        );
        const originOffice = this.getSingleIncluded(
          order,
          order.data.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['origin-warehouse'].data.id
        );

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        this.order.custom_shipment_address = false;
        this.order.shipmentAddress.address =
          destinationOffice.attributes.address ||
          order.data.attributes.shipment_address;
        this.order.date = order.data.attributes.createdAt;

        this.order.destinationOffice.id = destinationOffice.id;
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.originalCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.name = destinationOffice.attributes.name;

        this.order.destinationOffice.address =
          destinationOffice.attributes.address;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        this.order.originOfficeType = originOffice.attributes.office_type;

        this.order.originOffice.id = originOffice.id;
        this.order.originOffice.code = originOffice.attributes.code;
        this.order.originOffice.searchCode = `${originOffice.attributes.code} (${originOffice.attributes.area_code})`;
        this.order.originOffice.originalCode =
          this.order.originOffice.searchCode;
        this.order.originOffice.name = originOffice.attributes.name;

        this.order.originOffice.currentWarehouse.id = originWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          originWarehouse.attributes.code;

        this.order.originOffice.area.code = originOffice.attributes.area_code;

        this.order.shipment_type = order.data.attributes.order_shipment;

        this.order.valid = order.data.attributes.is_valid_for_payment;

        const orderDetails = this.getIncludedByType(order, 'order-details');

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
        }));

        this.orderDetails.push({
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        });

        this.loadPaymentId();
      }

      this.loadingOrder = false;
    },
    async loadPaymentId() {
      const [res, err] = await this.request(
        `/api/v1/orders/${this.order.id}/payments`,
        {
          params: {
            'page[size]': 1,
            'fields[payments]': 'code',
          },
        }
      );

      if (!err && res.data.length) {
        this.paymentId = res.data[0].id;
      }
    },
    async loadSetup() {
      this.loadingSetup = true;

      const [res, err] = await this.request('/api/v1/setups', {
        params: {
          'filter[setup_key]': 'min_order_spend_for_stockist',
          'fields[setups]': 'setup_value',
        },
      });

      if (!err && res.data.length) {
        this.minOrderSpend = +res.data[0].attributes.setup_value;
      }

      this.loadingSetup = false;
    },
    onConfirmValidate() {
      this.viewModalVisible = true;
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.loadPaymentId();

      this.$router.push({
        name: 'penjualan.edit',
        params: {
          id: order.data.id,
        },
      });
    },
    onValidate() {
      this.validateConfirmationVisible = true;
    },
  },
  created() {
    this.loadSetup();

    if (this.$route.params.id) {
      this.loadingPaymentMethods = true

      this.loadOrder();
    } else {
      this.loadBuyerType();
      this.loadOriginOffice();
    }
  },
};
</script>
