var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end gap-x-2"},[_c('base-select',{attrs:{"options":[
          { key: 'all', value: null, label: 'Semua' },
          { key: 'paid', value: true, label: 'Lunas' },
          { key: 'not_paid', value: false, label: 'Belum Lunas' },
        ],"expand":false,"shadow":false},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.is_paid),callback:function ($$v) {_vm.$set(_vm.filter, "is_paid", $$v)},expression:"filter.is_paid"}}),_c('base-input',{attrs:{"placeholder":"Search","shadow":false,"debounce":"","type":"search"},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"columns":_vm.columns,"data":_vm.orders.data,"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"cursor":""},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order,index){return _c('tr',{key:order.id,class:[
            classes.tr,
            index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
            'cursor-pointer bg-white hover:bg-green-100',
          ],on:{"click":function($event){return _vm.onViewOrder(order)}}},[_c('td',{class:[classes.td]},[_c('span',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(order.attributes.origin_code)+" ")]),_vm._v("/ "),_c('span',{staticClass:"text-gray-400"},[_vm._v(_vm._s(order.attributes.destination_code))]),_c('p',{staticClass:"mt-1 text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.attributes.updatedAt))+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.origin_office_code)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.origin_warehouse_code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(Math.max( _vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.grand_total_price - _vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.payment_amount, 0 )))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.grand_total_price))+" ")]),_c('td',{class:classes.td},[_c('base-badge',{attrs:{"color":order.attributes.is_paid ? 'green' : 'red'}},[_vm._v(_vm._s(order.attributes.is_paid ? 'Lunas' : 'Belum Lunas'))])],1),_c('td',{class:classes.td},[_c('base-badge',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(order.attributes.order_status))])],1)])})}}])})],1),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }