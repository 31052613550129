<template>
  <base-wrapper :loading="loadingSupplier">
    <div class="space-y-4">
      <div class="flex items-center justify-end space-x-2">
        <base-input
          type="search"
          placeholder="Cari Supplier"
          debounce
          :shadow="false"
          v-model="filter.search"
          @native-input="loadSupplier"
        />
        <base-button @click="onCreate" type="button">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          Tambah Supplier
        </base-button>
      </div>
      <datatable
        :total="suppliers.meta.page.total"
        :perPage="suppliers.meta.page.perPage"
        :currentPage="suppliers.meta.page.currentPage"
        :meta="suppliers.meta"
        :columns="[
          { id: 'code', name: 'Kode Supplier' },
          { id: 'name', name: 'Nama Supplier' },
          { id: 'phone', name: 'Alamat / No. Telp' },
        ]"
        cursor
        @pagechanged="onPageChange"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="supplier in suppliers.data"
            :key="supplier.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(supplier)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ supplier.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">{{ supplier.attributes.name }}</td>
            <td :class="classes.td">
              <div>
                <p>
                  {{ getOfficeIncluded(supplier).attributes.address ?? '' }}
                </p>
                <p class="text-xs">
                  {{ getOfficeIncluded(supplier).attributes.phone ?? '' }}
                </p>
              </div>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <office-form-modal
      office-type="supplier"
      :visible="createModalVisible"
      @close="createModalVisible = false"
      @success="onSuccessCreate"
    />
    <view-office-modal
      office-type="supplier"
      :with-users="false"
      :loading="detailModal.loading"
      :visible="detailModal.visible"
      :office-id="detailModal.officeId"
      @close="detailModal.visible = false"
    >
      <template #additional-attributes="{ classes }">
        <div :class="classes.wrapper">
          <dt :class="classes.title">Produk</dt>
          <dd :class="classes.content">
            <datatable
              :columns="[
                { id: 'code', name: 'Kode Barang' },
                { id: 'name', name: 'Nama Barang' },
                { id: 'category', name: 'Kelompok Barang' },
              ]"
              :total="detailModal.products.meta.page.total"
              :perPage="detailModal.products.meta.page.perPage"
              :currentPage="detailModal.products.meta.page.currentPage"
              @pagechanged="onPageChangeProduct"
            >
              <template #tbody="{ classes }">
                <tr
                  v-for="product in detailModal.products.data"
                  :key="product.id"
                  :class="[classes.tr, 'bg-white hover:bg-green-100']"
                >
                  <td :class="classes.td">
                    <p class="font-bold text-gray-900">
                      {{ product.attributes.code }}
                    </p>
                  </td>
                  <td :class="classes.td">{{ product.attributes.name }}</td>
                  <td :class="classes.td">
                    {{ product.attributes.product_category_name ?? '-' }}
                  </td>
                </tr>
              </template>
            </datatable>
          </dd>
        </div>
      </template>
    </view-office-modal>
  </base-wrapper>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import ViewOfficeModal from '@/components/office/view-office-modal.vue';
import OfficeFormModal from '@/components/office/office-form-modal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'Home',
  mixins: [requestMixin],
  components: {
    BaseButton,
    ViewOfficeModal,
    OfficeFormModal,
  },
  data() {
    return {
      detailModal: {
        officeId: null,
        visible: false,
        loading: false,
        products: {
          data: [],
          meta: {
            page: {},
          },
        },
      },
      filter: {
        search: null,
      },
      loadingSupplier: false,
      suppliers: {
        data: [],
        meta: {
          page: {},
        },
      },
      createModalVisible: false,
    };
  },
  created() {
    this.loadSupplier();
  },
  methods: {
    async onClickDetail(simpleOffice) {
      this.detailModal.officeId = simpleOffice.relationships.office.data.id;
      this.detailModal.visible = true;

      this.loadSupplierProducts();
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.suppliers,
        simpleOffice.relationships.office.data.id
      );
    },
    onPageChange(page) {
      this.loadSupplier(page);
    },
    onPageChangeProduct(page) {
      this.loadSupplierProducts({
        'page[number]': page,
      });
    },
    onCreate() {
      this.createModalVisible = true;
    },
    async loadSupplierProducts(params) {
      this.detailModal.loading = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.detailModal.officeId}/products`,
        {
          params: {
            'page[size]': 5,
            'fields[products]': 'code,name,product_category_name',
            ...params,
          },
        }
      );

      if (!err) {
        this.detailModal.products = res;
      }

      this.detailModal.loading = false;
    },
    onSuccessCreate() {
      this.createModalVisible = false;
      this.loadSupplier();
    },
    async loadSupplier(params) {
      this.loadingSupplier = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'page[limit]': 5,
          'filter[office_category_id]': 6,
          include: 'office',
          'fields[simple-offices]': 'code,name,office',
          'fields[offices]': 'address,phone',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.suppliers = res;
      }

      this.loadingSupplier = false;
    },
  },
};
</script>
