<template>
  <div>
    <base-wrapper class="space-y-6">
      <create-order-form
        :valid="order.valid"
        with-destination-area
        with-stockist-center-balance
        v-model="order"
        @change-destination-office="onChangeDestinationOffice"
      />
      <create-order-detail-form
        v-if="canCreateOrderDetail"
        :disabled="order.valid"
        :order-id="order.id"
        :origin-office="{ id: me.office_id, code: me.office_code }"
        :destination-office="{
          id: order.destinationOffice.id,
          code: order.destinationOffice.code,
        }"
        :origin-warehouse="{
          id: order.originWarehouse.id,
          code: order.originWarehouse.code,
        }"
        :buyer-type="{
          id: order.buyerType.id,
          code: order.buyerType.code,
        }"
        :area="{
          id: order.area.id,
          code: order.area.code,
        }"
        :create-order-attributes="{
          is_loan: order.loan,
          is_using_stock: order.using_stock,
          destination_warehouse_id: order.destinationOffice.currentWarehouse.id,
          order_type: 'sale',
          order_shipment: order.shipment_type,
          ...(order.custom_shipment_address
            ? {
                destination_address: {
                  province_id: order.shipmentAddress.province.id,
                  city_id: order.shipmentAddress.city.id,
                  district_id: order.shipmentAddress.district.id,
                  village_id: order.shipmentAddress.village.id,
                  address: order.shipmentAddress.address,
                },
              }
            : null),
        }"
        :min-spend="minOrderSpend"
        v-model="orderDetails"
        @order-created="onOrderCreated"
      />
      <create-balance-payment-form
        :disabled="order.valid"
        :payment-id="payment.id"
        v-model="balanceUsed"
        v-if="canCreatePayment"
      />
      <div class="flex justify-end gap-x-2">
        <base-button
          v-if="!order.valid"
          :disabled="!canValidate"
          @click="onValidate"
          >Validasi</base-button
        >
        <base-button :to="{ name: 'restock-sc' }" color="white"
          >Kembali</base-button
        >
      </div>
    </base-wrapper>
    <view-order-modal
      :visible="viewOrderModalVisible"
      :order-id="order.id"
      @validated="$router.push({ name: 'restock-sc' })"
      @close="$router.push({ name: 'restock-sc' })"
    />
    <validate-order-confirmation
      :order-id="order.id"
      :visible="confirmValidateOrderVisible"
      @close="confirmValidateOrderVisible = false"
      @confirmed="onValidateConfirmed"
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import CreateBalancePaymentForm from '@/components/payment/create-balance-payment-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';

export default {
  mixins: [requestMixin],
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    CreateBalancePaymentForm,
    ViewOrderModal,
    ValidateOrderConfirmation,
  },
  data() {
    return {
      balanceUsed: [
        {
          paymentMethodId: null,
          id: 'restock',
          type: 'restock_balance',
          name: 'Saldo Restock',
          balance: 0,
          amount: null,
          used: false,
        },
        {
          paymentMethodId: null,
          id: 'multiplied_deposit',
          type: 'multiplied_deposit_balance',
          name: 'Nilai Deposit Awal / Tambahan',
          balance: 0,
          amount: null,
          used: false,
        },
      ],
      confirmValidateOrderVisible: false,
      loadingBuyerType: false,
      loadingOrder: false,
      loadingPayment: false,
      loadingSetup: false,
      minOrderSpend: 0,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        date: new Date(),
        destinationOfficeType: 'stockist_center',
        destinationOffice: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          restock_balance: null,
          multiplied_deposit_balance: null,
          address: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          area: {
            id: null,
            code: null,
          },
        },
        loan: false,
        originWarehouse: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          area: {
            id: null,
            code: null,
          },
        },
        shipment_type: 'pickup',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
        custom_shipment_address: false,
        using_stock: true,
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        },
      ],
      payment: {
        id: null,
      },
      viewOrderModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    canCreateOrderDetail() {
      return this.order.destinationOffice.id && this.order.originWarehouse.id;
    },
    canCreatePayment() {
      return (
        this.canCreateOrderDetail && this.order.using_stock && !this.order.loan
      );
    },
    canValidate() {
      return this.totalPrice > this.minOrderSpend && this.totalPayment === this.totalPrice;
    },
    loading() {
      return (
        this.loadingBuyerType ||
        this.loadingSetup ||
        this.loadingOrder ||
        this.loadingPayment
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
    totalPayment() {
      return this.balanceUsed.reduce(
        (total, item) => total + (typeof item.amount === 'string' ? parseInt(item.amount.replace(/\D/gi, '')) : item.amount),
        0
      );
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, error] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 10,
          'fields[buyer-types]': 'code',
        },
      });

      if (!error) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      this.order.id = this.$route.params.id;

      const [res, error] = await this.request(
        `/api/v1/orders/${this.order.id}`,
        {
          params: {
            include:
              'area,buyer-type,destination-office,destination-warehouse,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,order_shipment,is_using_stock,is_loan,shipment_address,is_completed,is_valid_for_packing,area,buyer-type,destination-office,destination-warehouse,origin-warehouse,order-details',
            'fields[areas]': 'code',
            'fields[buyer-types]': 'code',
            'fields[offices]':
              'code,name,area_code,restock_balance,multiplied_deposit_balance,address',
            'fields[warehouses]': 'code,name,area_code',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price',
          },
        }
      );

      if (!error && res.data) {
        const order = res;

        const area = this.getSingleIncluded(
          res,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          res,
          order.data.relationships['buyer-type'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          res,
          order.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          res,
          order.data.relationships['destination-warehouse'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          res,
          order.data.relationships['origin-warehouse'].data.id
        );
        const orderDetails = this.getIncludedByType(res, 'order-details');

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        (this.order.date = order.data.attributes.createdAt),
          (this.order.destinationOffice.id = destinationOffice.id);
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode = `${destinationOffice.attributes.code} (${destinationOffice.attributes.area_code})`;
        this.order.destinationOffice.originalCode =
          this.order.destinationOffice.searchCode;
        this.order.destinationOffice.name = destinationOffice.attributes.name;
        this.order.destinationOffice.restock_balance =
          destinationOffice.attributes.restock_balance;
        this.order.destinationOffice.multiplied_deposit_balance =
          destinationOffice.attributes.multiplied_deposit_balance;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        this.order.destinationOffice.area.code =
          destinationOffice.attributes.area_code;

        this.order.originWarehouse.id = originWarehouse.id;
        this.order.originWarehouse.code = originWarehouse.attributes.code;
        this.order.originWarehouse.searchCode = `${originWarehouse.attributes.code} (${originWarehouse.attributes.area_code})`;
        this.order.originWarehouse.originalCode =
          this.order.originWarehouse.searchCode;
        this.order.originWarehouse.name = originWarehouse.attributes.name;

        this.order.originWarehouse.area.code =
          originWarehouse.attributes.area_code;

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.shipment_type = order.data.attributes.order_shipment;
        this.order.using_stock = order.data.attributes.is_using_stock;
        this.order.loan = order.data.attributes.is_loan;

        this.order.shipmentAddress.address =
          order.data.attributes.shipment_address ||
          destinationOffice.attributes.address;

        this.order.valid =
          order.data.attributes.is_completed ||
          order.data.attributes.is_valid_for_packing;

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
        }));

        if (!this.order.valid) {
          this.orderDetails.push({
            id: null,
            productCode: null,
            originalProductCode: null,
            productName: null,
            stock: null,
            qty: null,
            originalQty: null,
            price: null,
            totalPrice: null,
          });
        }

        if (
          order.data.attributes.is_using_stock &&
          !order.data.attributes.is_loan
        ) {
          this.loadPayment();
        }

        this.updateBalanceUsedBalance();
      }

      this.loadingOrder = false;
    },
    async loadPayment() {
      this.loadingPayment = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.order.id}/payments`,
        {
          params: {
            include: 'payment-methods',
            'fields[payments]': 'payment-methods',
            'fields[payment-methods]': 'payment_amount,balance_used',
          },
        }
      );

      if (!err) {
        this.payment.id = res.data[0].id;

        const paymentMethods = this.getIncludedByType(res, 'payment-methods');

        this.balanceUsed.forEach((balanceUsed, index) => {
          const paymentMethod = paymentMethods.find(
            (paymentMethod) =>
              paymentMethod.attributes.balance_used === balanceUsed.id
          );

          if (paymentMethod) {
            this.balanceUsed[index].used = true;
            this.balanceUsed[index].paymentMethodId = paymentMethod.id;
            this.balanceUsed[index].amount =
              paymentMethod.attributes.payment_amount;
          }
        });
      }

      this.loadingPayment = false;
    },
    async loadSetup() {
      this.loadingSetup = true;

      const [res, err] = await this.request('/api/v1/setups', {
        params: {
          'filter[setup_key]': 'min_order_spend_for_sc',
          'fields[setups]': 'setup_value',
        },
      });

      if (!err && res.data.length) {
        this.minOrderSpend = +res.data[0].attributes.setup_value;
      }

      this.loadingSetup = false;
    },
    onChangeDestinationOffice() {
      this.updateBalanceUsedBalance();
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.$router.push({
        name: 'restock-sc.edit',
        params: {
          id: order.data.id,
        },
      });

      if (
        order.data.attributes.is_using_stock &&
        !order.data.attributes.is_loan
      ) {
        this.loadPayment();
      }
    },
    async onValidate() {
      this.confirmValidateOrderVisible = true;
    },
    onValidateConfirmed() {
      this.confirmValidateOrderVisible = false;
      this.viewOrderModalVisible = true;
    },
    updateBalanceUsedBalance() {
      this.balanceUsed.forEach((balanceUsed, index) => {
        this.balanceUsed[index].balance =
          this.order.destinationOffice[balanceUsed.type];
      });
    },
  },
  created() {
    this.loadSetup();

    if (this.$route.params.id) {
      this.loadOrder();
    } else {
      this.loadBuyerType();
    }
  },
};
</script>
