var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loadingSupplier}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('base-input',{attrs:{"type":"search","placeholder":"Cari Supplier","debounce":"","shadow":false},on:{"native-input":_vm.loadSupplier},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-button',{attrs:{"type":"button"},on:{"click":_vm.onCreate}},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:plus-20-solid"}}),_vm._v(" Tambah Supplier ")],1)],1),_c('datatable',{attrs:{"total":_vm.suppliers.meta.page.total,"perPage":_vm.suppliers.meta.page.perPage,"currentPage":_vm.suppliers.meta.page.currentPage,"meta":_vm.suppliers.meta,"columns":[
        { id: 'code', name: 'Kode Supplier' },
        { id: 'name', name: 'Nama Supplier' },
        { id: 'phone', name: 'Alamat / No. Telp' },
      ],"cursor":""},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.suppliers.data),function(supplier){return _c('tr',{key:supplier.id,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){return _vm.onClickDetail(supplier)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(supplier.attributes.code)+" ")])]),_c('td',{class:classes.td},[_vm._v(_vm._s(supplier.attributes.name))]),_c('td',{class:classes.td},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(supplier).attributes.address ?? '')+" ")]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(supplier).attributes.phone ?? '')+" ")])])])])})}}])})],1),_c('office-form-modal',{attrs:{"office-type":"supplier","visible":_vm.createModalVisible},on:{"close":function($event){_vm.createModalVisible = false},"success":_vm.onSuccessCreate}}),_c('view-office-modal',{attrs:{"office-type":"supplier","with-users":false,"loading":_vm.detailModal.loading,"visible":_vm.detailModal.visible,"office-id":_vm.detailModal.officeId},on:{"close":function($event){_vm.detailModal.visible = false}},scopedSlots:_vm._u([{key:"additional-attributes",fn:function({ classes }){return [_c('div',{class:classes.wrapper},[_c('dt',{class:classes.title},[_vm._v("Produk")]),_c('dd',{class:classes.content},[_c('datatable',{attrs:{"columns":[
              { id: 'code', name: 'Kode Barang' },
              { id: 'name', name: 'Nama Barang' },
              { id: 'category', name: 'Kelompok Barang' },
            ],"total":_vm.detailModal.products.meta.page.total,"perPage":_vm.detailModal.products.meta.page.perPage,"currentPage":_vm.detailModal.products.meta.page.currentPage},on:{"pagechanged":_vm.onPageChangeProduct},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.detailModal.products.data),function(product){return _c('tr',{key:product.id,class:[classes.tr, 'bg-white hover:bg-green-100']},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(product.attributes.code)+" ")])]),_c('td',{class:classes.td},[_vm._v(_vm._s(product.attributes.name))]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(product.attributes.product_category_name ?? '-')+" ")])])})}}],null,true)})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }