var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end gap-x-2"},[_c('base-input',{attrs:{"shadow":false,"placeholder":"Cari Kode","debounce":"","type":"search"},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-select',{attrs:{"fit":"","shadow":false,"options":[
          { key: 'all', label: 'Status', value: null },
          { key: 'not_received', label: 'Belum Diterima', value: false },
          { key: 'received', label: 'Diterima', value: true },
        ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.is_received),callback:function ($$v) {_vm.$set(_vm.filter, "is_received", $$v)},expression:"filter.is_received"}}),_c('base-button',{attrs:{"to":{ name: 'pembelian.tambah' }}},[_vm._v("Tambah Pembelian")])],1),_c('div',[_c('datatable',{attrs:{"columns":_vm.columns,"data":_vm.orders.data,"scroll-x":false,"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"cursor":""},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order,index){return _c('tr',{key:order.id,class:[
              classes.tr,
              index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              'cursor-pointer bg-white hover:bg-green-100',
            ],on:{"click":function($event){_vm.onClickRow(
                _vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
              )}}},[_c('td',{class:[classes.td]},[_c('p',[_c('span',{staticClass:"font-bold text-gray-900"},[_vm._v(_vm._s(order.attributes.origin_code))])]),_c('span',{staticClass:"text-xs text-gray-400"},[_vm._v(_vm._s(_vm._f("formatDate")(order.attributes.updatedAt)))])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(order.attributes.origin_code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.item_count))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.final_price))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
                    .attributes.is_valid_for_payment
                    ? 'green'
                    : 'yellow'}},[_vm._v(_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.is_valid_for_payment ? 'Valid' : 'Draft'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id)
                    .attributes.is_completed
                    ? 'green'
                    : 'red'}},[_vm._v(_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.is_completed ? 'Selesai' : 'Belum Selesai'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":"indigo"}},[_vm._v(_vm._s(order.attributes.order_status))])],1)])})}}])})],1)]),_c('view-order-modal',{attrs:{"stock-movement-modal-props":{
      withFirstPacketProduct: true,
      withBatch: true,
    },"visible":_vm.viewOrderModal.visible,"order-id":_vm.viewOrderModal.orderId,"with-payment":false,"edit-route-name":"pembelian.edit","label":"Pembelian"},on:{"close":function($event){_vm.viewOrderModal.visible = false},"validated":_vm.onValidated,"deleted":_vm.onDeleted}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }