<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card>
        <div class="grid gap-4 sm:grid-cols-2">
          <search-product-input
            mode="input"
            required
            v-model="form.product"
            @searched="onProductSearched"
            @selected="onProductSelected"
          />
          <base-input
            inset
            required
            type="text"
            label="Nama Barang"
            placeholder="Masukkan Nama Barang"
            v-model="form.product.name"
          />

          <search-office-input
            office-type="supplier"
            placeholder="Masukkan Kode Supplier"
            required
            v-model="form.office"
          />
          <base-input
            inset
            required
            type="text"
            label="Nama Supplier"
            placeholder="Masukkan Nama Supplier"
            v-model="form.office.name"
          />

          <base-input inset required label="Kelompok Produk">
            <base-select
              inset
              expand
              :options="productCategoryOptions"
              v-model="form.productCategoryId"
            ></base-select>
          </base-input>
          <base-input inset required label="Unit">
            <base-select
              inset
              expand
              :options="unitOptions"
              v-model="form.unitId"
            ></base-select>
          </base-input>

          <base-input
            inset
            required
            currency
            type="text"
            label="Volume"
            placeholder="Masukkan Volume Barang"
            v-model="form.volume"
          />
          <base-input
            inset
            required
            currency
            type="text"
            label="Berat(Kg)"
            placeholder="Masukkan Berat Barang"
            v-model="form.weight"
          />

          <base-input
            inset
            required
            currency
            type="text"
            label="Harga Supplier"
            placeholder="Masukkan Harga Supplier"
            v-model="form.supplierPrice"
          />
          <base-input
            inset
            required
            currency
            type="text"
            label="Harga Supplier Promo"
            placeholder="Masukkan Harga Supplier Promo"
            v-model="form.supplierFreePrice"
          />
        </div>
      </base-card>

      <base-card>
        <div class="space-y-4">
          <p class="font-bold">P/N</p>
          <div class="flex gap-4">
            <base-radio :val="true" label="Point" v-model="form.point" />
            <base-radio :val="false" label="Non-Point" v-model="form.point" />
          </div>
          <div v-if="form.point" class="grid gap-4 sm:grid-cols-2">
            <base-input
              inset
              type="text"
              currency
              label="Point Value"
              placeholder="Masukkan Point Value"
              v-model="form.pointValue"
            />
            <base-input
              inset
              type="text"
              currency
              label="Bonus Value"
              placeholder="Masukkan Bonus Value"
              v-model="form.bonusValue"
            />
          </div>
        </div>
      </base-card>

      <base-card>
        <div class="grid gap-4 sm:grid-cols-2">
          <base-input
            inset
            type="text"
            class="col-span-full"
            label="Nomor Izin"
            placeholder="Masukkan Nomor Izin"
            v-model="form.licenseNumber"
          />
          <base-input
            inset
            type="text"
            class="col-span-full"
            label="Dinas/Lembaga Izin"
            placeholder="Masukkan Dinas/Lembaga Izin"
            v-model="form.licenseInstitution"
          />
          <base-input
            inset
            type="date"
            label="Mulai Izin"
            v-model="form.licenseStart"
          />
          <base-input
            inset
            type="date"
            label="Akhir Izin (Perkiraan)"
            v-model="form.licenseEnd"
          />
          <div class="col-span-2 flex flex-col gap-2">
            <p class="text-sm font-bold">Status Izin</p>
            <base-radio
              :val="true"
              label="Lengkap"
              v-model="form.licenseStatus"
            />
            <base-radio
              :val="false"
              label="Sedang Diproses"
              v-model="form.licenseStatus"
            />
          </div>
        </div>
      </base-card>

      <base-card v-if="!loadingProduct">
        <div class="space-y-6">
          <div
            v-for="(limit, index) in form.limits"
            :key="limit.origin"
            class="space-y-4 border-b pb-6"
          >
            <div class="flex justify-between">
              <p class="font-bold">Penjualan {{ limit.origin }}</p>
              <toggle-button
                v-model="form.limits[index].orderable"
                :labels="{
                  checked: 'Open',
                  unchecked: 'Close',
                }"
                :width="60"
                @change="onChangeOrderable(index)"
              />
            </div>

            <template v-if="limit.orderable">
              <input-product-limit v-model="form.limits[index]" />

              <div class="flex items-center gap-4">
                <p>Pre Order</p>
                <toggle-button
                  v-model="form.limits[index].preOrderable"
                  :labels="{
                    checked: 'Open',
                    unchecked: 'Close',
                  }"
                  :width="60"
                  @change="onChangePreOrderable(index)"
                />
              </div>

              <div
                v-if="limit.preOrderable"
                :class="[
                  'grid gap-8',
                  limit.preOrder.length === 3
                    ? 'grid-cols-3'
                    : limit.preOrder.length === 2
                    ? 'grid-cols-2'
                    : '',
                ]"
              >
                <div
                  v-for="(preOrderLimit, preOrderIndex) in limit.preOrder"
                  :key="preOrderLimit.destination"
                  class="space-y-4"
                >
                  <p class="text-sm font-bold">
                    {{ preOrderLimit.destination }}
                  </p>

                  <input-product-limit
                    v-model="form.limits[index].preOrder[preOrderIndex]"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </base-card>

      <base-card v-if="!loadingProduct">
        <datatable
          color="green"
          :columns="[
            { id: 'area', name: '' },
            { id: 'stockist', name: 'Harga Stockist' },
            { id: 'mitra_usaha', name: 'Harga Mitra Uaha' },
            { id: 'konsumen', name: 'Harga Konsumen' },
          ]"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="(area, index) in form.areaPrices"
              :key="area.areaId"
              :class="classes.tr"
            >
              <td :class="[classes.td, 'font-bold text-gray-900']">
                {{ area.name }}
              </td>
              <td :class="classes.td">
                <base-input
                  :shadow="false"
                  currency
                  placeholder="Masukkan Harga Stockist"
                  fullwidth
                  type="text"
                  v-model="form.areaPrices[index].stockistPrice"
                />
              </td>
              <td :class="classes.td">
                <base-input
                  :shadow="false"
                  currency
                  placeholder="Masukkan Harga MitraUsaha"
                  fullwidth
                  type="text"
                  v-model="form.areaPrices[index].mitraUsahaPrice"
                />
              </td>
              <td :class="classes.td">
                <base-input
                  :shadow="false"
                  currency
                  placeholder="Masukkan Harga Konsumen"
                  fullwidth
                  type="text"
                  v-model="form.areaPrices[index].konsumenPrice"
                />
              </td>
            </tr>
            <tr
              v-for="(buyerType, index) in form.extraPrices"
              :key="buyerType.buyerTypeId"
              :class="classes.tr"
            >
              <td :class="[classes.td, 'font-bold text-gray-900']">
                {{ buyerType.name }}
              </td>
              <td :class="classes.td" colspan="3">
                <base-input
                  :shadow="false"
                  currency
                  placeholder="Masukkan Harga Stockist"
                  fullwidth
                  type="text"
                  v-model="form.extraPrices[index].price"
                />
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>

      <div class="flex justify-end">
        <base-button @click="onSave">Simpan</base-button>
      </div>
    </div>

    <base-confirm
      :visible="successModal.visible"
      type="success"
      :with-cancel="false"
      confirm-text="Yakin"
      message="Data Berhasil disimpan"
      @confirmed="$router.push({ name: 'master.barang' })"
      @close="$router.push({ name: 'master.barang' })"
    />
  </base-wrapper>
</template>

<script>
import SearchProductInput from '@/components/product/search-product-input.vue';
import SearchOfficeInput from '@/components/office/search-office-input.vue';
import { requestMixin } from '@//mixins/request/request';
import BaseRadio from '@/components/base/BaseRadio.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import InputProductLimit from '@/components/product/input-product-limit.vue';
import { mapActions } from 'vuex';
import BaseConfirm from '@/components/base/BaseConfirm.vue';

export default {
  mixins: [requestMixin],
  components: {
    SearchProductInput,
    SearchOfficeInput,
    BaseRadio,
    ToggleButton,
    InputProductLimit,
    BaseConfirm,
  },
  data() {
    return {
      form: {
        product: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
        },
        office: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
        },
        productCategoryId: null,
        unitId: null,
        volume: null,
        weight: null,
        supplierPrice: null,
        supplierFreePrice: null,
        point: false,
        pointValue: null,
        bonusValue: null,
        licenseNumber: null,
        licenseInstitution: null,
        licenseStart: null,
        licenseEnd: null,
        licenseStatus: false,
        limits: [
          {
            origin: 'Pusat',
            orderable: true,
            unlimited: true,
            limit: null,
            limitPeriodDate: false,
            limitPeriod: null,
            limitPeriodStartAt: null,
            limitPeriodEndAt: null,
            preOrderable: false,
            preOrder: [
              {
                destination: 'Kantor Pelayanan',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
              {
                destination: 'Stockist Center',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
              {
                destination: 'Stockist',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
            ],
          },
          {
            origin: 'Kantor Pelayanan',
            orderable: true,
            unlimited: true,
            limit: null,
            limitPeriodDate: false,
            limitPeriod: null,
            limitPeriodStartAt: null,
            limitPeriodEndAt: null,
            preOrderable: false,
            preOrder: [
              {
                destination: 'Stockist Center',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
              {
                destination: 'Stockist',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
            ],
          },
          {
            origin: 'Stockist Center',
            orderable: true,
            unlimited: true,
            limit: null,
            limitPeriodDate: false,
            limitPeriod: null,
            limitPeriodStartAt: null,
            limitPeriodEndAt: null,
            preOrderable: false,
            preOrder: [
              {
                destination: 'Stockist',
                unlimited: true,
                limit: null,
                limitPeriodDate: false,
                limitPeriod: null,
                limitPeriodStartAt: null,
                limitPeriodEndAt: null,
              },
            ],
          },
        ],
        areaPrices: [],
        extraPrices: [],
      },
      loadingProduct: false,
      loadingProductCategories: false,
      loadingUnits: false,
      loadingAreas: false,
      loadingBuyerTypes: false,
      loadingSave: false,
      loadingOfficeCategories: false,
      areas: {
        data: [],
      },
      buyerTypes: {
        data: [],
      },
      productCategories: {
        data: [],
      },
      units: {
        data: [],
      },
      officeCategories: {
        data: [],
      },
      successModal: {
        visible: false,
        type: 'created',
      },
    };
  },
  computed: {
    loading() {
      return (
        this.loadingProduct ||
        this.loadingProductCategories ||
        this.loadingUnits ||
        this.loadingAreas ||
        this.loadingBuyerTypes ||
        this.loadingSave ||
        this.loadingOfficeCategories
      );
    },
    productCategoryOptions() {
      return [
        {
          key: null,
          value: null,
          label: 'Pilih Kelompok Produk',
        },
        ...this.productCategories.data.map((category) => ({
          key: category.id,
          value: category.id,
          label: category.attributes.name,
        })),
      ];
    },
    unitOptions() {
      return [
        {
          key: null,
          value: null,
          label: 'Pilih Unit',
        },
        ...this.units.data.map((unit) => ({
          key: unit.id,
          value: unit.id,
          label: unit.attributes.name,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async createProduct(payload) {
      const [, err] = await this.request('/api/v1/products', {
        method: 'post',
        data: {
          data: payload,
        },
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.successModal.visible = true;
        this.successModal.type = 'created';
      }
    },
    getBuyerTypeId(name) {
      return this.buyerTypes.data.find(
        (buyerType) => buyerType.attributes.name === name
      ).id;
    },
    getOfficeCategoryId(name) {
      return this.officeCategories.data.find(
        (officeCategory) => officeCategory.attributes.name === name
      ).id;
    },
    getProductPrice(data, area, buyerType) {
      const productPrice = data.find(
        (item) =>
          item.attributes.area === area &&
          item.attributes['buyer-type'] === buyerType
      );

      if (!productPrice) {
        return 0;
      }

      return productPrice.attributes.price;
    },
    async initPrices(firstLoad = false, data) {
      if (firstLoad) {
        await Promise.all([this.loadAreas(), this.loadBuyerTypes()]);
      }

      const areaJawa = this.areas.data.find(
        (area) => area.attributes.name === 'Wilayah Jawa'
      );

      this.form.areaPrices = this.areas.data.map((area) => ({
        areaId: area.id,
        name: area.attributes.name,
        stockistPrice: data
          ? this.getProductPrice(data, area.attributes.name, 'Stockist')
          : 0,
        mitraUsahaPrice: data
          ? this.getProductPrice(data, area.attributes.name, 'Mitra Usaha')
          : 0,
        konsumenPrice: data
          ? this.getProductPrice(data, area.attributes.name, 'Konsumen')
          : 0,
      }));
      this.form.extraPrices = this.buyerTypes.data
        .filter((buyerType) =>
          ['Karyawan', 'Khusus', 'Free'].includes(buyerType.attributes.name)
        )
        .map((buyerType) => ({
          buyerTypeId: buyerType.id,
          name: buyerType.attributes.name,
          areaId: areaJawa.id,
          price: data
            ? this.getProductPrice(
                data,
                areaJawa.attributes.name,
                buyerType.attributes.name
              )
            : 0,
        }));
    },
    async loadProduct() {
      this.loadingProduct = true;

      const [res, err] = await this.request(
        `/api/v1/products/${this.form.product.id}`,
        {
          params: {
            'fields[products]':
              'volume,weight,supplier_price,supplier_free_price,is_point,point,bonus,license_number,license_institution,license_start,license_end,license_status,office,product-category,unit,product-limits,product-prices',
            'fields[offices]': 'code,name',
            'fields[product-categories]': 'name',
            'fields[units]': 'name',
            include:
              'office,product-category,unit,product-limits,product-prices',
          },
        }
      );

      if (!err) {
        this.form.volume = res.data.attributes.volume;
        this.form.weight = res.data.attributes.weight;
        this.form.supplierPrice = res.data.attributes.supplier_price;
        this.form.supplierFreePrice = res.data.attributes.supplier_free_price;
        this.form.point = res.data.attributes.is_point;
        this.form.pointValue = res.data.attributes.point;
        this.form.bonusValue = res.data.attributes.bonus;

        this.form.licenseNumber = res.data.attributes.license_number;
        this.form.licenseInstitution = res.data.attributes.license_institution;
        this.form.licenseStart = res.data.attributes.license_start;
        this.form.licenseEnd = res.data.attributes.license_end;
        this.form.licenseStatus = res.data.attributes.license_status;

        const supplierRel = res.data.relationships.office.data;

        if (supplierRel) {
          const supplier = this.getSingleIncluded(res, supplierRel.id);

          this.form.office.id = supplier.id;
          this.form.office.code = supplier.attributes.code;
          this.form.office.searchCode = supplier.attributes.code;
          this.form.office.originalCode = supplier.attributes.code;
          this.form.office.name = supplier.attributes.name;
        } else {
          this.form.office.id = null;
          this.form.office.code = null;
          this.form.office.searchCode = null;
          this.form.office.originalCode = null;
          this.form.office.name = null;
        }

        this.form.productCategoryId =
          res.data.relationships['product-category'].data?.id;
        this.form.unitId = res.data.relationships.unit.data?.id;

        const productLimits = this.getIncludedByType(res, 'product-limits');

        if (productLimits.length) {
          this.form.limits.forEach((limit, index) => {
            const productLimit = productLimits.find(
              (productLimit) =>
                productLimit.attributes.origin_office_category ===
                  limit.origin &&
                productLimit.attributes.destination_office_category ===
                  limit.origin
            );

            if (!productLimit) {
              this.form.limits[index].orderable = true;
              this.form.limits[index].unlimited = true;
              this.form.limits[index].limit = null;
              this.form.limits[index].limitPeriodDate = false;
              this.form.limits[index].limitPeriod = null;
              this.form.limits[index].limitPeriodStartAt = null;
              this.form.limits[index].limitPeriodEndAt = null;
              this.form.limits[index].preOrderable =
                productLimits.filter(
                  (productLimit) =>
                    productLimit.attributes.origin_office_category ===
                    limit.origin
                ).length > 0;
            } else {
              this.form.limits[index].orderable =
                productLimit.attributes.is_orderable;
              this.form.limits[index].unlimited =
                productLimit.attributes.limit_order_periodic === 0;
              this.form.limits[index].limit =
                productLimit.attributes.limit_order_periodic === 0
                  ? null
                  : productLimit.attributes.limit_order_periodic;
              this.form.limits[index].limitPeriodDate =
                productLimit.attributes.is_custom_date_order;
              this.form.limits[index].limitPeriod = productLimit.attributes
                .is_custom_date_order
                ? null
                : productLimit.attributes.current_limit_order_periodic;
              this.form.limits[index].limitPeriodStartAt =
                productLimit.attributes.custom_order_start;
              this.form.limits[index].limitPeriodEndAt =
                productLimit.attributes.custom_order_end;
              this.form.limits[index].preOrderable =
                productLimit.attributes.is_pre_orderable;
            }

            limit.preOrder.forEach((preOrder, preOrderIndex) => {
              const productLimit = productLimits.find(
                (productLimit) =>
                  productLimit.attributes.origin_office_category ===
                    limit.origin &&
                  productLimit.attributes.destination_office_category ===
                    preOrder.destination
              );

              this.form.limits[index].preOrder[preOrderIndex].orderable =
                productLimit.attributes.is_orderable;
              this.form.limits[index].preOrder[preOrderIndex].unlimited =
                productLimit.attributes.limit_order_periodic === 0;
              this.form.limits[index].preOrder[preOrderIndex].limit =
                productLimit.attributes.limit_order_periodic === 0
                  ? null
                  : productLimit.attributes.limit_order_periodic;
              this.form.limits[index].preOrder[preOrderIndex].limitPeriodDate =
                productLimit.attributes.is_custom_date_order;
              this.form.limits[index].preOrder[preOrderIndex].limitPeriod =
                productLimit.attributes.is_custom_date_order
                  ? null
                  : productLimit.attributes.current_limit_order_periodic;
              this.form.limits[index].preOrder[
                preOrderIndex
              ].limitPeriodStartAt = productLimit.attributes.custom_order_start;
              this.form.limits[index].preOrder[preOrderIndex].limitPeriodEndAt =
                productLimit.attributes.custom_order_end;
            });
          });
        }

        const productPrices = this.getIncludedByType(res, 'product-prices');

        if (productPrices.length) {
          this.initPrices(false, productPrices);
        }
      }

      this.loadingProduct = false;
    },
    async loadOfficeCategories() {
      this.loadingOfficeCategories = true;

      const [res, err] = await this.request('/api/v1/office-categories', {
        params: {
          'page[size]': 30,
          'fields[office-categories]': 'name',
        },
      });

      if (!err) {
        this.officeCategories = res;
      }

      this.loadingOfficeCategories = false;
    },
    async loadProductCategories() {
      this.loadingProductCategories = true;

      const [res, err] = await this.request('/api/v1/product-categories', {
        params: {
          'page[size]': 30,
          'fields[product-categories]': 'name',
        },
      });

      if (!err) {
        this.productCategories = res;
      }

      this.loadingProductCategories = false;
    },
    async loadUnits() {
      this.loadingUnits = true;

      const [res, err] = await this.request('/api/v1/units', {
        params: {
          'page[size]': 30,
          'fields[units]': 'name',
        },
      });

      if (!err) {
        this.units = res;
      }

      this.loadingUnits = false;
    },
    async loadAreas() {
      this.loadingAreas = true;

      const [res, err] = await this.request('/api/v1/areas', {
        params: {
          'page[size]': 30,
          'fields[areas]': 'name',
          sort: 'code',
        },
      });

      if (!err) {
        this.areas = res;
      }

      this.loadingAreas = false;
    },
    async loadBuyerTypes() {
      this.loadingBuyerTypes = true;

      const [res, err] = await this.request('/api/v1/buyer-types', {
        params: {
          'page[size]': 30,
          'fields[buyer-types]': 'name',
        },
      });

      if (!err) {
        this.buyerTypes = res;
      }

      this.loadingBuyerTypes = false;
    },
    onChangeOrderable(index) {
      if (this.form.limits[index].orderable) {
        this.resetLimit(index);
      }
    },
    onChangePreOrderable(index) {
      if (this.form.limits[index].preOrderable) {
        this.resetPreOrderLimit(index);
      }
    },
    async onProductSearched() {
      if (!this.form.product.id) {
        this.loadingProduct = true;

        this.form.office.code = null;
        this.form.office.searchCode = null;
        this.form.office.originalCode = null;
        this.form.office.name = null;

        this.form.productCategoryId = null;
        this.form.unitId = null;
        this.form.volume = null;
        this.form.weight = null;
        this.form.supplierFreePrice = null;
        this.form.supplierPrice = null;

        this.form.point = false;
        this.form.pointValue = null;
        this.form.bonusValue = null;

        this.form.licenseNumber = null;
        this.form.licenseInstitution = null;
        this.form.licenseStart = null;
        this.form.licenseEnd = null;
        this.form.licenseStatus = false;

        this.resetLimits();
        this.initPrices();

        await this.$nextTick();

        this.loadingProduct = false;
      } else {
        this.loadProduct();
      }
    },
    onProductSelected() {
      this.loadProduct();
    },
    async onSave() {
      this.loadingSave = true;

      const id = this.form.product.id;
      const payload = {
        ...(id ? { id } : {}),
        type: 'products',
        attributes: {
          code: this.form.product.code,
          name: this.form.product.name,
          volume: parseInt(this.form.volume),
          weight: parseInt(this.form.weight),
          supplier_price: this.parsePrice(this.form.supplierPrice),
          supplier_free_price: this.parsePrice(this.form.supplierFreePrice),
          is_point: this.form.point,
          point:
            this.form.pointValue === null
              ? undefined
              : this.parsePrice(this.form.pointValue),
          bonus:
            this.form.bonusValue === null
              ? undefined
              : this.parsePrice(this.form.bonusValue),
          license_number: this.form.licenseNumber,
          license_institution: this.form.licenseInstitution,
          license_start: this.form.licenseStart,
          license_end: this.form.licenseEnd,
          license_status: this.form.licenseStatus,
          product_limits: this.form.limits.flatMap((limit) => {
            return limit.preOrder.map((preOrder) => ({
              origin_office_category_id: this.getOfficeCategoryId(limit.origin),
              destination_office_category_id: this.getOfficeCategoryId(
                preOrder.destination
              ),
              is_orderable: limit.orderable,
              is_custom_date_order: limit.limitPeriodDate,
              limit_order_custom_date: null,
              limit_order_periodic: limit.unlimited ? 0 : parseInt(limit.limit),
              current_limit_order_periodic: limit.limitPeriod,
              custom_order_start: limit.limitPeriodStartAt,
              custom_order_end: limit.limitPeriodEndAt,

              is_pre_orderable: limit.preOrderable,
              is_custom_date_pre_order: preOrder.limitPeriodDate,
              limit_pre_order_custom_date: null,
              limit_pre_order_periodic: preOrder.unlimited
                ? 0
                : parseInt(preOrder.limit),
              current_limit_pre_order_periodic: preOrder.limitPeriod,
              custom_pre_order_start: preOrder.limitPeriodStartAt,
              custom_pre_order_end: preOrder.limitPeriodEndAt,
            }));
          }),
          product_prices: [
            ...this.form.areaPrices.flatMap((areaPrice) => {
              return [
                {
                  area_id: areaPrice.areaId,
                  buyer_type_id: this.getBuyerTypeId('Stockist'),
                  price: this.parsePrice(areaPrice.stockistPrice),
                },
                {
                  area_id: areaPrice.areaId,
                  buyer_type_id: this.getBuyerTypeId('Mitra Usaha'),
                  price: this.parsePrice(areaPrice.mitraUsahaPrice),
                },
                {
                  area_id: areaPrice.areaId,
                  buyer_type_id: this.getBuyerTypeId('Konsumen'),
                  price: this.parsePrice(areaPrice.konsumenPrice),
                },
              ];
            }),
            ...this.form.extraPrices.map((extraPrice) => ({
              area_id: extraPrice.areaId,
              buyer_type_id: extraPrice.buyerTypeId,
              price: this.parsePrice(extraPrice.price),
            })),
          ],
        },
        relationships: {
          office: {
            data: {
              type: 'offices',
              id: this.form.office.id,
            },
          },
          'product-category': {
            data: {
              type: 'product-categories',
              id: this.form.productCategoryId,
            },
          },
          unit: {
            data: {
              type: 'units',
              id: this.form.unitId,
            },
          },
        },
      };

      if (id) {
        await this.updateProduct(payload);
      } else {
        await this.createProduct(payload);
      }

      this.loadingSave = false;
    },
    parsePrice(str) {
      if (str === null) {
        return null;
      }

      if (typeof str !== 'string') {
        return str;
      }

      return parseInt(str.replace(/\D/g, ''));
    },
    resetLimits() {
      this.form.limits.forEach((_, i) => {
        this.form.limits[i].orderable = true;

        this.resetLimit(i);
      });
    },
    resetLimit(index) {
      this.form.limits[index].unlimited = true;
      this.form.limits[index].limit = null;
      this.form.limits[index].limitPeriodDate = false;
      this.form.limits[index].limitPeriod = null;
      this.form.limits[index].limitPeriodStartAt = null;
      this.form.limits[index].limitPeriodEndAt = null;
      this.form.limits[index].preOrderable = false;

      this.resetPreOrderLimit(index);
    },
    resetPreOrderLimit(index) {
      this.form.limits[index].preOrder.forEach((_, preOderIndex) => {
        this.form.limits[index].preOrder[preOderIndex].unlimited = true;
        this.form.limits[index].preOrder[preOderIndex].limit = null;
        this.form.limits[index].preOrder[preOderIndex].limitPeriodDate = false;
        this.form.limits[index].preOrder[preOderIndex].limitPeriod = null;
        this.form.limits[index].preOrder[preOderIndex].limitPeriodStartAt =
          null;
        this.form.limits[index].preOrder[preOderIndex].limitPeriodEndAt = null;
      });
    },
    async updateProduct(payload) {
      const id = this.form.product.id;
      const [, err] = await this.request(`/api/v1/products/${id}`, {
        method: 'patch',
        data: {
          data: payload,
        },
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.successModal.visible = true;
        this.successModal.type = 'updated';
      }
    },
  },
  created() {
    this.loadProductCategories();
    this.loadUnits();
    this.loadOfficeCategories();

    this.initPrices(true);
  },
};
</script>
