<template>
  <base-modal
    :showing="visible"
    @close="onClose"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-4xl"
    overflowable
  >
    <BaseCard
      title="Pilih atau Tambah Alamat Pengiriman"
      :description="
        isUsingNewAddress
          ? 'Buat alamat baru'
          : 'Pilih alamat yang telah ditambahkan'
      "
      :with-style="false"
    >
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full space-y-4 py-2 align-middle sm:px-2 lg:px-4"
          >
            <base-input label="Pilih Alamat Tersedia">
              <base-select-search
                fullwidth
                :is-loading="false"
                :data="getAll['addresses'].data ?? []"
                label="name"
                placeholder="Pilih Alamat Tersedia"
                v-model="search"
                v-on:change="changeSelectSearchAddress"
                :formatter="formatLabel"
              />
            </base-input>
            <div class="field-inset-default w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Provinsi
              </label>
              <select
                v-model="
                  order.relationships.destinationAddress.attributes.province
                "
                @change="handleChangeProvince"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Stok Barang"
              >
                <option :value="{ id: null, name: null }">
                  Pilih Provinsi
                </option>
                <option
                  v-for="prov in getProvinces.data"
                  :key="prov.id"
                  :value="{
                    id: prov.id,
                    name: prov.attributes.name,
                  }"
                >
                  {{ prov.attributes.name }}
                </option>
              </select>
            </div>
            <div class="field-inset-default w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Kabupaten
              </label>
              <select
                v-model="order.relationships.destinationAddress.attributes.city"
                @change="handleChangeCity"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Stok Barang"
              >
                <option
                  :value="{
                    id: null,
                    name: null,
                    area: null,
                  }"
                >
                  Pilih Kabupaten
                </option>
                <option
                  v-for="city in getCities.data"
                  :key="city.id"
                  :value="{
                    id: city.id,
                    name: city.attributes.name,
                    area: city.attributes.area_code,
                  }"
                >
                  {{ city.attributes.name }}
                </option>
              </select>
            </div>
            <div class="field-inset-default w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Kecamatan
              </label>
              <select
                v-model="
                  order.relationships.destinationAddress.attributes.district
                "
                @change="handleChangeDistrict"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Stok Barang"
              >
                <option :value="{ id: null, name: null }">
                  Pilih Kecamatan
                </option>
                <option
                  v-for="district in getDistricts.data"
                  :key="district.id"
                  :value="{
                    id: district.id,
                    name: district.attributes.name,
                  }"
                >
                  {{ district.attributes.name }}
                </option>
              </select>
            </div>
            <div class="field-inset-default w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Desa
              </label>
              <select
                v-model="
                  order.relationships.destinationAddress.attributes.village
                "
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Stok Barang"
              >
                <option :value="{ id: null, name: null }">Pilih Desa</option>
                <option
                  v-for="village in getVillages.data"
                  :key="village.id"
                  :value="{
                    id: village.id,
                    name: village.attributes.name,
                  }"
                >
                  {{ village.attributes.name }}
                </option>
              </select>
            </div>
            <div class="field-inset-default w-full">
              <label for="alamat" class="block text-xs font-bold text-gray-700">
                Alamat
              </label>
              <input
                v-model="
                  order.relationships.destinationAddress.attributes.alamat
                "
                type="text"
                name="alamat"
                id="alamat"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Alamat jalan dan nomor rumah"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center">
        <base-button fullwidth @click="handleSave" type="button">
          Gunakan Alamat
        </base-button>
        <base-button
          class="tooltip"
          color="secondary"
          @click="onCreateAddress"
          type="button"
        >
          <span class="flex items-center">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            Simpan
          </span>
          <span class="tooltiptext tooltiptext-left">
            Klik untuk menambahkan ke daftar alamat
          </span>
        </base-button>
      </div>
    </BaseCard>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';

export default {
  components: {
    BaseModal,
    BaseSelectSearch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    destinationOffice: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
  },
  emits: ['input', 'change', 'close'],
  data() {
    return {
      isUsingNewAddress: false,
      search: '',
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.loadProvinces();
        if (this.destinationOffice.id) {
          this.fetchAllRelated({
            schema: 'offices',
            id: this.destinationOffice.id,
            related: 'addresses',
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getAll: 'main/getAll',
    }),
    order: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchAll: 'main/fetchAll',
      createOne: 'main/createOne',
      fetchAllRelated: 'main/fetchAllRelated',
    }),
    changeSelectSearchAddress() {
      if (!this.search.attributes) {
        throw Error('Alamat tidak ditemukan');
      }
      const {
        city_id,
        city_name,
        district_id,
        district_name,
        province_id,
        province_name,
        village_id,
        village_name,
        detail,
        area_id,
      } = this.search.attributes;
      this.order.relationships.destinationAddress.attributes.province = {
        id: province_id,
        name: province_name,
      };
      this.order.relationships.destinationAddress.attributes.city = {
        id: city_id + '',
        name: city_name,
        area: parseInt(area_id),
      };
      console.log(this.order.relationships.destinationAddress.attributes.city);
      this.order.relationships.destinationAddress.attributes.district = {
        id: district_id,
        name: district_name,
      };
      this.order.relationships.destinationAddress.attributes.village = {
        id: village_id,
        name: village_name,
      };

      this.order.relationships.destinationAddress.attributes.alamat = detail;
      this.loadCities();
      this.loadDistricts();
      this.loadVillages();
    },
    formatLabel(address) {
      const { detail, village_name, district_name, city_name, province_name } =
        address.attributes;
      return `${detail ? detail + ',' : ''} ${
        village_name ? village_name + ',' : ''
      } ${district_name ? district_name + ',' : ''} ${
        city_name ? city_name + ',' : ''
      } ${province_name ? province_name : ''}`;
    },
    onCreateAddress() {
      this.createOne({
        schema: 'addresses',
        payload: {
          data: {
            type: 'addresses',
            attributes: {
              detail:
                this.order.relationships.destinationAddress.attributes.alamat,
            },
            relationships: {
              office: {
                data: {
                  type: 'offices',
                  id: this.destinationOffice.id,
                },
              },
              province: {
                data: {
                  type: 'provinces',
                  id:
                    this.order.relationships.destinationAddress.attributes
                      .province.id + '',
                },
              },
              city: {
                data: {
                  type: 'cities',
                  id: this.order.relationships.destinationAddress.attributes
                    .city.id,
                },
              },
              district: {
                data: {
                  type: 'districts',
                  id: this.order.relationships.destinationAddress.attributes
                    .district.id,
                },
              },
              village: {
                data: {
                  type: 'villages',
                  id: this.order.relationships.destinationAddress.attributes
                    .village.id,
                },
              },
            },
          },
        },
      });
    },
    handleChangeProvince() {
      this.loadCities();
      this.clearCity();
      this.clearDistrict();
      this.clearVillage();
    },
    handleChangeCity() {
      this.loadDistricts();
      this.clearDistrict();
      this.clearVillage();
    },
    handleChangeDistrict() {
      this.loadVillages();
      this.clearVillage();
    },
    loadVillages() {
      this.fetchVillages({
        district_id:
          this.order.relationships.destinationAddress.attributes.district.id,
      });
    },
    loadDistricts() {
      this.fetchDistricts({
        city_id: this.order.relationships.destinationAddress.attributes.city.id,
      });
    },
    loadCities() {
      this.fetchCities({
        province_id:
          this.order.relationships.destinationAddress.attributes.province.id,
      });
    },
    clearCity() {
      this.order.relationships.destinationAddress.attributes.city = {
        id: null,
        name: null,
        area: null,
      };
    },
    clearDistrict() {
      this.order.relationships.destinationAddress.attributes.district = {
        id: null,
        name: null,
      };
    },
    clearVillage() {
      this.order.relationships.destinationAddress.attributes.village = {
        id: null,
        name: null,
      };
    },
    handleSave() {
      this.order.isCustomAddress = true;

      this.$emit('change');
      this.$emit('close');
    },
    loadProvinces() {
      this.fetchProvinces({
        pageNumber: 1,
      });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
