<template>
  <div class="grid grid-cols-1 gap-4 lg:grid-cols-6">
    <BaseCard>
      <dt class="text-sm text-gray-700">Total Cash Bill</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ (getOrders.meta.total_price ?? 0) | toCurrency }}
      </dd>
    </BaseCard>
    <BaseCard>
      <dt class="text-sm text-gray-700">Total PV</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ (getOrders.meta.total_pv ?? 0) | toCurrency }}
      </dd>
    </BaseCard>

    <BaseCard>
      <dt class="text-sm text-gray-700">Total BV</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ (getOrders.meta.total_bv ?? 0) | toCurrency }}
      </dd>
    </BaseCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseCard from '@/components/base/BaseCard.vue';
export default {
  name: 'CashbillStats',
  components: {
    BaseCard,
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
  },
};
</script>
