<template>
  <div
    slot="pdf-content"
    class="pdf-content mb-4 w-full space-y-2 px-6 py-2 text-sm"
  >
    <div class="flex justify-between">
      <div>
        <p>PT. NATURAL NUSANTARA</p>
        <p>Jl. Ring Road Barat No. 72 Salakan Trihanggo</p>
        <p>Gamping Sleman Jogjakarta 55291</p>
        <p>Telp:0274-6499191(Hunting) Fax:0274-6499192/6499193</p>
        <p>NPWP : 02.264.651.7.542.000</p>
      </div>
      <div class="">
        <div class="grid grid-cols-5 gap-x-4">
          <p class="col-span-2">No. Invoice</p>
          <p>:</p>
          <p class="col-span-2 font-bold">
            {{ order.attributes.origin_code }}/{{
              order.attributes.destination_code
            }}
          </p>
        </div>
        <div class="grid grid-cols-5 gap-x-4">
          <p class="col-span-2">Tanggal</p>
          <p>:</p>
          <p class="col-span-2">
            {{ order.attributes.createdAt | formatDate('DD/MM/YYYY') }}
          </p>
        </div>
        <div class="grid grid-cols-5 gap-x-4">
          <p class="col-span-2">Waktu</p>
          <p>:</p>
          <p class="col-span-2">
            {{ order.attributes.createdAt | formatDate('HH:mm:ss') }}
          </p>
        </div>
        <div class="grid grid-cols-5 gap-x-4">
          <p class="col-span-2">Wilayah</p>
          <p>:</p>
          <p class="col-span-2">{{ order.attributes.area_name }}</p>
        </div>
      </div>
    </div>

    <div>
      <p>Kepada Yth,</p>
      <div class="flex gap-4">
        <p>Bpk/Ibu</p>
        <div>
          <p class="font-bold">
            {{ destinationOffice.attributes.name }} (
            {{ destinationOffice.attributes.code }} )
          </p>
          <p>{{ destinationOffice.attributes.address }}</p>
          <p>
            {{ destinationOffice.attributes.cellphhone }} Telp
            {{ destinationOffice.attributes.phone }}
          </p>
        </div>
      </div>
    </div>

    <table class="w-full">
      <template
        v-for="(orderDetail, i) in orderDetailsPerType"
      >
        <tr :key="`${i}-title`">
          <td class="pt-0 m-0 pb-4" colspan="7">{{ orderDetail.name }}</td>
        </tr>
        <tr :key="`${i}-heading`" class="border-t-2 border-dashed border-gray-700">
          <td
            v-for="(column, i) in orderDetail.columns"
            :key="column.id"
            :class="[
              'pt-0 pb-4 font-normal whitespace-nowrap',
              column.currency ? 'text-right' : 'text-left',
              i !== orderDetail.columns.length - 1 ? 'pr-4' : '' 
            ]"
            :colspan="column.span"
          >
            {{ column.name }}
          </td>
        </tr>
        <tr
          v-for="(item, dataI) in orderDetail.data"
          :key="`${i}-${dataI}-data`"
          class="border-t-2 border-dashed border-gray-700"
        >
          <td
            v-for="(column, i) in orderDetail.columns"
            :key="column.id"
            :class="[
              'pt-0 pb-4',
              column.currency ? 'text-right' : 'text-left',
              i !== orderDetail.columns.length - 1 ? 'pr-4' : '' 
            ]"
            :colspan="column.span"
          >
            <span v-if="column.currency">{{
              item[column.id] | toCurrency
            }}</span>
            <span v-else>{{ item[column.id] }}</span>
          </td>
        </tr>
        <tr :key="`${i}-total`" class="border-t-2 border-dashed border-gray-700">
          <td class="text-right m-0 pr-4" :colspan="orderDetail.summarySpan">{{ orderDetail.summaryLabel }}</td>
          <td v-for="(column, i) in orderDetail.summaries" :key="i" :colspan="column.span" :class="['text-right m-0', column.end ? '' : 'pr-4']">
            {{ sum(orderDetail.data, column.id) | toCurrency }}
          </td>
        </tr>
      </template>
    </table>

    <div class="grid grid-cols-5 items-end">
      <div class="col-span-2">
        <p>Catatan :</p>
        <p>Kode Gudang : {{ order.attributes.origin_warehouse_code }}</p>
      </div>

      <div></div>

      <div class="col-span-2 grid grid-cols-12 gap-x-4">
        <p class="col-span-7 whitespace-nowrap font-bold">Total Pembelian</p>
        <p class="font-bold">:</p>
        <p class="col-span-4 text-right font-bold">
          {{ order.attributes.grand_total_price | toCurrency }}
        </p>

        <template v-if="order.attributes.shipping_fee">
          <p class="col-span-7 whitespace-nowrap">Beban Pengiriman</p>
          <p class="font-bold">:</p>
          <p class="col-span-4 text-right">
            {{ order.attributes.shipping_fee | toCurrency }}
          </p>
        </template>

        <div
          class="col-span-full mt-4 border-t border-dashed border-gray-700 pb-0"
        ></div>

        <p class="col-span-7 whitespace-nowrap">Dasar Pengenaan Pajak</p>
        <p>:</p>
        <p class="col-span-4 text-right">{{ dpp | toCurrency }}</p>

        <p class="col-span-7 whitespace-nowrap">PPN 11%</p>
        <p>:</p>
        <p class="col-span-4 text-right">{{ ppn | toCurrency }}</p>
      </div>
    </div>

    <div class="flex justify-end pt-24">
      <div>
        <p class="m-0 border-t border-dashed border-gray-700 p-0">
          Bag. Penjualan
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
    destinationOffice: Object,
    orderDetails: Array,
    orderDetailBonuses: Array
  },
  computed: {
    dpp() {
      return (100 / 111) * this.order.attributes.grand_total_base_price;
    },
    ppn() {
      return (11 / 100) * this.dpp;
    },
    orderDetailsPerType() {
      return [
        {
          name: 'BARANG BERPOINT',
          summaryLabel: 'Total Berpoin',
          summarySpan: 3,
          summaries: [
            {
              id: 'total_bv',
            },
            {
              id: 'total_price',
              end: true,
              span: 3
            }
          ],
          columns: [
            {
              id: 'product_code',
              name: 'Kode',
              currency: false,
            },
            {
              id: 'product_name',
              name: 'Nama Barang',
              currency: false,
            },
            {
              id: 'qty',
              name: 'Jumlah',
              currency: true,
            },
            {
              id: 'bv',
              name: 'Total BV',
              currency: true,
            },
            {
              id: 'product_price',
              name: 'Harga Stc',
              currency: true,
            },
            {
              id: 'discount',
              name: 'Diskon',
              currency: true,
            },
            {
              id: 'total_price',
              name: 'Total Harga',
              currency: true,
            },
          ],
          data: this.orderDetails
            .filter((orderDetail) => orderDetail.attributes.is_point)
            .map((orderDetail) => ({
              product_code: orderDetail.attributes.product_code,
              product_name: orderDetail.attributes.product_name,
              qty: orderDetail.attributes.product_qty,
              bv: parseInt(orderDetail.attributes.bonus_value),
              total_bv: parseInt(orderDetail.attributes.bonus_value) * orderDetail.attributes.product_qty,
              product_price: orderDetail.attributes.product_price,
              discount: orderDetail.attributes.product_price < orderDetail.attributes.base_product_price ? orderDetail.attributes.base_product_price - orderDetail.attributes.product_price : null,
              total_price: orderDetail.attributes.total_price,
            })),
        },
        {
          name: 'BARANG NON POINT',
          summaryLabel: 'Total Non Poin',
          summarySpan: 6,
          summaries: [
            {
              id: 'total_price',
              end: true
            }
          ],
          columns: [
            {
              id: 'product_code',
              name: 'Kode',
              currency: false,
            },
            {
              id: 'product_name',
              name: 'Nama Barang',
              currency: false,
            },
            {
              id: 'qty',
              name: 'Jumlah',
              currency: true
            },
            {
              id: 'product_price',
              name: 'Harga Stc',
              currency: true,
              span: 2
            },
            {
              id: 'discount',
              name: 'Diskon',
              currency: true,
            },
            {
              id: 'total_price',
              name: 'Total Harga',
              currency: true,
            },
          ],
          data: this.orderDetails
            .filter((orderDetail) => !orderDetail.attributes.is_point)
            .map((orderDetail) => ({
              product_code: orderDetail.attributes.product_code,
              product_name: orderDetail.attributes.product_name,
              qty: orderDetail.attributes.product_qty,
              product_price: orderDetail.attributes.product_price,
              discount: orderDetail.attributes.product_price < orderDetail.attributes.base_product_price ? orderDetail.attributes.base_product_price - orderDetail.attributes.product_price : null,
              total_price: orderDetail.attributes.total_price,
            })),
        },
        {
          name: 'Free Produk',
          summaryLabel: 'Total Free Produk',
          summarySpan: 6,
          summaries: [
            {
              id: 'total_price',
              end: true
            }
          ],
          columns: [
            {
              id: 'product_code',
              name: 'Kode',
              currency: false,
            },
            {
              id: 'product_name',
              name: 'Nama Barang',
              currency: false,
            },
            {
              id: 'qty',
              name: 'Jumlah',
              currency: true
            },
            {
              id: 'product_price',
              name: 'Harga Stc',
              currency: true,
              span: 2
            },
            {
              id: 'discount',
              name: 'Diskon',
              currency: true,
            },
            {
              id: 'total_price',
              name: 'Total Harga',
              currency: true,
            },
          ],
          data: this.orderDetailBonuses.map(bonus => ({
            product_code: bonus.attributes.product_code,
            product_name: bonus.attributes.product_name,
            qty: bonus.attributes.bonus_qty,
            product_price: bonus.attributes.product_price,
            discount: bonus.attributes.product_price,
            total_price: 0
          }))
        },
      ];
    },
  },
  methods: {
    sum(data, key) {
      return data.reduce((res, item) => res + item[key], 0);
    },
  },
};
</script>

<style scoped>
.pdf-content {
  font-family: 'Courier New', Courier, monospace !important;
}
</style>
