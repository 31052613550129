const LayoutDefault = () =>
  import(/* webpackChunkName: "sc-gudang" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "sc-gudang" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/dashboard/Index.vue'
  );
const Setup = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/setup/Index.vue'
  );

// Stock
const Stock = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/stock/Index.vue'
  );
const TambahStock = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/stock/Tambah.vue'
  );

// Restock
const Restock = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/restock/Index.vue'
  );
const TambahRestock = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/restock/Tambah.vue'
  );
// Penerimaan
const PenerimaanBarangDariPusat = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/penerimaan/Index.vue'
  );
// pengeluaran barang
const PengeluaranBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/barang-ke-stockist-diambil/Index.vue'
  );
const PengeluaranRestockBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/restock-barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranRestockBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/restock-barang-ke-stockist-diambil/Index.vue'
  );
const DetailPengeluaranBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/barang-ke-stockist/DetailPengiriman.vue'
  );
const PengemasanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/stockist-center/gudang/pengeluaran/barang-ke-stockist/Pengemasan.vue'
  );

// pengiriman barang
const PengirimanBarangkeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/stockist-center/gudang/pengiriman/barang-ke-stockist-dikirim/Index.vue'
  );
const PengirimanBarangkeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/stockist-center/gudang/pengiriman/barang-ke-stockist-diambil/Index.vue'
  );
const TambahPengirimanBarangkeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/stockist-center/gudang/pengiriman/barang-ke-stockist/Tambah.vue'
  );

const ReturKePusat = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/pengiriman/retur-ke-pusat/Index.vue'
  );
const TambahReturKePusat = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/pengiriman/retur-ke-pusat/Tambah.vue'
  );

// Susulan ke stockist
const Susulan = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/susulan/Index.vue'
  );
const TambahSusulan = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/susulan/Tambah.vue'
  );
const PengemasanSusulan = () =>
  import(
    /* webpackChunkName: "sc-gudang" */ '@/views/stockist-center/gudang/susulan/Detail.vue'
  );

let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/setup',
    component: Setup,
    meta: {
      auth: true,
      title: 'Setup | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/stock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Stock',
    },
    children: [
      {
        path: '',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stock | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-stock',
        component: TambahStock,
        meta: {
          auth: true,
          title: 'Tambah Stock | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/restock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Restock',
    },
    children: [
      {
        path: '',
        name: 'restock',
        component: Restock,
        meta: {
          auth: true,
          title: 'Restock | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-restock',
        name: 'restock.tambah',
        component: TambahRestock,
        meta: {
          auth: true,
          title: 'Tambah Restock | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Restock',
        },
      },
      {
        path: ':id',
        name: 'restock.edit',
        component: TambahRestock,
        meta: {
          auth: true,
          title: 'Edit Restock | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Edit Restock',
        },
      },
    ],
  },
  {
    path: root + '/penerimaan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari Pusat dan KP',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangDariPusat,
            meta: {
              auth: true,
              title: 'Penerimaan Barang dari Pusat dan KP | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Stockist',
        },
        children: [
          {
            path: 'barang-ke-stockist-dikirim/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengeluaran Barang ke Stockist',
            },
            children: [
              {
                path: '',
                component: PengeluaranBarangKeStockistDikirim,
                meta: {
                  auth: true,
                  title: 'Pengeluaran Barang ke Stockist Dikirim | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id/',
                component: LayoutData,
                meta: {
                  breadcrumb: 'Detail Pengeluaran Barang ke Stockist Dikirim',
                },
                children: [
                  {
                    path: '',
                    component: DetailPengeluaranBarangKeStockist,
                    name: 'gudang.pengeluaran.barang-ke-stockist-dikirim.detail',
                    meta: {
                      auth: true,
                      title: 'Detail Pengeluaran Barang ke Stockist Dikirim | Gudang',
                      layout: LayoutDefault,
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
                    }
                  },
                  {
                    path: 'pengemasan',
                    component: PengemasanBarangKeStockist,
                    meta: {
                      auth: true,
                      title: 'Pengemasan Barang | Gudang',
                      layout: LayoutDefault,
                      breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
                    }
                  }
                ],
              },
            ],
          },
          {
            path: 'barang-ke-stockist-diambil/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengeluaran Barang ke Stockist',
            },
            children: [
              {
                path: '',
                component: PengeluaranBarangKeStockistDiambil,
                meta: {
                  auth: true,
                  title: 'Pengeluaran Barang ke Stockist Diambil | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id/',
                component: LayoutData,
                meta: {
                  breadcrumb: 'Detail Pengeluaran Barang ke Stockist Diambil',
                },
                children: [
                  {
                    path: '',
                    component: DetailPengeluaranBarangKeStockist,
                    name: 'gudang.pengeluaran.barang-ke-stockist-diambil.detail',
                    meta: {
                      auth: true,
                      title: 'Detail Pengeluaran Barang ke Stockist Diambil | Gudang',
                      layout: LayoutDefault,
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
                    }
                  },
                  {
                    path: 'pengemasan',
                    component: PengemasanBarangKeStockist,
                    meta: {
                      auth: true,
                      title: 'Pengemasan Barang | Gudang',
                      layout: LayoutDefault,
                      breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
                    }
                  },
                ],
              },
            ],
          },
          {
            path: 'restock-barang-ke-stockist-dikirim/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
            },
            children: [
              {
                path: '',
                component: PengeluaranRestockBarangKeStockistDikirim,
                meta: {
                  auth: true,
                  title: 'Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id/',
                component: LayoutData,
                meta: {
                  breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim',
                },
                children: [
                  {
                    path: '',
                    component: DetailPengeluaranBarangKeStockist,
                    name: 'gudang.pengeluaran.restock-barang-ke-stockist-dikirim.detail',
                    meta: {
                      auth: true,
                      title: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
                      layout: LayoutDefault,
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
                    }
                  },
                  {
                    path: 'pengemasan',
                    component: PengemasanBarangKeStockist,
                    meta: {
                      auth: true,
                      title: 'Pengemasan Barang | Gudang',
                      layout: LayoutDefault,
                      breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
                    }
                  }
                ],
              },
            ],
          },
          {
            path: 'restock-barang-ke-stockist-diambil/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
            },
            children: [
              {
                path: '',
                component: PengeluaranRestockBarangKeStockistDiambil,
                meta: {
                  auth: true,
                  title: 'Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id/',
                component: LayoutData,
                meta: {
                  breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Diambil',
                },
                children: [
                  {
                    path: '',
                    component: DetailPengeluaranBarangKeStockist,
                    name: 'gudang.pengeluaran.restock-barang-ke-stockist-diambil.detail',
                    meta: {
                      auth: true,
                      title: 'Detail Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
                      layout: LayoutDefault,
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
                    }
                  },
                  {
                    path: 'pengemasan',
                    component: PengemasanBarangKeStockist,
                    meta: {
                      auth: true,
                      title: 'Pengemasan Barang | Gudang',
                      layout: LayoutDefault,
                      breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
                    },
                    props: {
                      indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
                    }
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengiriman/',
    component: LayoutData,
    children: [
      {
        path: 'barang-ke-stockist-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke Stockist Dikirim',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeStockistDikirim,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeStockist,
            name: 'gudang.pengiriman.barang-ke-stockist-dikirim.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-stockist-dikirim'
            }
          },
        ],
      },
      {
        path: 'barang-ke-stockist-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke Stockist Diambil',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeStockistDiambil,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeStockist,
            name: 'gudang.pengiriman.barang-ke-stockist-diambil.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-stockist-diambil'
            },
          }
        ],
      },
      {
        path: 'retur-ke-pusat',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Retur ke Pusat',
        },
        children: [
          {
            path: '',
            component: ReturKePusat,
            meta: {
              auth: true,
              title: 'Pengiriman Retur Barang ke Pusat | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahReturKePusat,
            meta: {
              auth: true,
              title: 'Pengiriman Retur Barang ke Pusat | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/susulan/sc',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Susulan ke Stockist',
    },
    children: [
      {
        path: '',
        component: Susulan,
        meta: {
          auth: true,
          title: 'Pengiriman Susulan ke Stockist | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahSusulan,
        meta: {
          auth: true,
          title: 'Tambah Pengiriman Susulan ke Stockist | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Pengiriman Susulan ke Stockist',
        },
      },
      {
        path: ':id/pengemasan',
        component: PengemasanSusulan,
        meta: {
          auth: true,
          title: 'Pengemasan Susulan ke Stockist | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Pengemasan Susulan ke Stockist',
        },
      },
    ],
  },
];
