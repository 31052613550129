<template>
  <div>
    <base-wrapper class="space-y-6">
      <create-order-form
        :valid="order.valid"
        with-destination-area
        use-origin-office-warehouse
        force-shipment-type
        force-destination
        :with-restock-type="false"
        :with-loan="false"
        v-model="order"
      />
      <create-order-detail-form
        v-if="canCreateOrderDetail"
        :disabled="order.valid"
        :order-id="order.id"
        :origin-office="{
          id: order.originOffice.id,
          code: order.originOffice.code,
        }"
        :destination-office="{
          id: order.destinationOffice.id,
          code: order.destinationOffice.code,
        }"
        :origin-warehouse="order.originOffice.currentWarehouse"
        :buyer-type="{
          id: order.buyerType.id,
          code: order.buyerType.code,
        }"
        :area="{
          id: order.area.id,
          code: order.area.code,
        }"
        :create-order-attributes="{
          is_loan: order.loan,
          is_using_stock: order.using_stock,
          destination_warehouse_id: order.destinationOffice.currentWarehouse.id,
          order_type: 'purchase',
          order_shipment: order.shipment_type,
          ...(order.custom_shipment_address
            ? {
                destination_address: {
                  province_id: order.shipmentAddress.province.id,
                  city_id: order.shipmentAddress.city.id,
                  district_id: order.shipmentAddress.district.id,
                  village_id: order.shipmentAddress.village.id,
                  address: order.shipmentAddress.address,
                },
              }
            : null),
        }"
        :min-spend="minOrderSpend"
        v-model="orderDetails"
        @order-created="onOrderCreated"
      />
      <div class="flex justify-end gap-x-2">
        <base-button
          v-if="!order.valid"
          :disabled="!canValidate"
          @click="onValidate"
          >Validasi</base-button
        >
        <base-button :to="{ name: 'restock' }" color="white"
          >Kembali</base-button
        >
      </div>
    </base-wrapper>
    <view-order-modal
      :visible="viewOrderModalVisible"
      :order-id="order.id"
      :with-payment="false"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      @validated="$router.push({ name: 'restock' })"
      @close="$router.push({ name: 'restock' })"
    />
    <validate-order-confirmation
      :order-id="order.id"
      :visible="confirmValidateOrderVisible"
      @close="confirmValidateOrderVisible = false"
      @confirmed="onValidateConfirmed"
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';

export default {
  mixins: [requestMixin],
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    ViewOrderModal,
    ValidateOrderConfirmation,
  },
  data() {
    return {
      confirmValidateOrderVisible: false,
      loadingBuyerType: false,
      loadingOriginOffice: false,
      loadingDestinationOffice: false,
      loadingOrder: false,
      loadingSetup: false,
      minOrderSpend: 0,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        date: new Date(),
        destinationOffice: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          address: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          area: {
            id: null,
            code: null,
          },
        },
        originOffice: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          area: {
            id: null,
            code: null,
          },
        },
        originOfficeType: 'pusat',
        destinationOfficeType: 'kantor_pelayanan',
        loan: false,
        originWarehouse: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          area: {
            id: null,
            code: null,
          },
        },
        shipment_type: 'delivery',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
        custom_shipment_address: false,
        using_stock: true,
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        },
      ],
      viewOrderModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    canCreateOrderDetail() {
      return this.order.destinationOffice.id;
    },
    canValidate() {
      return this.totalPrice > this.minOrderSpend;
    },
    loading() {
      return (
        this.loadingBuyerType ||
        this.loadingSetup ||
        this.loadingOrder ||
        this.loadingOriginOffice ||
        this.loadingDestinationOffice
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, error] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 10,
          'fields[buyer-types]': 'code',
        },
      });

      if (!error) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadDestinationOffice() {
      this.loadingDestinationOffice = true;

      const [res, error] = await this.request(
        `/api/v1/offices/${this.me.office_id}`,
        {
          params: {
            include: 'current-warehouse,area',
            'fields[offices]': 'code,name,address,current-warehouse,area',
            'fields[warehouses]': 'code',
            'fields[areas]': 'code',
          },
        }
      );

      if (!error) {
        const office = res.data;

        this.order.destinationOffice.id = office.id;
        this.order.destinationOffice.code = office.attributes.code;
        this.order.destinationOffice.searchCode = office.attributes.code;
        this.order.destinationOffice.originalCode = office.attributes.code;
        this.order.destinationOffice.name = office.attributes.name;
        this.order.destinationOffice.address = office.attributes.address;
        const currentWarehouse = this.getSingleIncluded(
          res,
          office.relationships['current-warehouse'].data.id
        );

        this.order.destinationOffice.currentWarehouse.id = currentWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          currentWarehouse.attributes.code;

        const area = this.getSingleIncluded(
          res,
          office.relationships['area'].data.id
        );

        this.order.destinationOffice.area.id = area.id;
        this.order.destinationOffice.area.code = area.attributes.code;
      }

      this.loadingDestinationOffice = false;
    },
    async loadOriginOffice() {
      this.loadingOriginOffice = true;

      const [res, error] = await this.request(`/api/v1/offices`, {
        params: {
          'filter[office_category_id]': 1,
          'filter[code]': 'pusat',
          include: 'office,office.current-warehouse,office.area',
          'fields[simple-offices]': 'code,office',
          'fields[offices]': 'code,name,current-warehouse,area',
          'fields[warehouses]': 'code',
          'fields[areas]': 'code',
        },
      });

      if (!error) {
        const office = this.getSingleIncluded(
          res,
          res.data[0].relationships.office.data.id
        );

        this.order.originOffice.id = office.id;
        this.order.originOffice.code = office.attributes.code;
        this.order.originOffice.originalCode = office.attributes.code;
        this.order.originOffice.searchCode = office.attributes.code;
        this.order.originOffice.name = office.attributes.name;

        const currentWarehouse = this.getSingleIncluded(
          res,
          office.relationships['current-warehouse'].data.id
        );

        this.order.originOffice.currentWarehouse.id = currentWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          currentWarehouse.attributes.code;

        const area = this.getSingleIncluded(
          res,
          office.relationships['area'].data.id
        );

        this.order.originOffice.area.id = area.id;
        this.order.originOffice.area.code = area.attributes.code;
      }

      this.loadingOriginOffice = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      this.order.id = this.$route.params.id;

      const [res, error] = await this.request(
        `/api/v1/orders/${this.order.id}`,
        {
          params: {
            include:
              'area,buyer-type,origin-office,destination-office,destination-warehouse,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,order_shipment,is_using_stock,is_loan,shipment_address,is_completed,is_valid_for_packing,area,buyer-type,origin-office,destination-office,destination-warehouse,origin-warehouse,order-details',
            'fields[areas]': 'code',
            'fields[buyer-types]': 'code',
            'fields[offices]': 'code,name,area_code,address',
            'fields[warehouses]': 'code,name,area_code',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price',
          },
        }
      );

      if (!error && res.data) {
        const order = res;

        const area = this.getSingleIncluded(
          res,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          res,
          order.data.relationships['buyer-type'].data.id
        );
        const originOffice = this.getSingleIncluded(
          res,
          order.data.relationships['origin-office'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          res,
          order.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          res,
          order.data.relationships['destination-warehouse'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          res,
          order.data.relationships['origin-warehouse'].data.id
        );
        const orderDetails = this.getIncludedByType(res, 'order-details');

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        (this.order.date = order.data.attributes.createdAt),
          (this.order.destinationOffice.id = destinationOffice.id);
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode = `${destinationOffice.attributes.code} (${destinationOffice.attributes.area_code})`;
        this.order.destinationOffice.originalCode =
          this.order.destinationOffice.searchCode;
        this.order.destinationOffice.name = destinationOffice.attributes.name;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        this.order.destinationOffice.area.code =
          destinationOffice.attributes.area_code;

        this.order.originOffice.id = originOffice.id;
        this.order.originOffice.code = originOffice.attributes.code;
        this.order.originOffice.originalCode = originOffice.attributes.code;
        this.order.originOffice.searchCode = originOffice.attributes.code;
        this.order.originOffice.name = originOffice.attributes.name;

        this.order.originOffice.currentWarehouse.id = originWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          originWarehouse.attributes.code;

        this.order.originOffice.area.code =
          originWarehouse.attributes.area_code;

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.shipment_type = order.data.attributes.order_shipment;
        this.order.using_stock = order.data.attributes.is_using_stock;
        this.order.loan = order.data.attributes.is_loan;

        this.order.shipmentAddress.address =
          order.data.attributes.shipment_address ||
          destinationOffice.attributes.address;

        this.order.valid =
          order.data.attributes.is_completed ||
          order.data.attributes.is_valid_for_packing;

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
        }));

        if (!this.order.valid) {
          this.orderDetails.push({
            id: null,
            productCode: null,
            originalProductCode: null,
            productName: null,
            stock: null,
            qty: null,
            originalQty: null,
            price: null,
            totalPrice: null,
          });
        }
      }

      this.loadingOrder = false;
    },
    async loadSetup() {
      this.loadingSetup = true;

      const [res, err] = await this.request('/api/v1/setups', {
        params: {
          'filter[setup_key]': 'min_order_spend_for_kp',
          'fields[setups]': 'setup_value',
        },
      });

      if (!err && res.data.length) {
        this.minOrderSpend = +res.data[0].attributes.setup_value;
      }

      this.loadingSetup = false;
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.$router.push({
        name: 'restock.edit',
        params: {
          id: order.data.id,
        },
      });
    },
    async onValidate() {
      this.confirmValidateOrderVisible = true;
    },
    onValidateConfirmed() {
      this.confirmValidateOrderVisible = false;
      this.viewOrderModalVisible = true;
    },
    setArea() {
      if (
        this.order.originOffice.area.code >
        this.order.destinationOffice.area.code
      ) {
        this.order.area.id = this.order.originOffice.area.id;
        this.order.area.code = this.order.originOffice.area.code;
      } else {
        this.order.area.id = this.order.destinationOffice.area.id;
        this.order.area.code = this.order.destinationOffice.area.code;
      }
    },
  },
  async created() {
    this.loadSetup();

    if (this.$route.params.id) {
      this.loadOrder();
    } else {
      this.loadBuyerType();

      await Promise.all([
        this.loadDestinationOffice(),
        this.loadOriginOffice(),
      ]);

      this.setArea();
    }
  },
};
</script>
