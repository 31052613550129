<template>
  <datatable
    :total="data.meta.page.total"
    :perPage="data.meta.page.perPage"
    :currentPage="data.meta.page.currentPage"
    @pagechanged="onPageChange"
    :columns="[
      { id: 'date', name: 'Tanggal' },
      { id: 'description', name: 'Ketarangan' },
      { id: 'in', name: 'Masuk', theadClass: 'text-right' },
      { id: 'out', name: 'Keluar', theadClass: 'text-right' },
      { id: 'stock', name: 'Saldo Stock', theadClass: 'text-right' },
    ]"
  >
      <template #tbody="{ classes }">
        <tr v-for="stockHistory in data.data" :key="stockHistory.id" :class="classes.tr">
          <td :class="classes.td">{{ stockHistory.attributes.createdAt | formatDate }}</td>
          <td :class="classes.td">{{ stockHistory.attributes.description ?? '-' }}</td>
          <td :class="[classes.td, 'text-right']">{{ (stockHistory.attributes.type === 'in' ? Math.abs(stockHistory.attributes.amount) : 0) | toCurrency }}</td>
          <td :class="[classes.td, 'text-right']">{{ (stockHistory.attributes.type === 'out' ? Math.abs(stockHistory.attributes.amount) : 0) | toCurrency }}</td>
          <td :class="[classes.td, 'text-right']">{{ stockHistory.attributes.current_stock | toCurrency }}</td>
        </tr>
      </template>
  </datatable>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        data: [],
        meta: {
          page: {}
        }
      })
    }
  },
  emits: ['change-page'],
  methods: {
    onPageChange(page) {
      this.$emit('change-page', page)
    }
  }
}
</script>