<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card title="Informasi Supplier">
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input
            inset
            label="Kode PO"
            placeholder="Kode PO"
            disabled
            v-model="order.data.attributes.origin_code"
          />
          <base-input
            inset
            label="Tanggal"
            placeholder="Tanggal"
            disabled
            v-model="order.data.attributes.updatedAt"
          />
          <base-input
            inset
            label="Kode Supplier"
            placeholder="Kode Supplier"
            disabled
          >
            <p class="mt-1 text-sm">
              {{
                order.data.relationships['origin-office'].data
                  ? getSingleIncluded(
                      order,
                      order.data.relationships['origin-office'].data.id
                    ).attributes.code
                  : '-'
              }}
            </p>
          </base-input>
          <base-input
            inset
            label="Nama Supplier"
            placeholder="Nama Supplier"
            disabled
          >
            <p class="mt-1 text-sm">
              {{
                order.data.relationships['origin-office'].data
                  ? getSingleIncluded(
                      order,
                      order.data.relationships['origin-office'].data.id
                    ).attributes.name
                  : '-'
              }}
            </p>
          </base-input>
        </div>
      </base-card>

      <base-card
        title="Informasi Barang Keluar"
        description="Masukkan detail barang keluar. Klik pada baris untuk menambahkan batch"
      >
        <div class="border-t pt-5">
          <datatable :columns="tableColumns">
            <template #tbody="{ classes }">
              <template v-for="(item, index) in shipmentForm">
                <tr :key="index" :class="classes.tr">
                  <td :class="[classes.td, 'font-bold text-gray-900']">
                    {{ item.productCode }}
                    <span v-if="item.productType === 'free'">(Promo)</span>
                  </td>
                  <td :class="classes.td">{{ item.productName }}</td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      v-model="shipmentForm[index].qty"
                    />
                  </td>
                  <td :class="classes.td">
                    {{ item.productPrice | toCurrency }}
                  </td>
                  <td :class="classes.td">
                    {{ item.totalPrice | toCurrency }}
                  </td>
                  <td :class="classes.td">
                    <Icon
                      icon="heroicons:x-mark-20-solid"
                      class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    />
                  </td>
                </tr>

                <tr
                  v-for="(batch, batchIndex) in item.batches"
                  :key="`batch-${index}-${batchIndex}`"
                  :class="[classes.tr, 'bg-gray-50']"
                >
                  <td :class="classes.td">
                    <button
                      v-if="batchIndex === item.batches.length - 1"
                      class="flex w-full justify-center"
                      @click="onAddBatch(index)"
                    >
                      <Icon
                        class="mx-auto h-6 w-6 text-green-600 hover:text-green-500"
                        icon="heroicons:plus-circle-20-solid"
                      />
                    </button>
                  </td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan No. Batch"
                      v-model="shipmentForm[index].batches[batchIndex].no"
                    />
                  </td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      v-model="shipmentForm[index].batches[batchIndex].qty"
                    />
                  </td>
                  <td :class="classes.td">
                    <input
                      type="date"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      v-model="shipmentForm[index].batches[batchIndex].expire"
                    />
                  </td>
                  <td></td>
                  <td :class="classes.td">
                    <button
                      v-if="item.batches.length > 1"
                      class="flex w-full justify-center"
                      @click="onDeleteBatch(index, batchIndex)"
                    >
                      <Icon
                        icon="heroicons:x-mark-20-solid"
                        class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                      />
                    </button>
                  </td>
                </tr>
              </template>
            </template>
          </datatable>
        </div>
      </base-card>

      <div class="flex justify-end gap-2">
        <base-button :disabled="!validForSubmit" @click="onSubmit"
          >Simpan</base-button
        >
        <base-button
          color="white"
          :to="{ name: 'gudang.susulan.barang-dari.supplier' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <view-stock-movement-modal
      with-first-packet-product
      with-batch
      :visible="detailModal.visible"
      :stock-movement-id="detailModal.stockMovementId"
      @deleted="$router.push({ name: 'gudang.susulan.barang-dari.supplier' })"
      @validated="$router.push({ name: 'gudang.susulan.barang-dari.supplier' })"
      @close="$router.push({ name: 'gudang.susulan.barang-dari.supplier' })"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewStockMovementModal from '@/components/stock-movement/view-stock-movement-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewStockMovementModal },
  data() {
    return {
      detailModal: {
        visible: false,
        stockMovementId: null,
      },
      loadingOrder: false,
      loadingSubmit: false,
      order: {
        data: {
          attributes: {},
          relationships: {
            'origin-office': {
              data: null,
            },
          },
        },
        included: [],
      },
      shipmentForm: [],
    };
  },
  computed: {
    loading() {
      return this.loadingOrder || this.loadingSubmit;
    },
    tableColumns() {
      return [
        { id: 'code', name: 'Kode Barang' },
        { id: 'name', name: 'Nama Barang / Batch' },
        { id: 'qty', name: 'Jumlah' },
        { id: 'price', name: 'Harga / Tgl Kadaluarsa' },
        { id: 'total_price', name: 'Total Harga' },
        { id: 'action', name: 'Aksi', theadClass: 'text-center' },
      ];
    },
    validForSubmit() {
      return this.shipmentForm.every((item) => {
        if (parseInt(item.qty) > item.productQty) {
          return false;
        }

        const allBatchValid = item.batches.every((batch) => {
          return !!batch.no && !!batch.qty && !!batch.expire;
        });

        if (!allBatchValid) {
          return false;
        }

        return (
          item.batches.reduce(
            (total, batch) => total + parseInt(batch.qty),
            0
          ) === parseInt(item.qty)
        );
      });
    },
  },
  methods: {
    async loadOrder() {
      this.loadingOrder = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include: 'origin-office,order-details',
            'fields[simple-orders]':
              'origin_code,updatedAt,origin-office,order-details',
            'fields[offices]': 'code,name',
            'fields[order-details]':
              'product_code,product_name,product_qty,product_price,total_price,product_type,product_received',
          },
        }
      );

      if (!err) {
        this.order = res;

        this.setShipmentForm();
      }

      this.loadingOrder = false;
    },
    onAddBatch(index) {
      this.shipmentForm[index].batches.push({
        no: null,
        qty: null,
        expire: null,
      });
    },
    onDeleteBatch(index, batchIndex) {
      this.shipmentForm[index].batches.splice(batchIndex, 1);
    },
    async onSubmit() {
      this.loadingSubmit = true;

      const payload = {
        order_id: this.order.data.id,
        packets: this.shipmentForm.map((item) => ({
          order_detail_id: item.orderDetailId,
          qty: item.qty,
          batches: item.batches,
        })),
      };

      const [res, err] = await this.request(
        '/api/v1/stock-movements/-actions/create-supplier-shipment',
        {
          method: 'post',
          data: payload,
        }
      );

      if (!err) {
        this.detailModal.stockMovementId = res.data.id;
        this.detailModal.visible = true;
      }

      this.loadingSubmit = false;
    },
    setShipmentForm() {
      const orderDetails = this.getIncludedByType(this.order, 'order-details');

      this.shipmentForm = orderDetails
        .filter(orderDetail => orderDetail.attributes.product_qty - orderDetail.attributes.product_received)
        .map((orderDetail) => ({
          orderDetailId: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          productType: orderDetail.attributes.product_type,
          productName: orderDetail.attributes.product_name,
          productQty: orderDetail.attributes.product_qty - orderDetail.attributes.product_received,
          qty: orderDetail.attributes.product_qty - orderDetail.attributes.product_received,
          productPrice: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
          batches: [
            {
              no: null,
              qty: null,
              expire: null,
            },
          ],
        }));
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>
