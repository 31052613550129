<template>
  <div>
    <slot :cashback="cashback" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Number,
    officeType: {
      type: String,
      required: true,
    },
    areaCode: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  emits: ['input'],
  computed: {
    ...mapGetters({
      officeCategories: 'office_categories/getAvailableOfficeCategories',
    }),
    cashback: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    totalPrice: {
      immediate: true,
      handler() {
        this.setCashback();
      },
    },
  },
  methods: {
    setCashback() {
      const officeCategoryName = {
        pusat: 'Pusat',
        kantor_pelayanan: 'Kantor Pelayanan',
      }[this.officeType];
      const officeCategory = this.officeCategories.find(
        (officeCategory) =>
          officeCategory.attributes.name === officeCategoryName
      );
      const minOrder =
        officeCategory.attributes[
          this.areaCode === 0 ? 'min_order_jawa' : 'min_order_luar_jawa'
        ];
      const cashback =
        officeCategory.attributes[
          this.areaCode === 0 ? 'cashback_jawa' : 'cashback_luar_jawa'
        ];

      const cashbackMultiplier = Math.floor(this.totalPrice / minOrder);

      this.cashback = cashbackMultiplier * cashback;
    },
  },
};
</script>
