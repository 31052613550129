var render = function render(){var _vm=this,_c=_vm._self._c;return _c('datatable',{attrs:{"total":_vm.data.meta.page.total,"perPage":_vm.data.meta.page.perPage,"currentPage":_vm.data.meta.page.currentPage,"columns":[
    { id: 'date', name: 'Tanggal' },
    { id: 'description', name: 'Ketarangan' },
    { id: 'in', name: 'Masuk', theadClass: 'text-right' },
    { id: 'out', name: 'Keluar', theadClass: 'text-right' },
    { id: 'stock', name: 'Saldo Stock', theadClass: 'text-right' },
  ]},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.data.data),function(stockHistory){return _c('tr',{key:stockHistory.id,class:classes.tr},[_c('td',{class:classes.td},[_vm._v(_vm._s(_vm._f("formatDate")(stockHistory.attributes.createdAt)))]),_c('td',{class:classes.td},[_vm._v(_vm._s(stockHistory.attributes.description ?? '-'))]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(_vm._s(_vm._f("toCurrency")((stockHistory.attributes.type === 'in' ? Math.abs(stockHistory.attributes.amount) : 0))))]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(_vm._s(_vm._f("toCurrency")((stockHistory.attributes.type === 'out' ? Math.abs(stockHistory.attributes.amount) : 0))))]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(_vm._s(_vm._f("toCurrency")(stockHistory.attributes.current_stock)))])])})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }