<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-lg"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        title="Promosi Kantor"
        :with-style="false"
        description="Lengkapi form dibawah ini untuk melakukan promosi"
      >
        <form class="space-y-4 border-t pt-5" @submit.prevent="onSubmit">
          <base-input
            fullwidth
            inset
            type="text"
            label="Kode Kantor"
            placeholder="Masukkan kode kantor (Contoh AB.001)"
            v-model="form.code"
          />
          <base-input
            fullwidth
            inset
            type="text"
            label="Email"
            placeholder="Masukkan email (Contoh ab.001@nasa.com)"
            v-model="form.email"
          />
          <div class="grid grid-cols-2 gap-2">
            <base-button fullwidth>Promosi</base-button>
            <base-button
              type="button"
              fullwidth
              color="light"
              @click="$emit('close')"
              >Tutup</base-button
            >
          </div>
        </form>
      </base-card>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: { BaseModal },
  props: {
    visible: Boolean,
    officeId: String,
  },
  emits: ['close', 'promoted'],
  data() {
    return {
      form: {
        code: null,
        email: null,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.form.code = null;
      this.form.email = null;
      this.form.no_account_gl = null;
      this.form.cost_center = null;
    },
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/offices/${this.officeId}/-actions/promoteToStockist`,
        {
          method: 'post',
          data: {
            data: {
              attributes: this.form,
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('promoted');
      }

      this.loading = false;
    },
  },
};
</script>
