<template>
  <div>
    <base-modal
      :showing="visible"
      :size="size"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        :title="`Detail ${label}`"
        :description="`Berdasarkan data ${label} yang telah dibuat`"
        :with-style="false"
      >
        <div class="border-t pt-5">
          <dl :class="['grid grid-cols-1 gap-x-4 gap-y-8 sm:pb-6', gridColumn === 2 ? 'sm:grid-cols-6' : 'sm:grid-cols-9' ]">
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Nama {{ label }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.name }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">
                <span>Kode {{ label }}</span>
                <span v-if="office.data.attributes.temp_code"> / Kode Sementara</span>
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.code }}
                <span v-if="office.data.attributes.temp_code"> / {{ office.data.attributes.temp_code }} </span>
                <span v-if="office.data.attributes.is_promoted"
                  >({{ office.data.attributes.promoted_by }})</span
                >
              </dd>
            </div>
            <div v-if="columns.includes('upline')" class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">No Upline</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <p v-if="!office.data.attributes.referred_by">-</p>
                <p v-else>{{ office.data.attributes.referred_by ?? '-' }} / {{ office.data.attributes.referred_by_name ?? '-' }}</p>
              </dd>
            </div>
            <div v-if="columns.includes('ktp_number')" class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">No. KTP</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.ktp_number ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.phone ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.email ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Provinsi</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.province_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Kabupaten</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.city_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Kecamatan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.district_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Desa</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.village_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Alamat {{ label }} 1</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.address ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Alamat {{ label }} 2</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.address2 ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ area ? area.attributes.name : '-' }} ({{
                  area ? area.attributes.code : '-'
                }})
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Diperbarui
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Suspend</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <toggle-button
                  v-model="office.data.attributes.is_suspended"
                  :labels="{
                    checked: 'Aktifkan',
                    unchecked: 'Suspend',
                  }"
                  :width="office.data.attributes.is_suspended ? 80 : 80"
                  v-on:change="onChangeSuspended"
                />
              </dd>
            </div>
            <div v-if="withUsers" class="col-span-full">
              <dt class="text-sm font-bold text-gray-700">Daftar User</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <datatable :scroll-x="false" :columns="userColumns">
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(user, index) in getIncludedByType(
                        office,
                        'users'
                      )"
                      :key="index"
                      :class="classes.tr"
                    >
                      <td :class="classes.td">{{ user.attributes.name }}</td>
                      <td :class="classes.td">{{ user.attributes.email }}</td>
                      <td :class="classes.td">
                        {{ user.attributes.current_role }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>

            <slot name="additional-attributes" :classes="{
              wrapper: 'sm:col-span-6',
              title: 'text-sm font-bold text-gray-700',
              content: 'mt-1 text-sm text-gray-900'
            }" />
          </dl>
        </div>

        <div>
          <base-button v-if="editable" color="white" @click="$router.push({ name: editRoute, params: { id: officeId } })">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            Edit
          </base-button>
        </div>
      </base-card>
    </base-modal>
    <loading v-if="loadingDetail || loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ToggleButton,
  },
  props: {
    visible: Boolean,
    officeType: {
      type: String,
      required: true,
    },
    officeId: String,
    withUsers: {
      type: Boolean,
      default: true
    },
    loading: Boolean,
    size: {
      type: String,
      default: 'max-w-5xl'
    },
    gridColumn: {
      type: Number,
      default: 2
    },
    columns: {
      type: Array,
      default: () => []
    },
    editable: Boolean,
    editRoute: String
  },
  emits: ['close', 'edit'],
  data() {
    return {
      loadingDetail: false,
      office: {
        data: {
          attributes: {},
          relationships: {
            addresses: {
              data: [],
            },
            area: {
              data: null,
            },
            users: {
              data: [],
            }
          },
        },
      },
    };
  },
  computed: {
    area() {
      if (!this.office.data.relationships.area.data) {
        return null;
      }

      return this.getSingleIncludedType('area', this.office, this.office.data);
    },
    label() {
      return {
        stockist: 'Stockist',
        stockist_center: 'Stockist Center',
        supplier: 'Supplier',
        kantor_pelayanan: 'Kantor Pelayanan',
        member: 'Mitra Usaha',
      }[this.officeType];
    },
    officeAddress() {
      if (!this.office.data.relationships.addresses.data.length) {
        return null;
      }

      return this.getIncludedByType(this.office, 'addresses')[0];
    },
    userColumns() {
      return [
        { id: 'name', name: 'Nama' },
        { id: 'email', name: 'Email' },
        { id: 'role', name: 'Role' },
      ];
    },
  },
  methods: {
    async onChangeSuspended() {
      this.loadingDetail = true;

      await this.request(`/api/v1/offices/${this.officeId}`, {
        method: 'patch',
        data: {
          data: {
            type: 'offices',
            id: this.office.data.id,
            attributes: {
              is_suspended: this.office.data.attributes.is_suspended,
            },
          },
        },
      });

      this.loadingDetail = false;
    },
    async onOpened() {
      this.loadingDetail = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.officeId}`,
        {
          params: {
            include: this.withUsers ? 'addresses,area,users' : 'addresses,area',
            'fields[offices]':
              'name,code,temp_code,is_promoted,promoted_by,phone,email,addresses,address,address2,area,createdAt,updatedAt,is_suspended,users,ktp_number,referred_by,referred_by_name',
            'fields[areas]': 'name,code',
            'fields[users]': 'name,email,current_role',
          },
        }
      );

      if (!err) {
        this.office = res;
      }

      this.loadingDetail = false;
    },
  },
};
</script>
