var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-serif",attrs:{"id":"app"}},[(!_vm.getVersionUpdated)?_c('version-alert'):_vm._e(),_c('transition',{attrs:{"enter-active-class":"fadeInRight duration-100","leave-active-class":"fadeOutRight duration-100"}},[(_vm.getAlertData)?[_c('Alert',{attrs:{"data":_vm.getAlertData,"status":_vm.getStatus}})]:_vm._e()],2),_c('ModalMenu',{attrs:{"show-menu":_vm.isMenuShown},on:{"onClose":_vm.onCloseMenu}}),_c(this.$route.meta.layout || 'div',{tag:"component"},[_c('router-view',{key:_vm.$route.fullpath})],1),_c('div',{class:[
      // define style
      'bg-yellow-400',
      'bg-blue-600',
      'bg-purple-500',
      'bg-green-600',
      'bg-red-600',
      'bg-indigo-400',

      'border-blue-600',
      'border-yellow-800',
      'border-purple-800',
      'border-green-600',
      'border-gray-600',
      'text-blue-600',
    ]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }