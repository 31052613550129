<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Role
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar Role Yang Telah Dibuat
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex justify-end gap-x-2">
            <base-input
              placeholder="Cari Nama"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadRoles"
            />
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="roles.meta.page.total"
            :perPage="roles.meta.page.perPage"
            :currentPage="roles.meta.page.currentPage"
            :meta="roles.meta"
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="role in roles.data"
                :key="role.id"
                :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                @click="onClickRole(role)"
              >
                <td :class="[classes.td]">
                  <span class="font-bold text-gray-900">
                    {{ role.attributes.name }}
                  </span>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
  },
  emits: ['close', 'click-role'],
  data() {
    return {
      roles: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        {
          id: 'name',
          name: 'Nama Role',
        },
      ];
    }
  },
  methods: {
    async loadRoles(params) {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/roles`, {
        params: {
          ...this.requestParams,
          'page[size]': 5,
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!error) {
        this.roles = res;
      }

      this.loading = false;
    },
    onClickRole(role) {
      this.$emit('click-role', role);
    },
    onOpened() {
      this.filter.search = null;

      this.loadRoles();
    },
    onPagechanged(page) {
      this.loadRoles({
        'page[number]': page,
      });
    },
  },
};
</script>
