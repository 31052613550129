<template>
  <div class="font-serif bg-gray-100 min-h-full flex flex-col justify-between">
    <Navbar />
    <slot />
    <TheFooter />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Navbar from '@/components/navbar/navbar.vue';
import TheFooter from '@/components/TheFooter';
// import { StorageService } from '@/services/storage.service';
export default {
  name: 'LayoutDefault',
  components: {
    Navbar,
    TheFooter,
  },
  created() {},
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
      getRolesByUser: 'users/getRolesByUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchMenus: 'menus/fetchMenus',
    }),
  },
};
</script>
