<template>
  <base-modal
    :showing="visible"
    @close="onClose"
    size="max-w-lg"
    @opened="onOpened"
  >
    <base-card title="Pilih Alamat Pengiriman" :with-style="false">
      <div class="space-y-4">
        <base-input inset label="Provinsi">
          <base-select
            inset
            expand
            :options="provinceOptions"
            v-model="temp.province"
            @change="onChangeProvince"
          ></base-select>
        </base-input>
        <base-input inset label="Kabupaten">
          <base-select
            inset
            expand
            :options="cityOptions"
            v-model="temp.city"
            @change="onChangeCity"
          ></base-select>
        </base-input>
        <base-input inset label="Kecamatan">
          <base-select
            inset
            expand
            :options="districtOptions"
            v-model="temp.district"
            @change="onChangeDistrict"
          ></base-select>
        </base-input>
        <base-input inset label="Desa">
          <base-select
            inset
            expand
            :options="villageOptions"
            v-model="temp.village"
          ></base-select>
        </base-input>
        <base-input
          inset
          label="Alamat"
          tag="textarea"
          placeholder="Alamat Lengkap"
          v-model="temp.address"
        />
        <base-button fullwidth @click="onSave">Gunakan Alamat</base-button>
      </div>
    </base-card>
  </base-modal>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal';

export default {
  components: { BaseModal },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    value: Object,
  },
  emits: ['close', 'input', 'change'],
  data() {
    return {
      temp: {
        province: {
          id: null,
          name: null,
        },
        city: {
          id: null,
          name: null,
        },
        district: {
          id: null,
          name: null,
        },
        village: {
          id: null,
          name: null,
        },
        address: null,
        area: {
          id: null,
          code: null,
        },
      },
      provinces: {
        data: [],
      },
      cities: {
        data: [],
      },
      districts: {
        data: [],
      },
      villages: {
        data: [],
      },
    };
  },
  computed: {
    address: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    provinceOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Provinsi',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.provinces.data.map((province) => ({
          key: province.id,
          label: province.attributes.name,
          value: {
            id: province.id,
            name: province.attributes.name,
          },
        })),
      ];
    },
    cityOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kabupaten',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.cities.data.map((city) => ({
          key: city.id,
          label: city.attributes.name,
          value: {
            id: city.id,
            name: city.attributes.name,
          },
        })),
      ];
    },
    districtOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Kecamatan',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.districts.data.map((district) => ({
          key: district.id,
          label: district.attributes.name,
          value: {
            id: district.id,
            name: district.attributes.name,
          },
        })),
      ];
    },
    villageOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Desa',
          value: {
            id: null,
            name: null,
          },
        },
        ...this.villages.data.map((village) => ({
          key: village.id,
          label: village.attributes.name,
          value: {
            id: village.id,
            name: village.attributes.name,
          },
        })),
      ];
    },
  },
  methods: {
    async loadProvinces() {
      const [res, err] = await this.request('/api/v1/provinces');

      if (!err) {
        this.provinces = res;
      }
    },
    async loadCities() {
      const [res, err] = await this.request(
        `/api/v1/provinces/${this.temp.province.id}/cities`,
        {
          params: {
            include: 'area',
          },
        }
      );

      if (!err) {
        this.cities = res;
      }
    },
    async loadDistricts() {
      const [res, err] = await this.request(
        `/api/v1/cities/${this.temp.city.id}/districts`
      );

      if (!err) {
        this.districts = res;
      }
    },
    async loadVillages() {
      const [res, err] = await this.request(
        `/api/v1/districts/${this.temp.district.id}/villages`
      );

      if (!err) {
        this.villages = res;
      }
    },
    onChangeProvince() {
      this.temp.city.id = null;
      this.temp.city.name = null;

      this.temp.district.id = null;
      this.temp.district.name = null;

      this.temp.village.id = null;
      this.temp.village.name = null;

      this.temp.area.code = null;
      this.temp.area.id = null;

      this.loadCities();
    },
    onChangeCity() {
      const city = this.cities.data.find(
        (city) => city.id === this.temp.city.id
      );
      const area = this.getSingleIncluded(
        this.cities,
        city.relationships.area.data.id
      );

      this.temp.area.code = area.attributes.code;
      this.temp.area.id = area.id;

      this.temp.district.id = null;
      this.temp.district.name = null;

      this.temp.village.id = null;
      this.temp.village.name = null;

      this.loadDistricts();
    },
    onChangeDistrict() {
      this.temp.village.id = null;
      this.temp.village.name = null;

      this.loadVillages();
    },
    onClose() {
      this.$emit('close');
    },
    onOpened() {
      this.temp.province.id = this.address.province.id;
      this.temp.province.name = this.address.province.name;

      this.temp.city.id = this.address.city.id;
      this.temp.city.name = this.address.city.name;

      this.temp.district.id = this.address.district.id;
      this.temp.district.name = this.address.district.name;

      this.temp.village.id = this.address.village.id;
      this.temp.village.name = this.address.village.name;

      this.temp.address = this.address.address;

      this.temp.area.id = this.address.area.id;
      this.temp.area.code = this.address.area.code;
    },
    onSave() {
      this.address.province.id = this.temp.province.id;
      this.address.province.name = this.temp.province.name;

      this.address.city.id = this.temp.city.id;
      this.address.city.name = this.temp.city.name;

      this.address.district.id = this.temp.district.id;
      this.address.district.name = this.temp.district.name;

      this.address.village.id = this.temp.village.id;
      this.address.village.name = this.temp.village.name;

      this.address.address = this.temp.address;

      this.address.area.id = this.temp.area.id;
      this.address.area.code = this.temp.area.code;

      this.$emit('change');
      this.$emit('close');
    },
  },
  created() {
    this.loadProvinces();
  },
};
</script>
