<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          :options="filterStatusOptions"
          v-model="filter.is_valid"
          @change="loadOrders"
        />
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode atau Nama"
          debounce
          v-model="filter.search"
          @native-input="loadOrders"
        />
        <base-button :to="{ name: 'restock.tambah' }">
          <Icon icon="heroicons:plus-solid" class="h-4 w-4" />
          Tambah Restock
        </base-button>
      </div>

      <datatable
        :columns="tableColumns"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        cursor
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="order in orders.data"
            :key="order.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(order.attributes.order_id)"
          >
            <td :class="[classes.td, 'space-y-1']">
              <p class="font-bold text-gray-900">
                {{ order.attributes.origin_code }}
              </p>
              <p class="text-xs text-gray-500">
                {{ order.attributes.updatedAt | formatDate }}
              </p>
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['origin-office'].data.id
                ).attributes.code
              }}
              /
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['destination-office'].data.id
                ).attributes.code
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                v-if="order.attributes.order_status === 'Draft'"
                color="yellow"
                >Draft</base-badge
              >
              <base-badge v-else color="green">Valid</base-badge>
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge v-if="order.attributes.is_completed" color="red"
                >Belum Selesai</base-badge
              >
              <base-badge v-else color="green">Selesai</base-badge>
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge color="indigo">{{
                order.attributes.warehouse_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-order-modal
      :with-payment="false"
      edit-route-name="restock.edit"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      :visible="detailModal.visible"
      :order-id="detailModal.orderId"
      @close="detailModal.visible = false"
      @validated="onRefresh"
      @deleted="onRefresh"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: {
    ViewOrderModal,
  },
  data() {
    return {
      filter: {
        is_valid: null,
        search: null,
      },
      detailModal: {
        orderId: null,
        visible: false,
      },
      loading: false,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterStatusOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Status',
          value: null,
        },
        {
          key: 'draft',
          label: 'Draft',
          value: false,
        },
        {
          key: 'valid',
          label: 'Valid',
          value: true,
        },
      ];
    },
    tableColumns() {
      return [
        { id: 'code', name: 'Nomor Restock KP' },
        { id: 'office_code', name: 'Kode KP / Kode Gudang' },
        {
          id: 'order_status',
          name: 'Status Faktur',
          theadClass: 'text-center',
        },
        {
          id: 'report_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
        {
          id: 'warehouse_status',
          name: 'Status Proses',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    async loadOrders(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          include: 'origin-office,destination-office',
          'fields[simple-orders]':
            'origin_code,updatedAt,order_status,order_id,warehouse_status,origin-office,destination-office',
          'fields[offices]': 'code',
          ...(this.filter.is_valid === null
            ? {}
            : this.filter.is_valid === true
            ? { 'filter[not-order_status]': 'Draft' }
            : { 'filter[order_status]': 'Draft' }),
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          'filter[search]': this.filter.search,
          'filter[order_type]': 'purchase',
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadOrders(page);
    },
    onClickDetail(orderId) {
      this.detailModal.orderId = orderId;
      this.detailModal.visible = true;
    },
    onRefresh() {
      this.detailModal.visible = false;

      this.loadOrders();
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
