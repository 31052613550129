<template>
  <base-wrapper :loading="loadingKantorPelayanan">
    <div class="space-y-4">
      <div class="flex items-center justify-end space-x-2">
        <base-input
          type="search"
          placeholder="Cari Kantor Pelayanan"
          debounce
          :shadow="false"
          v-model="filter.search"
          @native-input="loadKantorPelayanan"
        />
        <base-button @click="onCreate" type="button">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          Tambah Kantor Pelayanan
        </base-button>
      </div>
      <datatable
        :total="kantorPelayanans.meta.page.total"
        :perPage="kantorPelayanans.meta.page.perPage"
        :currentPage="kantorPelayanans.meta.page.currentPage"
        :meta="kantorPelayanans.meta"
        :columns="[
          { id: 'code', name: 'Kode Kantor Pelayanan' },
          { id: 'name', name: 'Nama Kantor Pelayanan' },
          { id: 'area', name: 'Area' },
          { id: 'bank_account_number', name: 'No. Rekening' },
          { id: 'phone', name: 'Alamat / No. Telp' },
        ]"
        cursor
        @pagechanged="onPageChange"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="kantorPelayanan in kantorPelayanans.data"
            :key="kantorPelayanan.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(kantorPelayanan)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ kantorPelayanan.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">
              <p class="text-gray-900 mb-1">{{ kantorPelayanan.attributes.name }}</p>
              <p class="text-xs">{{ getOfficeIncluded(kantorPelayanan).attributes.email ?? '' }}</p>
            </td>
            <td :class="classes.td">{{ getOfficeIncluded(kantorPelayanan).attributes.area_name ?? '-' }}</td>
            <td :class="classes.td">{{ getOfficeIncluded(kantorPelayanan).attributes.bank_account_number ?? '-' }}</td>
            <td :class="classes.td">
              <div>
                <p>
                  {{ getOfficeIncluded(kantorPelayanan).attributes.address ?? '-' }}
                </p>
                <p class="text-xs">
                  {{ getOfficeIncluded(kantorPelayanan).attributes.phone ?? '' }}
                </p>
              </div>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <office-form-modal
      office-type="kantor_pelayanan"
      :visible="createModalVisible"
      @close="createModalVisible = false"
      @success="onSuccessCreate"
    />
    <view-office-modal
      office-type="kantor_pelayanan"
      :loading="detailModal.loading"
      :visible="detailModal.visible"
      :office-id="detailModal.officeId"
      @close="detailModal.visible = false"
    />
  </base-wrapper>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import ViewOfficeModal from '@/components/office/view-office-modal.vue';
import OfficeFormModal from '@/components/office/office-form-modal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'Home',
  mixins: [requestMixin],
  components: {
    BaseButton,
    ViewOfficeModal,
    OfficeFormModal,
  },
  data() {
    return {
      detailModal: {
        officeId: null,
        visible: false,
        loading: false
      },
      filter: {
        search: null,
      },
      loadingKantorPelayanan: false,
      kantorPelayanans: {
        data: [],
        meta: {
          page: {},
        },
      },
      createModalVisible: false,
    };
  },
  created() {
    this.loadKantorPelayanan();
  },
  methods: {
    async onClickDetail(simpleOffice) {
      this.detailModal.officeId = simpleOffice.relationships.office.data.id;
      this.detailModal.visible = true;
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.kantorPelayanans,
        simpleOffice.relationships.office.data.id
      );
    },
    onPageChange(page) {
      this.loadKantorPelayanan(page);
    },
    onCreate() {
      this.createModalVisible = true;
    },
    onSuccessCreate() {
      this.createModalVisible = false;
      this.loadKantorPelayanan();
    },
    async loadKantorPelayanan(params) {
      this.loadingKantorPelayanan = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'page[limit]': 5,
          'filter[office_category_id]': 2,
          include: 'office',
          'fields[simple-offices]': 'code,name,office',
          'fields[offices]': 'address,phone,email,area_name,bank_account_number',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.kantorPelayanans = res;
      }

      this.loadingKantorPelayanan = false;
    },
  },
};
</script>
