<template>
  <div class="px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-8 rounded-lg bg-white p-6">
      <editOfficeForm
        title="Edit Data Kantor Pelayanan"
        view-as="kantor_pelayanan"
      />
    </div>
  </div>
</template>
<script>
import editOfficeForm from '@/components/office/edit-office-form-old.vue';

export default {
  name: 'EditKantorPelayanan',
  components: {
    editOfficeForm,
  },
};
</script>
