<template>
  <base-wrapper :loading="loadingMailLogs || loadingRetry">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input placeholder="Cari Email" :shadow="false" type="search" v-model="filter.search" debounce @native-input="loadMailLogs" />
        <base-select :expand="false" :shadow="false" :options="[
          { key: 'all', value: null, label: 'Pilih Status' },
          { key: 'pending', value: 'pending', label: 'Menunggu Diproses' },
          { key: 'processing', value: 'processing', label: 'Sedang Diproses' },
          { key: 'failed', value: 'failed', label: 'Gagal' },
          { key: 'success', value: 'success', label: 'Berhasil' }
        ]" v-model="filter.status" @change="loadMailLogs" />
      </div>
      <datatable
        :total="mailLogs.meta.page.total"
        :perPage="mailLogs.meta.page.perPage"
        :currentPage="mailLogs.meta.page.currentPage"
        :columns="[
          { id: 'date', name: 'Tanggal' },
          { id: 'email', name: 'Email' },
          { id: 'status', name: 'Status' },
          { id: 'action', name: 'Aksi', theadClass: 'text-center' },
        ]"
        :scroll-x="false"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="historyLog in mailLogs.data"
            :key="historyLog.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td :class="classes.td">
              {{ historyLog.attributes.createdAt | formatDate }}
            </td>
            <td :class="classes.td">
              {{ historyLog.attributes.email }}
            </td>
            <td :class="classes.td">
              <base-badge
                :color="getStatusBadge(historyLog.attributes.status).color"
                :title="
                  historyLog.attributes.status === 'failed'
                    ? historyLog.attributes.message
                    : ''
                "
                >{{
                  getStatusBadge(historyLog.attributes.status).text
                }}</base-badge
              >
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-button v-if="historyLog.attributes.status === 'failed'" size="sm" @click="onRetry(historyLog.id)">Kirim Ulang</base-button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        search: null,
        status: null
      },
      loadingMailLogs: false,
      loadingRetry: false,
      mailLogs: {
        meta: {
          page: {}
        },
        data: []
      }
    }
  },
  methods: {
    getStatusBadge(status) {
      return {
        pending: {
          color: 'yellow',
          text: 'Menunggu Diproses'
        },
        processing: {
          color: 'indigo',
          text: 'Sedang Diproses'
        },
        failed: {
          color: 'red',
          text: 'Gagal'
        },
        success: {
          color: 'green',
          text: 'Terkirim'
        }
      }[status]
    },
    async loadMailLogs(params = {}) {
      this.loadingMailLogs = true

      const [res, err] = await this.request('/api/v1/mail-logs', {
        params: {
          'page[size]': 5,
          'fields[mail-logs]': 'createdAt,email,status,type,message',
          'filter[search]': this.filter.search,
          'filter[status]': this.filter.status,
          ...params
        }
      })

      if (!err) {
        this.mailLogs = res
      }

      this.loadingMailLogs = false
    },
    onChangePage(page) {
      this.loadMailLogs({
        'page[number]': page
      })
    },
    async onRetry(id) {
      this.loadingRetry = true

      const [, err] = await this.request(`/api/v1/mail-logs/${id}/-actions/retry`, {
        method: 'post'
      })

      if (err) {
        this.createdAlert({
          data: 'Gagal mengirim ulang email',
          status: 'error'
        })
      } else {
        this.loadMailLogs()
      }

      this.loadingRetry = false
    }
  },
  created() {
    this.loadMailLogs()
  }
}
</script>