<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          :options="filterStatusOptions"
          v-model="filter.status"
          @change="loadOrders"
        />
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadOrders"
        />
      </div>

      <datatable
        cursor
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        :columns="tableColumns"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="order in orders.data"
            :key="order.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onDetailOrder(getOrder(order))"
          >
            <td :class="classes.td">
              <div class="space-y-1">
                <p class="font-bold text-gray-900">
                  {{ order.attributes.origin_code }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ order.attributes.updatedAt | formatDate }}
                </p>
              </div>
            </td>
            <td :class="classes.td">
              {{ getOrder(order).attributes.origin_office_code }}
            </td>
            <td :class="classes.td">
              {{ order.attributes.createdAt | formatDate }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ getOrder(order).attributes.item_count | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ getOrder(order).attributes.grand_total_weight | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ getOrder(order).attributes.grand_total_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge color="indigo">{{
                getOrder(order).attributes.order_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-order-modal
      :stock-movement-modal-props="{
        withFirstPacketProduct: true,
        withBatch: true,
      }"
      label="Pembelian"
      readonly
      :with-payment="false"
      :columns="['code', 'warehouse', 'office', 'date', 'user', 'area']"
      :visible="detailModal.visible"
      :order-id="detailModal.orderId"
      @close="detailModal.visible = false"
    >
      <template #actions="{ stockMovements }">
        <base-button
          color="danger"
          @click="onDeleteFirstShipment(stockMovements[0])"
        >
          <Icon icon="heroicons:trash-solid" class="h-4 w-4 text-white" />
          Hapus
        </base-button>
        <base-button @click="onValidateFirstShipment(stockMovements[0])">
          <Icon
            icon="heroicons:shield-check-solid"
            class="h-4 w-4 text-white"
          />
          Validasi
        </base-button>
      </template>
    </view-order-modal>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewOrderModal },
  data() {
    return {
      detailModal: {
        visible: false,
        orderId: null,
      },
      filter: {
        status: 'Diproses Gudang Pengeluaran,Persiapan',
        search: null,
      },
      loadingOrders: false,
      loadingShipment: false,
      orders: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterStatusOptions() {
      return [
        {
          label: 'Semua',
          value: 'Diproses Gudang Pengeluaran,Persiapan',
        },
        { label: 'Sudah Dikemas', value: 'Persiapan' },
        { label: 'Belum Dikemas', value: 'Diproses Gudang Pengeluaran' },
      ];
    },
    loading() {
      return this.loadingOrders || this.loadingShipment;
    },
    tableColumns() {
      return [
        {
          name: 'Nomor Pembelian',
        },
        {
          name: 'Kode Supplier',
        },
        {
          name: 'Tanggal Pembelian',
        },
        {
          name: 'Total Produk',
          theadClass: 'text-right',
        },
        {
          name: 'Total Berat',
          theadClass: 'text-right',
        },
        {
          name: 'Total Harga',
          theadClass: 'text-right',
        },
        {
          name: 'Status',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    getOrder(simpleOrder) {
      return this.getSingleIncluded(
        this.orders,
        simpleOrder.relationships.order.data.id
      );
    },
    async getFirstStockMovement(order) {
      this.loadingShipment = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${order.id}/stock-movements`,
        {
          params: {
            'page[size]': 1,
            'fields[stock-movements]': 'code',
          },
        }
      );

      this.loadingShipment = false;

      if (!err) {
        return res.data[0];
      } else {
        return null;
      }
    },
    async loadOrders(params) {
      this.loadingOrders = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          'filter[order_status]': this.filter.status,
          'filter[search]': this.filter.search,
          include: 'order',
          'fields[simple-orders]': 'origin_code,createdAt,updatedAt,order',
          'fields[orders]':
            'origin_office_code,item_count,grand_total_weight,grand_total_price,order_status',
          'page[limit]': 5,
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loadingOrders = false;
    },
    onChangePage(page) {
      this.loadOrders(page);
    },
    async onDetailOrder(order) {
      const stockMovement = await this.getFirstStockMovement(order);

      if (!stockMovement) {
        this.$router.push({
          name: 'gudang.pengeluaran.dari-supplier.tambah',
          params: { id: order.id },
        });
      } else {
        this.detailModal.orderId = order.id;
        this.detailModal.visible = true;
      }
    },
    async onValidateFirstShipment(movement) {
      this.loadingShipment = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${movement.id}/-actions/validate-shipment`,
        {
          method: 'patch',
        }
      );

      if (!err) {
        this.detailModal.visible = false;

        this.loadOrders();
      }

      this.loadingShipment = false;
    },
    async onDeleteFirstShipment(movement) {
      this.loadingShipment = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${movement.id}`,
        {
          method: 'delete',
        }
      );

      if (!err) {
        this.detailModal.visible = false;

        this.loadOrders();
      }

      this.loadingShipment = false;
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
