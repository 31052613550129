<template>
  <base-success-modal :visible="visible" @close="$emit('close')">
    <div class="space-y-2">
      <div>
        <p>{{ message }}</p>
        <a :href="link" class="mt-1 block break-all font-semibold text-green-700">{{
          link
        }}</a>
      </div>
      <a :href="waLink" target="_blank" class="inline-block">
        <base-button v-if="phone">
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M3 21l1.65-3.8a9 9 0 1 1 3.4 2.9L3 21"></path>
              <path
                d="M9 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0v1a5 5 0 0 0 5 5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 0 1"
              ></path>
            </g>
          </svg>
          Kirim ke Whatsapp
        </base-button>
      </a>
    </div>
  </base-success-modal>
</template>

<script>
import BaseSuccessModal from '@/components/base/BaseSuccessModal.vue';

export default {
  components: {
    BaseSuccessModal,
  },
  props: {
    visible: Boolean,
    token: String,
    phone: String,
    code: String,
    name: String,
  },
  emits: ['close'],
  computed: {
    message() {
      if (!this.phone) {
        return 'Klik link di bawah ini untuk mereset password.'
      }

      return `Untuk me-reset password ID Tetap / Kode Office : ${this.code}, Nama : ${this.name}, Silakan klik link di bawah ini`
    },
    link() {
      if (!this.token) {
        return '';
      }

      const link = this.$router.resolve({
        name: 'reset-password',
        params: { token: this.token },
      });

      return new URL(link.href, window.location.origin);
    },
    waLink() {
      if (!this.phone) {
        return ''
      }

      return `https://wa.me/${this.phone}?text=Untuk%20me-reset%20password%20ID%20Tetap%20/%20Kode%20Office%20:%20${this.code},%20Nama%20:%20${this.name},%20Silakan%20klik%20link%20di%20bawah%20ini%0A${this.link}`
    }
  },
};
</script>
