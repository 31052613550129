var render = function render(){var _vm=this,_c=_vm._self._c;return _c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"block bg-white sm:text-sm",class:[
    _vm.inset
      ? 'border-0 px-0 pt-0 mt-1 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm'
      : 'rounded-md border border-gray-300 py-0 focus:border-green-500 focus:ring-green-500',
    _vm.disabled ? 'bg-gray-100' : '',
    _vm.shadow ? (_vm.inset ? '' : 'shadow') : '',
    _vm.expand ? _vm.mdExpand ? 'w-full' : 'w-full md:w-auto' : '',
    _vm.customHeight ? _vm.customHeight : _vm.inset ? 'h-auto' : 'h-10',
  ],style:({ width: _vm.fit ? 'fit-content' : null }),attrs:{"disabled":_vm.disabled,"id":_vm.id},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.handleChange],"focus":_vm.handleFocus}},[_vm._t("default",function(){return _vm._l((_vm.options),function(option){return _c('option',{key:option.key,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }