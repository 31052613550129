<template>
  <div class="px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-8 rounded-lg bg-white p-6">
      <editOfficeForm title="Edit Data Mitra Usaha" view-as="member" />
      <loading v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import editOfficeForm from '@/components/office/edit-office-form-old.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EditKantorPelayanan',
  components: {
    editOfficeForm,
  },
  computed: {
    ...mapGetters({
      getOfficeLoading: 'offices/getLoading',
      getSimpleOfficeLoading: 'simple_offices/getLoading',
    }),
    isLoading() {
      return this.getSimpleOfficeLoading || this.getOfficeLoading;
    },
  },
};
</script>
