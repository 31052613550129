<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-2">
        <base-input
          type="search"
          placeholder="Cari kode atau nama"
          :shadow="false"
          debounce
          v-model="filter.search"
          @native-input="loadProducts"
        />
        <base-button :to="{ name: 'master.barang.tambah' }"
          >Tambah Barang</base-button
        >
        <base-button :loading="loadingExport" @click="onExport">
          <svg
            v-if="!loadingExport"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </base-button>
      </div>
      <datatable
        :columns="[
          { id: 'code', name: 'Kode Barang' },
          { id: 'name', name: 'Nama Barang' },
          { id: 'supplier', name: 'Kode Supplier' },
          { id: 'category', name: 'Kelompok Produk' },
        ]"
        :total="products.meta.page.total"
        :perPage="products.meta.page.perPage"
        :currentPage="products.meta.page.currentPage"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="product in products.data"
            :key="product.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickProduct(product)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ product.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">{{ product.attributes.name }}</td>
            <td :class="classes.td">
              {{ product.attributes.supplier_code ?? '-' }}
            </td>
            <td :class="classes.td">
              {{ product.attributes.product_category_name ?? '-' }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-product-modal
      :visible="detailProduct.visible"
      :product-id="detailProduct.productId"
      @close="detailProduct.visible = false"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewProductModal from '@/components/product/view-product-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { downloadFileUrl } from '@/services/utils.service';

export default {
  mixins: [requestMixin],
  components: { ViewProductModal },
  data() {
    return {
      detailProduct: {
        productId: null,
        visible: false,
      },
      exportChannelName: null,
      filter: {
        search: null,
      },
      loading: false,
      loadingExport: false,
      products: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addExportListener() {
      this.exportChannelName = `private-ProductExport.${this.me.id}`;

      const exportProductChannel = this.$pusher.subscribe(
        this.exportChannelName
      );

      exportProductChannel.bind('ProductExportStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.loadingExport = true;
        } else if (e.status === 'failed') {
          this.loadingExport = false;

          this.createAlert({ data: e.message, status: 'error' });
        } else if (e.status === 'finished') {
          this.loadingExport = false;

          downloadFileUrl(e.file_url);
        }
      });
    },
    async loadProducts(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/products', {
        params: {
          'page[size]': 5,
          'fields[products]': 'code,name,supplier_code,product_category_name',
          'filter[search]': this.filter.search,
          sort: '-createdAt',
          ...params,
        },
      });

      if (!err) {
        this.products = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadProducts({
        'page[number]': page,
      });
    },
    onClickProduct(product) {
      this.detailProduct.productId = product.id;
      this.detailProduct.visible = true;
    },
    async onExport() {
      this.loadingExport = true;

      const [, err] = await this.request(
        `api/v1/products/-actions/exportProduct`
      );

      if (err) {
        this.loadingExport = false;
      }
    },
    removeExportListener() {
      this.$pusher.unsubscribe(this.exportChannelName);
    },
  },
  created() {
    this.loadProducts();
    this.addExportListener();
  },
  beforeRouteLeave(to, from, next) {
    this.removeExportListener();

    next();
  },
};
</script>
