var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-6"},[_c('create-order-form',{attrs:{"with-restock-type":false,"with-destination-area":"","with-loan":false,"valid":_vm.order.valid,"use-origin-office-warehouse":""},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.order.destinationOffice.id)?[_c('cashback-order-provider',{staticClass:"space-y-6",attrs:{"office-type":_vm.order.originOfficeType,"total-price":_vm.totalPrice,"area-code":_vm.order.area.code},model:{value:(_vm.cashback),callback:function ($$v) {_vm.cashback=$$v},expression:"cashback"}},[_c('create-order-detail-form',{attrs:{"cashback":_vm.cashback,"origin-office":_vm.order.originOffice,"origin-warehouse":_vm.order.originOffice.currentWarehouse,"destination-office":_vm.order.destinationOffice,"buyer-type":_vm.order.buyerType,"area":_vm.order.area,"order-id":_vm.order.id,"with-cashback":"","create-order-attributes":{
            is_using_stock: true,
            order_shipment: _vm.order.shipment_type,
            order_type: 'sale',
            destination_warehouse_id:
              _vm.order.destinationOffice.currentWarehouse.id,
            ...(_vm.order.custom_shipment_address
              ? {
                  destination_address: {
                    province_id: _vm.order.shipmentAddress.province.id,
                    city_id: _vm.order.shipmentAddress.city.id,
                    district_id: _vm.order.shipmentAddress.district.id,
                    village_id: _vm.order.shipmentAddress.village.id,
                    address: _vm.order.shipmentAddress.address,
                  },
                }
              : {}),
          },"disabled":_vm.order.valid,"min-spend":_vm.minOrderSpend},on:{"order-created":_vm.onOrderCreated},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}),(_vm.canAddPayment)?_c('create-payment-form',{attrs:{"office-id":_vm.order.originOffice.id,"order-id":_vm.order.id,"payment-id":_vm.paymentId,"min-amount":_vm.totalPrice,"paid":_vm.cashback,"with-validate-button":false,"auto-save":""},on:{"load-payment-methods-finished":function($event){_vm.loadingPaymentMethods = false}}}):_vm._e()],1)]:_vm._e(),_c('div',{staticClass:"flex justify-end gap-2"},[(!_vm.order.valid)?_c('base-button',{attrs:{"disabled":!_vm.canValidate},on:{"click":_vm.onValidate}},[_vm._v("Siap Validasi")]):_vm._e(),_c('base-button',{attrs:{"color":"white","to":{ name: 'penjualan' }}},[_vm._v("Kembali")])],1)],2),_c('validate-order-confirmation',{attrs:{"visible":_vm.validateConfirmationVisible,"order-id":_vm.order.id},on:{"close":function($event){_vm.validateConfirmationVisible = false},"confirmed":_vm.onConfirmValidate}}),_c('view-order-modal',{attrs:{"visible":_vm.viewModalVisible,"order-id":_vm.order.id,"columns":[
      'code',
      'warehouse',
      'office',
      'date',
      'user',
      'area',
      'shipment_type',
      'address',
    ]},on:{"close":function($event){return _vm.$router.push({ name: 'penjualan' })},"validated":function($event){return _vm.$router.push({ name: 'penjualan' })},"deleted":function($event){return _vm.$router.push({ name: 'penjualan' })}}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }