import axios from 'axios';
import router from '@/router';
import { version } from '../../package.json';
import store from '@/store'

export const options = {
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'cache-control': 'no-cache',
  },
};

let errorStack = [];

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access_token'); // pokoknya jgn pake StorageService
        if (token) config.headers.Authorization = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/vnd.api+json';
        config.headers['Accept'] = 'application/vnd.api+json';
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    async function refreshAccessToken() {
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post('api/v1/refresh-token', {
          refresh_token: refreshToken,
        });
        return response;
      } catch (err) {
        localStorage.clear();
        router.push({ name: 'Login' });
        throw err;
      }
    }

    axios.interceptors.response.use(null, async (error) => {
      const originalRequest = error.config;

      if (errorStack.length > 1) {
        errorStack = [];
        return Promise.reject(error);
      } else {
        errorStack.push(originalRequest.url);
      }

      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes('/refresh-token') &&
        router.currentRoute.fullPath !== '/login'
      ) {
        originalRequest._retry = true;
        // jika unauthenticated utk pertamakalinya, minta access token ke backend
        return refreshAccessToken().then((response) => {
          const { client_min_version } = response.data.data

          if (client_min_version) {
            if (version !== client_min_version) {
              store.commit('auth/setVersionUpdated', false)
            }
          }

          const accessToken = response.data.data.token;
          const refreshToken = response.data.data.refresh_token;
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('refresh_token', refreshToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
      }
      if (error.response.status === 403) {
        router.push('/');
      }
      return Promise.reject(error);
    });
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, params) {
    return axios.get(resource, params, options);
  },

  post(resource, data, options) {
    return axios.post(resource, data, options);
  },

  patch(resource, data, options) {
    return axios.patch(resource, data, options);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
