<template>
  <base-wrapper>
    <div class="grid grid-cols-1 gap-4">
      <base-card title="Tambah / Update Mitra Usaha">
        <div class="grid grid-cols-1 gap-4">
          <base-input
            label="File Mitra Usaha Untuk Ditambahkan"
            type="file"
            :shadow="false"
            size="padding-md"
            fullwidth
            accept=".csv"
            @change="onChangeFile"
          />
          <div class="col-span-full flex items-center gap-2">
            <base-button
              :loading="processLoading"
              :disabled="validateLoading"
              @click="onProcess"
              >{{ processStatusMessage }}</base-button
            >
            <base-button
              color="white"
              :loading="validateLoading"
              :disabled="processLoading"
              @click="onValidate"
              >{{ validateStatusMessage }}</base-button
            >
          </div>
          <alert-message
            v-if="validationSuccess"
            type="success"
            message="Validasi Berhasil"
          />
          <div v-if="validationError.length" class="col-span-full">
            <div class="space-y-1 rounded-lg bg-red-50 p-4">
              <p
                v-for="(error, i) in validationError"
                :key="i"
                class="text-sm font-semibold text-red-800"
              >
                {{ error }}
              </p>
            </div>
          </div>
        </div>
      </base-card>
      <base-card title="Keterangan">
        <dl class="grid gap-4">
          <div class="w-full">
            <dt class="text-sm text-gray-900">
              Contoh file mitra usaha untuk ditambah / diupdate
            </dt>
            <dd class="mt-1">
              <a
                href="/tool-bonus-import-example/tambah-update-mitra-usaha.csv"
                class="text-sm font-medium text-green-600"
                >tambah-update-mitra-usaha.csv</a
              >
            </dd>
          </div>
        </dl>
      </base-card>
    </div>
    <base-confirm
      message="Apakah anda yakin akan menambahkan mitra usaha yang diupload?"
      :visible="confirming"
      @close="confirming = false"
      @confirmed="onConfirmed"
    ></base-confirm>
  </base-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { validateFileCsv } from '@/utils/misc';
import AlertMessage from '@/components/base/AlertMessage.vue';

export default {
  mixins: [requestMixin],
  components: { BaseConfirm, AlertMessage },
  data() {
    return {
      processLoading: false,
      validateLoading: false,
      validationError: [],
      validationSuccess: false,
      confirming: false,
      processStatus: 'null',
      validateStatus: 'null',
      form: {
        file: null,
      },
      channelName: null,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    processStatusMessage() {
      return {
        null: 'Proses',
        request: 'Mengirim ke Server',
        wait: 'Menunggu Diproses',
        queued: 'Menunggu Antrian',
        started: 'Mulai Diproses',
        'warehouse-importing': 'Menambahkan Gudang',
        'member-importing': 'Menambahkan Kantor',
        'member-warehouse-importing': 'Menambahkan Kantor Gudang',
        'user-importing': 'Menambahkan Akun',
        'upline-importing': 'Menambahkan Upline',
        'role-importing': 'Menambahkan Role',
        'pin-hashing': 'Menenkripsi PIN',
        finished: 'Selesai',
      }[this.processStatus];
    },
    validateStatusMessage() {
      return {
        null: 'Validasi',
        request: 'Mengirim ke Server',
        wait: 'Menunggu Diproses',
        started: 'Mulai Diproses',
      }[this.validateStatus];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    listenToEvent() {
      this.channelName = `private-OfficeBulkCreateMitraUsaha.${this.me.id}`;

      const channel = this.$pusher.subscribe(this.channelName);

      channel.bind('OfficeBulkCreateMitraUsahaStatusUpdated', (e) => {
        if (e.status === 'error') {
          this.setProcessStatus('null');
          this.stopProcessLoading();
          this.createAlert({
            data: e.message,
            status: 'error',
          });
        } else if (e.status === 'finished') {
          this.setProcessStatus('null');
          this.stopProcessLoading();
          this.createAlert({
            data: 'Berhasil menambahkan mitra usaha',
            status: 'success',
          });
        } else {
          this.processStatus = e.status;
        }
      });

      channel.bind('BatchValidateImportMemberStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.setValidateStatus('started');
        } else if (e.status === 'has-error') {
          const errors = JSON.parse(e.message);

          errors.forEach((error) => {
            this.validationError.push(error);
          });
        } else if (e.status === 'finished-with-error') {
          this.setValidateStatus('null');
          this.stopValidateLoading();
        } else {
          this.setValidateStatus('null');
          this.stopValidateLoading();
          this.validationSuccess = true;
        }
      });
    },
    onChangeFile(e) {
      this.form.file = e.target.files[0];
    },
    async onProcess() {
      if (!validateFileCsv(this.form.file)) {
        this.createAlert({
          data: 'File yang diupload harus bertipe csv',
          status: 'error',
        });
      } else {
        this.confirming = true;
      }
    },
    async onConfirmed() {
      this.confirming = false;

      this.setProcessStatus('request');
      this.startProcessLoading();

      const formData = new FormData();

      formData.append('file', this.form.file);

      const [, error] = await this.request(
        '/api/v1/offices/-actions/bulk-create-mitra-usaha',
        {
          method: 'post',
          data: formData,
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });
        this.setProcessStatus('null');
        this.stopProcessLoading();
      } else {
        this.setProcessStatus('wait');
      }
    },
    async onValidate() {
      if (!validateFileCsv(this.form.file)) {
        this.createAlert({
          data: 'File yang diupload harus bertipe csv',
          status: 'error',
        });
      } else {
        this.validationError = [];
        this.validationSuccess = false;

        this.setValidateStatus('request');
        this.startValidateLoading();

        const formData = new FormData();

        formData.append('file', this.form.file);
        formData.append('validate', true);

        const [, error] = await this.request(
          '/api/v1/offices/-actions/bulk-create-mitra-usaha',
          {
            method: 'post',
            data: formData,
          }
        );

        if (error) {
          this.createAlert({
            data: this.getRequestErrorMessage(error),
            status: 'error',
          });
          this.setValidateStatus('null');
          this.stopValidateLoading();
        } else {
          this.setValidateStatus('wait');
        }
      }
    },
    setProcessStatus(status) {
      this.processStatus = status;
    },
    startProcessLoading() {
      this.processLoading = true;
    },
    stopProcessLoading() {
      this.processLoading = false;
    },
    setValidateStatus(status) {
      this.validateStatus = status;
    },
    startValidateLoading() {
      this.validateLoading = true;
    },
    stopValidateLoading() {
      this.validateLoading = false;
    },
    stopListenToEvent() {
      if (this.me) {
        this.$pusher.unsubscribe(this.channelName);
      }
    },
  },
  created() {
    this.listenToEvent();
  },
  destroyed() {
    this.stopListenToEvent();
  },
};
</script>
