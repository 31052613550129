<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end">
        <base-input
          placeholder="Cari Kode"
          type="search"
          :shadow="false"
          debounce
          v-model="filter.search"
          @native-input="loadMembers"
        />
      </div>
      <datatable
        :total="members.meta.page.total"
        :perPage="members.meta.page.perPage"
        :currentPage="members.meta.page.currentPage"
        :meta="members.meta"
        :columns="[
          { id: 'code', name: 'Kode Kantor' },
          { id: 'upline', name: 'Kode / Nama Upline' },
          { id: 'stockist', name: 'Kode / Nama Stockist' },
          { id: 'sc', name: 'Kode / Nama SC' },
          { id: 'bank_account_number', name: 'No. Rek' },
          { id: 'address', name: 'Alamat / Telp' },
          { id: 'status', name: 'Status', theadClass: 'text-center' },
        ]"
        cursor
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="member in members.data"
            :key="member.id"
            :class="[classes.tr, 'bg-white hover:bg-green-100']"
          >
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              <p class="font-bold text-gray-900">
                {{ member.attributes.code }}
              </p>
              <span class="text-xs text-gray-600">{{
                member.attributes.name
              }}</span>
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              <p v-if="!hasUpline(member, 'referred_by')">-</p>
              <template v-else>
                <p class="font-bold text-gray-900">
                  {{ getUpline(member, 'referred_by').code }}
                </p>
                <span class="text-xs text-gray-600">{{
                  getUpline(member, 'referred_by').name
                }}</span>
              </template>
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              <p v-if="!hasDownline(member, 'promoting_to_stockist')">-</p>
              <template v-else>
                <p class="font-bold text-gray-900">
                  {{ getDownline(member, 'promoting_to_stockist').code }}
                </p>
                <span class="text-xs text-gray-600">{{
                  getDownline(member, 'promoting_to_stockist').name
                }}</span>
              </template>
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              <p v-if="!hasDownline(member, 'promoting_to_sc')">-</p>
              <template v-else>
                <p class="font-bold text-gray-900">
                  {{ getDownline(member, 'promoting_to_sc').code }}
                </p>
                <span class="text-xs text-gray-600">{{
                  getDownline(member, 'promoting_to_sc').name
                }}</span>
              </template>
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              {{ getOffice(member).attributes.bank_account_number ?? '-' }}
            </td>
            <td
              :class="[classes.td, 'cursor-pointer']"
              @click="onClickDetail(getOffice(member))"
            >
              <p>
                {{ getOffice(member).attributes.address ?? '-' }}
              </p>
              <p class="text-xs">
                {{ getOffice(member).attributes.phone ?? '' }}
              </p>
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                v-if="!getOffice(member).attributes.is_active"
                color="yellow"
                >Tidak Aktif</base-badge
              >
              <base-badge
                v-else-if="getOffice(member).attributes.is_expired"
                color="red"
                >Expired</base-badge
              >
              <base-badge
                v-else-if="!getOffice(member).attributes.is_promoted"
                color="green"
                class="cursor-pointer"
                @click="onPromote(getOffice(member))"
                >Promosi Ke Stockist</base-badge
              >
              <base-badge v-else color="blue">{{
                getOffice(member).attributes.promoted_by
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-office-modal
      size="max-w-6xl"
      :grid-column="3"
      office-type="member"
      :visible="detailModal.visible"
      :office-id="detailModal.officeId"
      :columns="['ktp_number', 'upline']"
      @close="detailModal.visible = false"
    />

    <promote-office-to-stockist-modal
      :visible="promoteModal.visible"
      :office-id="promoteModal.officeId"
      @close="promoteModal.visible = false"
      @promoted="onPromoted"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewOfficeModal from '@/components/office/view-office-modal.vue';
import PromoteOfficeToStockistModal from '@/components/office/promote-office-to-stockist-modal.vue';

export default {
  mixins: [requestMixin],
  components: {
    ViewOfficeModal,
    PromoteOfficeToStockistModal,
  },
  data() {
    return {
      detailModal: {
        officeId: null,
        visible: false,
      },
      promoteModal: {
        officeId: null,
        visible: false,
      },
      filter: {
        search: null,
      },
      loading: false,
      members: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  methods: {
    getOffice(member) {
      return this.getSingleIncluded(
        this.members,
        member.relationships.office.data.id
      );
    },
    getUpline(member, type) {
      const office = this.getOffice(member);

      return office.attributes.upline_histories.find(
        (history) => history.type === type
      );
    },
    hasUpline(member, type) {
      const office = this.getOffice(member);

      if (!office.attributes.upline_histories) {
        return false
      }

      return office.attributes.upline_histories.some(
        (history) => history.type === type
      );
    },
    getDownline(member, type) {
      const office = this.getOffice(member);

      return office.attributes.downline_histories.find(
        (history) => history.type === type
      );
    },
    hasDownline(member, type) {
      const office = this.getOffice(member);

      if (!office.attributes.downline_histories) {
        return false
      }

      return office.attributes.downline_histories.some(
        (history) => history.type === type
      );
    },
    async loadMembers(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          include: 'office',
          'fields[simple-offices]': 'code,name,office',
          'fields[offices]':
            'upline_histories,downline_histories,bank_account_number,address,phone,is_active,is_expired,is_promoted,promoted_by',
          'filter[office_category_id]': 5,
          'page[limit]': 5,
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.members = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadMembers(page);
    },
    onClickDetail(member) {
      this.detailModal.officeId = member.id;
      this.detailModal.visible = true;
    },
    onPromote(member) {
      this.promoteModal.officeId = member.id;
      this.promoteModal.visible = true;
    },
    onPromoted() {
      this.promoteModal.visible = false;

      this.loadMembers();
    },
  },
  created() {
    this.loadMembers();
  },
};
</script>
