<template>
  <div class="flex gap-2">
    <base-select
      :shadow="false"
      :options="years"
      v-model="year"
      @change="onChangeYear"
    />
    <base-select
      :shadow="false"
      :options="months"
      v-model="month"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
import { getListPastNYear } from '@/services/date.service.js';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    value: String,
    prevPeriod: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'change'],
  data() {
    return {
      year: new Date().getFullYear(),
      mitraPeriods: {
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    month: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    months() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Bulan',
          value: null,
          disabled: true,
        },
        ...this.mitraPeriods.data.map((month) => ({
          key: month.attributes.name,
          label: month.attributes.name.split(' ')[0],
          value: month.id,
        })),
      ];
    },
    years() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Tahun',
          value: null,
          disabled: true,
        },
        ...getListPastNYear(10).map((year) => ({
          key: year,
          label: year,
          value: year,
        })),
      ];
    },
  },
  methods: {
    async loadMitraPeriods() {
      const [res, err] = await this.request(`/api/v1/mitra-periods`, {
        params: {
          'filter[year]': this.year,
          'fields[mitra-periods]': 'month,name',
        },
      });

      if (!err) {
        this.mitraPeriods = res;
      }
    },
    async onChangeYear() {
      await this.loadMitraPeriods();

      if (this.mitraPeriods.data.length) {
        this.month = this.mitraPeriods.data[0].id;

        this.$emit('change');
      } else {
        this.month = null;
      }
    },
  },
  async mounted() {
    this.year = this.prevPeriod ? this.getUser.prev_period.year : this.getUser.current_period.year;

    await this.loadMitraPeriods();

    this.month = this.prevPeriod ? this.getUser.prev_period.id : this.getUser.current_period.id;
  },
};
</script>
