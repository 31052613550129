<template>
  <div class="flex flex-col">
    <div class="space-y-6">
      <div>
        <div>
          <h4 class="font-medium text-gray-900">Data Bank</h4>
          <p class="text-sm text-gray-500">
            Data ini digunakan untuk pencarian bonus
          </p>
        </div>
        <div class="mt-4 flex flex-col sm:flex-row gap-4">
          <base-input
            label="Nama Bank"
            name="bank_id"
            inset
            with-label
            :disabled="disabled"
            :invalid="errors.bank_id"
            :message="errors.bank_id"
            fullwidth
          >
            <base-select
              :options="selectBankOptions"
              inset
              :disabled="disabled"
              custom-height="h-auto"
              id="bank_id"
              v-model="value.bank_id"
              expand
            />
          </base-input>
          <base-input
            v-model="value.bank.no_rekening"
            type="text"
            name="bank_no_rekening"
            id="bank_no_rekening"
            placeholder="Masukkan nomor rekening"
            label="No. Rekening"
            with-label
            inset
            :disabled="disabled"
            :invalid="errors.bank.no_rekening"
            :message="errors.bank.no_rekening"
            fullwidth
          />
        </div>
        <div class="mt-4 flex flex-col sm:flex-row gap-4">
          <base-input
            v-model="value.bank.cabang_bank"
            type="text"
            name="bank_cabang_bank"
            id="bank_cabang_bank"
            placeholder="Masukkan cabang bank"
            label="Cabang Bank"
            with-label
            inset
            :disabled="disabled"
            :invalid="errors.bank.cabang_bank"
            :message="errors.bank.cabang_bank"
            fullwidth
          />
          <base-input
            v-model="value.bank.nama_rekening"
            type="text"
            name="bank_nama_rekening"
            id="bank_nama_rekening"
            placeholder="Masukkan nama pemilik rekening (harus sama dengan pendaftar)"
            label="Nama Rekening"
            with-label
            inset
            :disabled="disabled"
            :invalid="errors.bank.nama_rekening"
            :message="errors.bank.nama_rekening"
            fullwidth
          />
        </div>
      </div>
      <div>
        <div>
          <h4 class="font-medium text-gray-900">Data NPWP</h4>
        </div>
        <div class="mt-4 flex flex-col sm:flex-row gap-4">
          <base-input
            v-model="value.no_npwp"
            type="text"
            name="no_npwp"
            id="no_npwp"
            placeholder="Masukkan nomor npwp"
            label="No. NPWP"
            with-label
            inset
            :disabled="disabled"
            :invalid="errors.no_npwp"
            :message="errors.no_npwp"
            fullwidth
          />
          <base-input
            v-model="value.nama_npwp"
            type="text"
            name="nama_npwp"
            id="nama_npwp"
            placeholder="Nama NPWP"
            label="Nama NPWP"
            with-label
            inset
            :disabled="disabled"
            :invalid="errors.nama_npwp"
            :message="errors.nama_npwp"
            fullwidth
          />
        </div>
      </div>
      <div>
        <div>
          <h4 class="font-medium text-gray-900">Data Referal</h4>
        </div>
        <div class="mt-4 flex flex-col sm:flex-row gap-4">
          <div class="relative w-full">
            <base-input
              v-model="value.referal.no_upline"
              @blur="searchReferal"
              type="text"
              name="referal_no_upline"
              id="referal_no_upline"
              placeholder="Masukkan kode referal"
              label="Nomor Upline"
              with-label
              inset
              :disabled="disabled"
              :invalid="errors.referal.no_upline"
              :message="errors.referal.no_upline"
              fullwidth
            />
            <button
              v-if="!disabled"
              @click="toggleOfficeModal"
              type="button"
              class="absolute right-4 top-6 cursor-pointer text-green-600"
            >
              <Icon
                class="h-5 w-5"
                icon="heroicons:magnifying-glass-circle-20-solid"
              />
            </button>
          </div>
          <base-input
            v-model="value.referal.nama_upline"
            :disabled="disabled"
            type="text"
            name="referal_nama_upline"
            id="referal_nama_upline"
            placeholder="Nama referal (otomatis terisi)"
            label="Nama Upline"
            with-label
            inset
            :invalid="errors.referal.nama_upline"
            :message="errors.referal.nama_upline"
            fullwidth
          />
        </div>
      </div>
    </div>
    <OfficeModal
      :is-visible="officeModalVisible"
      @onSearchKantor="onSearchOffice"
      @onPageChangeKantor="onChangePageOffice"
      @change-office="changeSelectedOffice"
      @closeKantorModal="() => (officeModalVisible = false)"
    />
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
// import BaseButton from '@/components/base/BaseButton.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { mapActions, mapGetters } from 'vuex';
import OfficeModal from '@/components/office/office-modal.vue';

export default {
  name: 'ThirdStepForm',
  components: {
    // BaseButton,
    BaseInput,
    BaseSelect,
    OfficeModal,
  },
  props: {
    value: {
      type: Object,
    },
    errors: {
      type: Object,
    },
    selectBankOptions: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      officeModalVisible: false,
      officeFilter: {
        search: '',
        pageNumber: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOfficeCategories: 'office_categories/getOfficeCategories',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
    }),
  },
  methods: {
    ...mapActions({
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    loadReferralOffices(params = {}) {
      return this.fetchSimpleOffices({
        ...params,
        'filter[office_category_id]': 5,
        'filter[search]': this.officeFilter.search,
        'page[limit]': 5,
        include: 'office',
        'fields[offices]': 'address,phone,name,code',
        'fields[simple-offices]': 'code,name,office',
      });
    },
    async toggleOfficeModal() {
      if (!this.officeModalVisible) {
        await this.loadReferralOffices();
      }
      this.officeModalVisible = !this.officeModalVisible;
    },
    onSearchOffice(val) {
      this.officeFilter.search = val;

      this.loadReferralOffices();
    },
    onChangePageOffice(page) {
      this.loadReferralOffices({
        [`page[${page === 'next' ? 'after' : 'before'}]`]:
          this.getSimpleOffices.meta.page[page === 'next' ? 'from' : 'to'],
      });
    },
    changeSelectedOffice(office) {
      this.$emit('search-referal', office.attributes.code);
      this.officeModalVisible = false;
    },
    onChangeOffice(page) {
      console.log({ page });
    },
    saving() {},
    searchReferal(value) {
      this.$emit('search-referal', value);
    },
  },
  created() {},
};
</script>
