<template>
  <base-wrapper :loading="loading">
    <div class="space-y-8">
      <div class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        <base-widget
          label="Saldo Stockist"
          :value="office.stockistBalance | toCurrency"
          class="cursor-pointer hover:bg-gray-50"
          @click="onChangeTab('order_balance')"
        />
        <base-widget
          label="Saldo Restock P3S"
          :value="office.restockBalance | toCurrency"
        />
        <base-widget
          label="Total Pinjaman P3S"
          :value="office.restockLoanBalance | toCurrency"
          class="cursor-pointer hover:bg-gray-50"
          @click="onChangeTab('restock_loan_balance')"
        />
        <base-widget
          label="Saldo Sisa Pembayaran"
          :value="0 | toCurrency"
        />
      </div>
      <div v-if="tab === 'order_balance'" class="space-y-4">
        <h2
          class="mt-2 text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl"
        >
          Riwayat Transaksi Saldo Stockist
        </h2>
        <order-balance-trx-table
          :office-id="me.office_id"
          @update:loading="onUpdateLoadingOrderBalanceTrxes"
        />
      </div>
      <div v-if="tab === 'restock_loan_balance'" class="space-y-4">
        <h2
          class="mt-2 text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl"
        >
          Riwayat Transaksi Pinjaman P3S
        </h2>
        <stockist-loan-trx-table
          :office-id="me.office_id"
          @update:loading="onUpdateLoadingStockistLoanTrxes"
        />
      </div>
    </div>
  </base-wrapper>
</template>

<script>
import BaseWidget from '@/components/base/BaseWidget.vue';
import OrderBalanceTrxTable from '@/components/balance-trx/order-balance-trx-table.vue';
import StockistLoanTrxTable from '@/components/stockist-loan-trx/stockist-loan-trx-table.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseWidget,
    OrderBalanceTrxTable,
    StockistLoanTrxTable,
  },
  data() {
    return {
      loadingOffice: false,
      loadingOrderBalanceTrxes: false,
      loadingStockistLoanTrxes: false,
      office: {
        stockistBalance: 0,
        restockBalance: 0,
        restockLoanBalance: 0,
      },
      tab: 'order_balance',
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return (
        this.loadingOffice ||
        this.loadingOrderBalanceTrxes ||
        this.loadingStockistLoanTrxes
      );
    },
  },
  methods: {
    async loadOffice() {
      this.loadingOffice = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}`,
        {
          params: {
            include: 'stockist-loan',
            'fields[offices]': 'order_balance,stockist-loan',
            'fields[stockist-loans]': 'amount,initial_amount',
          },
        }
      );

      if (!err) {
        this.office.stockistBalance = res.data.attributes.order_balance;

        const stockistLoanRel = res.data.relationships['stockist-loan'].data;

        if (stockistLoanRel) {
          const stockistLoan = this.getSingleIncluded(res, stockistLoanRel.id);

          this.office.restockBalance = stockistLoan.attributes.amount;
          this.office.restockLoanBalance =
            stockistLoan.attributes.initial_amount;
        }
      }

      this.loadingOffice = false;
    },
    onChangeTab(tab) {
      this.tab = tab;
    },
    onUpdateLoadingOrderBalanceTrxes(value) {
      this.loadingOrderBalanceTrxes = value;
    },
    onUpdateLoadingStockistLoanTrxes(value) {
      this.loadingStockistLoanTrxes = value;
    },
  },
  created() {
    this.loadOffice();
  },
};
</script>
