<template>
  <div class="relative">
    <base-input
      inset
      with-label
      label="Role"
      type="text"
      placeholder="contoh: Pusat"
      v-model="role.search"
      @change="onSearch"
    />
    <button
      v-if="withModal"
      type="button"
      class="absolute right-4 top-6 cursor-pointer text-gray-400"
      @click="onOpenModal"
    >
      <Icon class="h-5 w-5" icon="heroicons:magnifying-glass-circle-20-solid" />
    </button>

    <role-table-modal
      v-if="withModal"
      :visible="visibleModal"
      @close="visibleModal = false"
      @click-role="onChange"
    />

    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import RoleTableModal from './role-table-modal.vue';
import Teleport from 'vue2-teleport'
import { mapActions } from 'vuex';

export default {
  components: { RoleTableModal, Teleport },
  mixins: [requestMixin],
  props: {
    value: Object,
    withModal: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input', 'change'],
  data() {
    return {
      loading: false,
      visibleModal: false,
    };
  },
  computed: {
    role: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert'
    }),
    onOpenModal() {
      this.visibleModal = true;
    },
    async onChange(role) {
      this.role.search = role.attributes.name;

      await this.onSearch();

      this.visibleModal = false;
    },
    async onSearch() {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/roles', {
        params: {
          'filter[name]': this.role.search,
          'fields[roles]': 'name',
        },
      });

      if (err || !res.data.length) {
        this.role.search = this.role.originalSearch;

        this.createAlert({
          data: 'Role tidak ditemukan',
          status: 'error'
        })
      } else {
        const role = res.data[0];

        this.role.id = role.id;
        this.role.name = role.attributes.name;
        this.role.search = role.attributes.name;
        this.role.originalSearch = role.attributes.name;

        this.$emit('change');
      }

      this.loading = false;
    },
  },
};
</script>
